import { TableCellProps } from 'react-virtualized';
import constants from 'src/constants';
import { routePaths } from 'src/routePaths';
import dayjs from 'dayjs';
import { history } from '../store';

const isNotAuthorizedRes = (status: number): boolean => status === 401;
const isForbiddenRes = (status: number) => status === 403;
const getResJson = async (response: Response) => {
  const resText = await response.clone().text();
  return resText ? JSON.parse(resText) : {};
};

export const handleRedirects = async (response: Response) => {
  let isRedirecting = isNotAuthorizedRes(response.status);
  if (isForbiddenRes(response.status)) {
    const json = await getResJson(response);
    if (json?.code !== 5) {
      isRedirecting = true;
    }
  }
  if (isRedirecting) {
    history.push(routePaths.login);
  }
};

const getForbiddenResponseError = async (response: Response): Promise<string | null> => {
  if (isForbiddenRes(response.status)) {
    const json = await getResJson(response);
    if (json?.code === 5) {
      return 'Forbidden action';
    }
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HandleOk = (response: Response) => Promise<any>;

export const handle = (handleOk: HandleOk) => <T>(
  defaultErrorMessage: string,
  errorMap: { [key: number]: string } = {},
) => async (response: Response): Promise<T> => {
  if (response.ok) {
    return handleOk(response);
  }
  handleRedirects(response);
  const customError = await getForbiddenResponseError(response);

  throw Error(
    (!!customError && customError) ||
      (response.status in errorMap ? errorMap[response.status] : defaultErrorMessage),
  );
};

function acceptableOk<T>(response: Response): Promise<T | null> {
  if (response.status === 202) {
    return new Promise((res) => res(null));
  }
  return response.json();
}
const standardOk = <T>(response: Response): Promise<T> => response.json();

export const handleAcceptableResponse = handle(acceptableOk);
export const handleResponse = handle(standardOk);
export function handleResponseWithErrorMsg<T>(
  defaultErrorMessage: string,
  messageMapper?: (message: string) => string | undefined | null,
) {
  return async (response: Response): Promise<T> => {
    if (response.ok) {
      return standardOk(response);
    }
    handleRedirects(response);
    const customError = await getForbiddenResponseError(response);

    const data = (await response.json()) || {};
    throw Error(
      customError || (data?.message && messageMapper?.(data.message)) || defaultErrorMessage,
    );
  };
}

export const formattedDate = (date: string, format = constants.dateTimeFormat) =>
  dayjs(date).utc(true).tz().format(format);

export const dateCellRenderer = ({ cellData }: TableCellProps) => formattedDate(cellData);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const omitBy = <T extends Record<string, any>>(
  obj: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  check: (v: any, key?: string) => boolean,
) => {
  const tmp = { ...obj };
  Object.entries(tmp).forEach(([key, value]) => check(value, key) && delete tmp[key]);
  return tmp;
};

export const getUrl = (url = '', paramsObject: { [name: string]: string | number } = {}): string =>
  Object.entries(paramsObject).reduce((p, [k, v]) => p.replace(`:${k}`, String(v)), url);
