import { http2 } from 'services/http2';
import { getPostRequests } from 'services/defaultHeaders';
import { handleResponse, handleResponseWithErrorMsg } from 'services/utils';
import { User } from 'store/User/User.types';

type UserServiceRequests =
  | 'changePassword'
  | 'editUser'
  | 'getUser'
  | 'findUser'
  | 'createUser'
  | 'deleteUsers';

export const userService = http2.createGroup<UserServiceRequests>('user');

export const changePassword = async (
  userId: User.ChangePassReq['userId'],
  body: User.ChangePassReq['body'],
  version = 1,
): Promise<User.ChangePassRes> => {
  const url = `/api/users/${userId}/cred?version=${version}`;
  return userService
    .subgroup('changePassword')
    .fetch(url, { method: 'PUT', headers: getPostRequests(), body: JSON.stringify(body) })
    .request.then(handleResponseWithErrorMsg('Failed to update password'));
};

function uniqueUserError(message: string) {
  // message sample:
  // 409 : [{"code":409,"message":"User with username {username@example.com} already exists","errorCode":"USER_USERNAME_UNIQUE_CONSTRAINT"...
  if (message.includes('USER_USERNAME_UNIQUE_CONSTRAINT')) {
    return 'User with this email already exists! Please enter different email.';
  }
  if (message === 'User with same email already exists') {
    return 'User with this email already exists! Please enter different email.';
  }
  return undefined;
}
export const editUser = async (
  userId: User.EditUserReq['userId'],
  body: User.EditUserReq['body'],
  version = 1,
): Promise<User.EditUserRes> => {
  const url = `/api/users/${userId}?version=${version}`;
  return userService
    .subgroup('editUser')
    .fetch(url, { method: 'PUT', headers: getPostRequests(), body: JSON.stringify(body) })
    .request.then(handleResponseWithErrorMsg('Failed to update user', uniqueUserError));
};

export const getUser = async (userId: User.GetReq, version = 1): Promise<User.GetRes> => {
  const url = `/api/users/${userId}?version=${version}`;
  return userService
    .subgroup('getUser')
    .fetch(url)
    .request.then(handleResponse('Failed to get user'));
};

export const findUser = async (
  params: User.FindUserReq,
  version = 1,
): Promise<User.FindUserRes> => {
  const url = `/api/users/find?version=${version}`;

  return userService
    .subgroup('findUser')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: getPostRequests(),
    })
    .request.then(handleResponse('Error finding users'));
};

export const createUser = async (
  params: User.CreateUserReq,
  version = 1,
): Promise<User.UserRes> => {
  const url = `/api/users?version=${version}`;

  return userService
    .subgroup('createUser')
    .fetch(url, { method: 'POST', body: JSON.stringify(params), headers: getPostRequests() })
    .request.then(handleResponseWithErrorMsg('Error creating user', uniqueUserError));
};

export const deleteUsers = async (
  idList: User.DeleteUsersReq,
  version = 1,
): Promise<User.DeleteUsersRes> => {
  const url = `/api/users?version=${version}`;

  return userService
    .subgroup('deleteUsers')
    .fetch(url, { method: 'DELETE', body: JSON.stringify(idList), headers: getPostRequests() })
    .request.then(handleResponse<{ deletedIds: User.DeleteUsersRes }>('Error deleting users'))
    .then((res) => res?.deletedIds || null);
};

export default {
  changePassword,
  editUser,
  getUser,
  deleteUsers,
  findUser,
  createUser,
  userService,
};
