import { useEffect, useState } from "react";

export const useMatchMedia = (query: string): boolean => {
  const [matches, setMatches] = useState(
    window.matchMedia(query).matches
  );

  // Activity normally for componentDidMount + componentDidUpdate
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [query]);

  // publish value for render
  return matches;
}