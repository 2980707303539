import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { routePaths } from 'src/routePaths';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoImg from 'src/assets/mobile-logo.svg';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { NavMenu } from 'components/NavMenu/NavMenu';
import { AnimatePresence, motion } from 'framer-motion';
import { UserDetails } from 'components/UserDetails/UserDetails';
import { UserDropdownOptions } from 'components/UserDropdownOptions/UserDropdownOptions';
import { HamburgerButton } from 'components/HamburgerButton/HamburgerButton';
import styles from './LayoutMobile.css';

export const LayoutMobile: React.FC = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const toggleNav = React.useCallback(() => setIsNavOpen(!isNavOpen), [isNavOpen]);

  const renderNavigation = React.useCallback(() => {
    if (!isNavOpen) return null;

    return (
      <div data-test-id="LayoutMobile-sidebar">
        <motion.aside
          className={styles.aside}
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ ease: 'easeOut' }}
        >
          <div className={styles.navigationHeader}>Navigation</div>
          <NavMenu className={styles.navMenu} onClick={toggleNav} />
          <div className={styles.footer}>
            <UserDetails theme="light" />
            <UserDropdownOptions className={styles.listOption} navLinkOnClick={toggleNav} />
          </div>
        </motion.aside>
        <motion.div
          className={styles.backdrop}
          onClick={toggleNav}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
        />
      </div>
    );
  }, [isNavOpen, toggleNav]);

  return (
    <div className={styles.layout} data-test-id="LayoutMobile--div-0">
      <header className={styles.header}>
        <div className={styles.logo} data-test-id="LayoutMobile-logo--div-0">
          <NavLink to={routePaths.root} exact>
            <img src={logoImg} alt="Ruptela" height={24} width={24} data-test-id="layout--img-0" />
          </NavLink>
        </div>
        <div className={styles.breadcrumb}>
          <Breadcrumb />
        </div>
        <HamburgerButton isOpen={isNavOpen} onClick={toggleNav} />
        <AnimatePresence>{renderNavigation()}</AnimatePresence>
      </header>
      <main className={styles.main}>{children}</main>
    </div>
  );
};
