import { ListResponse, PageDescriptor, SortDescriptor } from 'src/types/common';
import { asyncActions } from './Client.actions';

export namespace Client {
  export enum Type {
    RUPTELA_HQ = 'RUPTELA_HQ',
    RUPTELA_BRANCH = 'RUPTELA_BRANCH',
    SERVICE_PROVIDER = 'SERVICE_PROVIDER',
    DISTRIBUTOR = 'DISTRIBUTOR',
    END_USER = 'END_USER',
  }

  export const TypeHierarchy = [
    Type.RUPTELA_HQ,
    Type.RUPTELA_BRANCH,
    Type.DISTRIBUTOR,
    Type.SERVICE_PROVIDER,
    Type.END_USER,
  ];

  export enum SortIdentifier {
    NAME = 'NAME',
    TYPE = 'TYPE',
  }

  export enum FilterIdentifier {
    TYPE = 'types',
  }

  export enum ClientListColumnKey {
    ID = 'id',
    NAME = 'name',
    ACTIONS = 'actions',
    ROW_SELECT = 'rowSelect',
    TYPE = 'type',
  }

  export interface Base {
    id: number;
    name: string;
    type: Type;
    parentId: number | null;
    parentName: string | null;
    parentType: Type | null;
  }
  export interface ExternalClient extends Base {
    created: string;
    updated: string;
  }

  export type FindClientResponse = ListResponse<ExternalClient>;

  export interface ValidateResponse {
    clientIdsWithDevices?: Record<string, number>;
    errorMessage: string;
    valid: boolean;
  }
  export interface FindClientRequest extends FindFilters {
    pageDescriptor: PageDescriptor;
    sortDescriptor?: ListSortDescriptor;
  }

  export type FindFilters = {
    name?: Base['name'];
    types?: Type[];
  };

  export type ListSortDescriptor = SortDescriptor<SortIdentifier>;
  export type AsyncActionKeys = keyof typeof asyncActions;

  export enum Dialogs {
    CREATE_CLIENT = 'CREATE_CLIENT',
    EDIT_CLIENT = 'EDIT_CLIENT',
    DELETE_CONFIRMATION = 'DELETE_CONFIRMATION',
  }

  export type DialogStates = {
    [Dialogs.CREATE_CLIENT]: {
      isOpen: boolean;
    };
    [Dialogs.EDIT_CLIENT]: {
      isOpen: boolean;
      clientId: ExternalClient['id'];
    };
    [Dialogs.DELETE_CONFIRMATION]: {
      isOpen: boolean;
      clientIdList: ExternalClient['id'][];
    };
  };

  export type CreateClientRequest = Pick<ExternalClient, 'name' | 'type'>;
  export interface DeleteClientsResponse {
    deletedIds: ExternalClient['id'][];
  }
  export type EditClientRequest = { clientId: ExternalClient['id']; params: CreateClientRequest };
}
