import { ListResponse, PageDescriptor, SortDescriptor, RequestState } from 'src/types/common';
import { IoT } from 'store/Device/Device.types';
import { asyncActions } from './File.actions';

export namespace File {
  export enum FileType {
    CFG = 'CFG',
    CFG_DIFF = 'CFG_DIFF',
    FW = 'FW',
    BLE = 'BLE',
    GET_CFG = 'GET_CFG',
    EXT = 'EXT',
  }

  export enum Visibility {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
    OFFICIAL = 'OFFICIAL',
  }

  export interface UploadFormData {
    files: File[];
    visibility: File.Visibility;
  }

  export type UploadFile = Pick<UploadFormData, 'visibility'> & {
    file: File;
  };

  export interface UploadFileState extends RequestState {
    isDone: boolean;
  }

  export type AsyncActionKeys = keyof typeof asyncActions;

  export enum SortIdentifier {
    FILE_NAME = 'FILE_NAME',
    DEVICE_TYPE = 'DEVICE_TYPE',
    FILE_TYPE = 'FILE_TYPE',
    LAST_TIME_USED = 'LAST_TIME_USED',
    UPLOADED = 'UPLOADED',
  }
  export type FileListSortDescriptor = SortDescriptor<SortIdentifier>;

  export enum FilterIdentifier {
    DEVICE_TYPE = 'deviceTypes',
    FILE_TYPE = 'fileTypes',
    LAST_TIME_USED = 'lastTimeUsedFrom',
    UPLOADED = 'uploadedFrom',
    VISIBILITY = 'visibility',
  }

  export enum FileListColumnKey {
    UUID = 'uuid',
    FILE_NAME = 'fileName',
    FILE_TYPE = 'fileType',
    DEVICE_TYPE = 'deviceType',
    LAST_TIME_USED = 'lastTimeUsed',
    UPLOADED = 'uploaded',
    ACTIONS = 'actions',
    ROW_SELECT = 'rowSelect',
    VISIBILITY = 'visibility',
  }

  export interface ExternalDeviceFileMetadata {
    clientId: number;
    deviceType: IoT.DeviceType;
    fileName: string;
    fileType: FileType;
    lastTimeUsed: string;
    uploaded: string;
    uuid: string;
    visibility: string;
  }

  export type FindFilters = {
    fileName?: ExternalDeviceFileMetadata['fileName'];
    deviceTypes?: IoT.DeviceType[];
    fileTypes?: FileType[];
    visibility?: Visibility[];
    lastTimeUsedFrom?: string;
    lastTimeUsedTo?: string;
    uploadedFrom?: string;
    uploadedTo?: string;
  };

  export type FindFileResponse = ListResponse<ExternalDeviceFileMetadata>;

  export interface DeleteFilesResponse {
    deletedFiles: File.ExternalDeviceFileMetadata['uuid'][];
  }

  export interface FindFileRequest extends FindFilters {
    pageDescriptor: PageDescriptor;
    sortDescriptor?: FileListSortDescriptor;
  }

  export interface ValidateResponse {
    errorMessage: string;
    usedFileIds: File.ExternalDeviceFileMetadata['uuid'][];
    restrictedFileIds: File.ExternalDeviceFileMetadata['uuid'][];
    valid: boolean;
  }

  export enum Dialogs {
    UPLOAD_FILE = 'UPLOAD_FILE',
    DELETE_CONFIRMATION = 'DELETE_CONFIRMATION',
  }

  export type DialogStates = {
    [Dialogs.UPLOAD_FILE]: boolean;
    [Dialogs.DELETE_CONFIRMATION]: {
      isOpen: boolean;
      fileUUIDList: File.ExternalDeviceFileMetadata['uuid'][];
    };
  };

  export namespace State {
    export type Find = Omit<FindFileResponse, 'continuationToken'>;
  }
}
