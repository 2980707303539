import * as React from 'react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'store/Authorization/Authorization.selectors';
import cn from 'classnames';
import styles from './UserDetails.css';

interface UserDetailsProps {
  theme?: 'dark' | 'light';
}

export const UserDetails: React.FC<UserDetailsProps> = ({ theme = 'dark' }) => {
  const { name, email } = useSelector(currentUserSelector);
  return (
    <div
      className={cn(styles.userDetails, {
        [styles.dark]: theme === 'dark',
        [styles.light]: theme === 'light',
      })}
      data-test-id="user-details"
    >
      {name && (
        <div className={styles.name} data-test-id="name">
          {name}
        </div>
      )}
      <div className={styles.email} data-test-id="email">
        {email}
      </div>
    </div>
  );
};
