import { Client } from 'store/Client/Client.types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const findClientAsync = createAsyncAction(
  'USER_FORM | FIND_CLIENT_REQ',
  'USER_FORM | FIND_CLIENT_RES',
  'USER_FORM | FIND_CLIENT_ERR',
)<void, Client.FindClientResponse, string>();

export const setFindClientName = createAction('USER_FORM | SET_FIND_CLIENT_NAME')<
  Pick<Client.FindFilters, 'name'>
>();
export const clearFilters = createAction('USER_FORM | LIST.CLEAR_FILTERS')();
export const endOfListReached = createAction('USER_FORM | END_OF_LIST_REACHED')();

export const resetFind = createAction('USER_FORM | RESET_FIND')();

export const asyncActions = {
  findClientAsync,
};
