import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjsLocalesJSON from 'dayjs/locale.json';
import { DEFAULT_LANGUAGE } from 'src/constants';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

dayjs.tz.setDefault('UTC');

declare global {
  interface Window {
    dayjs: typeof dayjs;
  }
}
window.dayjs = dayjs;

export const setDateLocale = () => {
  const userLang = window.navigator.languages[0];
  const dayjsLocale = dayjsLocalesJSON.find(({ key }) => key === userLang.toLowerCase());
  return (
    userLang !== DEFAULT_LANGUAGE &&
    Boolean(dayjsLocale) &&
    import(`dayjs/locale/${dayjsLocale.key}.js`).then(() => {
      dayjs.locale(dayjsLocale.key);
    })
  );
};
