import { IconName } from '@tt2/components';
import { RouteProps } from 'react-router';

export interface PageDescriptor {
  page: number;
  size: number;
}

export interface SortDescriptor<T> {
  direction: SortDirection;
  identifier: T;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ListPagination {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface ListResponse<T = unknown> {
  continuationToken: number;
  items: T[];
  page?: ListPagination;
}

export interface RequestState {
  loading: boolean;
  error: string | null;
}

export interface RouteLink extends RouteProps {
  name: string;
  isSubpage?: boolean;
  hide?: boolean;
  navigationIcon?: IconName;
}

export type FiltersPopupState<T extends string> = { [K in T]?: boolean };

export type listReducerCreatorStores = 'device' | 'file' | 'client';

export type TableActionsProps<T extends string | number> = {
  endOfListReached?: () => void;
  selectItem: (id: T) => void;
  init?: () => void;
  destroy?: () => void;
  setPage?: (page: number) => void;
  setPageSize?: (page: number) => void;
};

export interface TableStateProps<T extends unknown> extends RequestState {
  items: T[];
  pagination?: ListPagination;
  pageDescriptor?: PageDescriptor;
}

export interface ListLocationQuery {
  page?: number;
  size?: number;
  sort?: any;
  sortDir?: SortDirection;
}
