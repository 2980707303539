import * as React from 'react';
import cn from 'classnames';
import styles from './HamburgerButton.css';

interface HamburgerButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

export const HamburgerButton: React.FC<HamburgerButtonProps> = ({ isOpen, onClick }) => (
  <button
    className={styles.hamburger}
    onClick={onClick}
    type="button"
    data-test-id="HamburgerButton-button"
  >
    <div
      data-test-id="HamburgerButton-icon"
      className={cn(styles.icon, {
        [styles.checked]: isOpen,
      })}
    >
      <span />
    </div>
  </button>
);
