import { History } from 'history';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import deviceReducer from './Device/Device.reducer';
import fileReducer from './File/File.reducer';
import updateDeviceReducer from './UpdateDevice/UpdateDevice.reducer';
import commandHistoryReducer from './CommandHistory/CommandHistory.reducer';
import authorizationReducer from './Authorization/Authorization.reducer';
import clientReducer from './Client/Client.reducer';
import userReducer from './User/User.reducer';
import { assignDeviceReducer } from './AssignDevice/AssignDevice.reducer';
import { WebsocketReducer } from './Websocket/Websocket.reducer';

const rootReducer = (history: History<unknown>) =>
  combineReducers({
    router: connectRouter(history),
    device: deviceReducer,
    file: fileReducer,
    updateDevice: updateDeviceReducer,
    commandHistory: commandHistoryReducer,
    authorization: authorizationReducer,
    client: clientReducer,
    user: userReducer,
    assignDevice: assignDeviceReducer,
    websocket: WebsocketReducer,
  });

export default rootReducer;
