import React from 'react';
import { InputProps } from '@tt2/components/build/Atoms/Input';
import { destinationValidation } from 'services/UpdateDevice/UpdateDevice.utils';

const REG_DIGIT = /^\d+$/;
const ControlKeys = ['Backspace', 'Delete', 'Enter', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];

export const onEnterPress = (callback: () => void) => {
  return ({ key }: React.KeyboardEvent<HTMLInputElement>): void => {
    if (key === 'Enter') callback();
  };
};

export const onDestinationKeyDown: InputProps['onKeyDown'] = (e) => {
  e.persist();
  if (!/[a-zA-Z0-9]/.test(e.key) && !['.', '-'].includes(e.key)) {
    e.preventDefault();
  }
};

export const onDestinationPaste: InputProps['onPaste'] = (e) => {
  const valid = destinationValidation(e.clipboardData.getData('text'));
  if (valid) {
    e.persist();
  }
};

export const onNumberInputKeyDown: InputProps['onKeyDown'] = (e) => {
  e.persist();
  if (!(REG_DIGIT.test(e.key) || e.ctrlKey || e.metaKey || ControlKeys.includes(e.key))) {
    e.preventDefault();
  }
};

export const onNumberInputPaste: InputProps['onPaste'] = (e) => {
  const data = e.clipboardData.getData('text');
  if (!data || !REG_DIGIT.test(data)) {
    e.preventDefault();
  }
}
  

export const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};
