import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import { omitBy } from 'src/services/utils';
import { routePaths } from 'src/routePaths';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/constants';
import { SortDirection } from 'src/types/common';
import { stringify } from 'src/utils/queryParams';
import { DEFAULT_DEVICE_FILTERS } from './Device.reducer';
import { IoT } from './Device.types';

export const devicePaginationSelector = (state: RootState) => state.device.list.pagination;
export const devicePageDescriptorSelector = (state: RootState) => state.device.list.pageDescriptor;
export const deviceItemsSelector = (state: RootState) => state.device.list.items;
export const deviceIdsSelector = createSelector(deviceItemsSelector, (items) =>
  items.map(({ id }) => id),
);
export const selectedDeviceIdsSelector = (state: RootState) => state.device.list.selected;

export const deleteDialogSelector = (state: RootState) =>
  state.device.dialogStates[IoT.Dialogs.DELETE_CONFIRMATION];

export const isOpenDeleteDialogSelector = createSelector(
  deleteDialogSelector,
  ({ isOpen }) => isOpen,
);

export const deviceIdsForDeleteSelector = createSelector(
  deleteDialogSelector,
  ({ deviceIdList }) => deviceIdList,
);

export const deviceFiltersSelector = (state: RootState) => state.device.list.filters;

export const sortDescriptorSelector = (state: RootState) => state.device.list.sortDescriptor;

export const isOpenRegisterDialogSelector = (state: RootState) =>
  state.device.dialogStates[IoT.Dialogs.REGISTER_DEVICE];

export const selectedDevicesSelector = createSelector(
  deviceItemsSelector,
  selectedDeviceIdsSelector,
  (devices, selectedIds) => devices.filter(({ id }) => selectedIds.includes(id)),
);

export const devicesReadyForDeletionSelector = createSelector(
  deviceIdsForDeleteSelector,
  deviceItemsSelector,
  (deviceIdsForDelete, deviceItems) =>
    deviceIdsForDelete.map((id) => deviceItems.find((item) => item.id === id)).filter(Boolean),
);

export const deviceChangedFiltersSelector = createSelector(deviceFiltersSelector, (filters) => {
  return omitBy(filters, (v) => Object.values(DEFAULT_DEVICE_FILTERS).includes(v));
});

export const selectedDeviceCountSelector = createSelector(
  selectedDeviceIdsSelector,
  (selected) => selected.length,
);

export const selectedItemsDeviceTypesSelector = createSelector(
  selectedDevicesSelector,
  (selectedDevices) => [...new Set(selectedDevices.map(({ deviceType }) => deviceType))],
);

export const deviceLoadingSelector = (name: IoT.AsyncActionKeys) => (state: RootState) =>
  state.device.loading[name];

export const deviceErrorSelector = (name: IoT.AsyncActionKeys) => (state: RootState) =>
  state.device.error[name];

export const getDeviceById = (id: IoT.Device['id']) =>
  createSelector(deviceItemsSelector, (deviceItems: IoT.Device[]): IoT.Device | undefined =>
    deviceItems.find(({ id: deviceId }) => deviceId === id),
  );

export const getDeviceDetailsSelector = (state: RootState) => state.device.details;

export const checkImeisSelector = (state: RootState) => state.device.checkImeis;

export const deviceRootPathSelector = (state: RootState) => {
  const {
    filters,
    pageDescriptor: { page, size },
    sortDescriptor: { identifier: sort, direction: sortDir },
  } = state.device.list;

  const search = stringify({
    ...Object.entries(filters).reduce(
      (out, [name, value]: [keyof IoT.FindFilters, any]) => ({
        ...out,
        [name]: value === DEFAULT_DEVICE_FILTERS[name] ? null : value,
      }),
      {},
    ),
    page: page || null,
    size: (size !== DEFAULT_PAGINATION_PAGE_SIZE && size) || null,
    sort: (sort !== IoT.SortIdentifier.NAME && sort) || null,
    sortDir: (sortDir !== SortDirection.ASC && sortDir) || null,
  });

  return `${routePaths.device.root}${search}`;
};
