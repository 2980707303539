import { IoT } from 'store/Device/Device.types';
import { CommandHistory } from 'store/CommandHistory/CommandHistory.types';
import { http2 } from '../http2';
import { handleResponse, handleResponseWithErrorMsg, omitBy } from '../utils';
import { getPostRequests } from '../defaultHeaders';

type UpdateDeviceServiceRequests =
  | 'upload'
  | 'getBasicParameters'
  | 'uploadBasicParameters'
  | 'findHistory'
  | 'cancelCommand';

export const updateDeviceService = http2.createGroup<UpdateDeviceServiceRequests>('updateDevice');

export const upload = async (data: IoT.CommandUploadRequest, version = 1): Promise<void> => {
  const url = `/api/commands/upload?version=${version}`;

  return updateDeviceService
    .subgroup('upload')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify(omitBy(data, (v) => !v)),
      headers: getPostRequests(),
    })
    .request.then((res) => {
      if (res.ok) {
        return;
      }
      throw Error('Error uploading command');
    });
};

export const findHistory = async (
  params: CommandHistory.FindHistoryRequest,
  version = 1,
): Promise<CommandHistory.FindHistoryResponse> => {
  const url = `/api/commands/find?version=${version}`;
  return updateDeviceService
    .subgroup('findHistory')
    .fetch(url, { method: 'POST', body: JSON.stringify(params), headers: getPostRequests() })
    .request.then(handleResponse('Error finding history'));
};

export const getBasicParameters = async (id: number, version = 1): Promise<IoT.BasicParameters> => {
  const url = `/api/basicparameters/${id}?version=${version}`;

  return updateDeviceService
    .subgroup('getBasicParameters')
    .fetch(url)
    .request.then(handleResponse('Error getting device basic parameters'));
};

export const uploadBasicParameters = async (
  deviceIds: IoT.Device['id'][],
  parameters: IoT.BasicParameters,
  version = 1,
): Promise<IoT.UploadBasicParametersResponse> => {
  const url = `/api/basicparameters/upload?version=${version}`;

  return updateDeviceService
    .subgroup('uploadBasicParameters')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        deviceIds,
        parameters,
      }),
      headers: getPostRequests(),
    })
    .request.then(
      handleResponseWithErrorMsg('Error uploading device basic parameters', (err) => err),
    );
};

export const cancelCommand = async (
  params: CommandHistory.CancelParams,
  version = 1,
): Promise<CommandHistory.ExternalIoTDeviceCommand> => {
  const url = `/api/commands/cancel/${params.id}?version=${version}`;
  return updateDeviceService
    .subgroup('cancelCommand')
    .fetch(url, { method: 'PUT', body: JSON.stringify({}), headers: getPostRequests() })
    .request.then(handleResponse('Error canceling command'));
};

export default {
  updateDeviceService,
  upload,
  getBasicParameters,
  uploadBasicParameters,
  findHistory,
  cancelCommand,
};
