import { createSelector } from 'reselect';
import { omitBy } from 'src/services/utils';
import { clientTypeSelector } from 'store/Authorization/Authorization.selectors';
import { RootState } from 'typesafe-actions';
import { DEFAULT_CLIENT_FILTERS } from './Client.reducer';
import { Client } from './Client.types';
import { clientTypeMemo } from './Client.utils';

export const clientFiltersSelector = (state: RootState) => state.client.list.filters;

export const clientChangedFiltersSelector = createSelector(clientFiltersSelector, (filters) =>
  omitBy(filters, (v) => Object.values(DEFAULT_CLIENT_FILTERS).includes(v)),
);

export const sortDescriptorSelector = (state: RootState) => state.client.list.sortDescriptor;

export const clientLoadingSelector = (name: Client.AsyncActionKeys) => (state: RootState) =>
  state.client.loading[name];

export const clientErrorSelector = (name: Client.AsyncActionKeys) => (state: RootState) =>
  state.client.error[name];

export const clientItemsSelector = (state: RootState) => state.client.list.items;

export const clientIdsSelector = createSelector(clientItemsSelector, (items) =>
  items.map(({ id }) => id),
);

export const selectedClientIdsSelector = (state: RootState) => state.client.list.selected;

export const isCreateDialogOpenSelector = (state: RootState) =>
  state.client.dialogStates[Client.Dialogs.CREATE_CLIENT].isOpen;

export const editDialogSelector = (state: RootState) =>
  state.client.dialogStates[Client.Dialogs.EDIT_CLIENT];

export const isEditDialogOpenSelector = createSelector(editDialogSelector, ({ isOpen }) => isOpen);

export const editClientIdSelector = createSelector(editDialogSelector, ({ clientId }) => clientId);

export const editClientSelector = createSelector(
  editClientIdSelector,
  clientItemsSelector,
  (clientId, clients) => {
    const { name = '', type = '' } = clients.find(({ id }) => id === clientId) ?? {};
    return { name, type } as Client.CreateClientRequest;
  },
);

export const clientIdsForDeleteSelector = (state: RootState) =>
  state.client.dialogStates[Client.Dialogs.DELETE_CONFIRMATION].clientIdList;

export const selectedClientsSelector = createSelector(
  clientItemsSelector,
  selectedClientIdsSelector,
  (clients, selectedIds) => clients.filter(({ id }) => selectedIds.includes(id)),
);

export const clientsReadyForDeletionSelector = createSelector(
  clientIdsForDeleteSelector,
  clientItemsSelector,
  (clientIdsForDelete, clientItems) =>
    clientIdsForDelete.map((id) => clientItems.find((item) => item.id === id)).filter(Boolean),
);

export const selectedClientCountSelector = createSelector(
  selectedClientIdsSelector,
  (selected) => selected.length,
);

export const listableClientTypesSelector = createSelector(clientTypeSelector, (clientType) => {
  const typeIndexInHierarchy = Client.TypeHierarchy.indexOf(clientType);
  return Client.TypeHierarchy.filter((_v, i) => i > typeIndexInHierarchy).map((key) => ({
    eventKey: key,
    content: clientTypeMemo(key),
  }));
});

export const validateClientSelector = (state: RootState) =>
  state.client.deleteClients.validateClients;
