import { combineReducers } from 'redux';
import { Client } from 'store/Client/Client.types';
import { CREATE_USER_CLIENT_TYPES } from 'store/Client/Client.utils';
import {
  errorReducerCreator,
  itemsReducerCreator,
  loadingReducerCreator,
  pageReducerCreator,
  filterReducerCreator,
} from 'store/utils';
import {
  setFindClientName,
  asyncActions,
  findClientAsync,
  resetFind,
  clearFilters,
} from './AssignClient.actions';

const items = itemsReducerCreator<Client.ExternalClient>({
  getterAsyncAction: findClientAsync,
  resetAction: resetFind,
  extended: [[[setFindClientName], () => []]],
});

const page = pageReducerCreator({
  listAsyncAction: findClientAsync,
  resetAction: resetFind,
});

const filter = filterReducerCreator<Partial<Client.FindFilters>>({
  setterAction: setFindClientName,
  resetAction: clearFilters,
  defaultState: {
    name: '',
    types: CREATE_USER_CLIENT_TYPES,
  },
});
/**
 * Loading reducer ↓
 */
export const loading = loadingReducerCreator(asyncActions, {
  findClientAsync: { set: [setFindClientName] },
});

/**
 * Error reducer ↓
 */

export const error = errorReducerCreator(asyncActions, {
  findClientAsync: {
    reset: [resetFind],
  },
});

export const assignClientReducer = combineReducers({
  items,
  page,
  filter,
  loading,
  error,
});
