import deviceApi from './Device/Device.api';
import fileApi from './File/File.api';
import updateDeviceApi from './UpdateDevice/UpdateDevice.api';
import authorizationApi from './Authorization/Authorization.api';
import userApi from './User/User.api';
import websocket from './Websocket/Websocket';
import client from './Client/Client.api';
import analytics from './Analytics/Analytics.utils';

export default {
  api: {
    device: deviceApi,
    file: fileApi,
    updateDevice: updateDeviceApi,
    authorization: authorizationApi,
    user: userApi,
    client,
    websocket,
  },
  utils: {
    analytics,
  },
};
