import { IoT } from 'store/Device/Device.types';
import { File } from 'store/File/File.types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const findConfigFileAsync = createAsyncAction(
  'UPDATE_DEVICE | FIND_CONFIG_FILE_REQ',
  'UPDATE_DEVICE | FIND_CONFIG_FILE_RES',
  'UPDATE_DEVICE | FIND_CONFIG_FILE_ERR',
)<void, File.FindFileResponse, string>();

export const findFirmwareFileAsync = createAsyncAction(
  'UPDATE_DEVICE | FIND_FIRMWARE_FILE_REQ',
  'UPDATE_DEVICE | FIND_FIRMWARE_FILE_RES',
  'UPDATE_DEVICE | FIND_FIRMWARE_FILE_ERR',
)<void, File.FindFileResponse, string>();

export const findExtFwFileAsync = createAsyncAction(
  'UPDATE_DEVICE | FIND_EXT_FW_FILE_REQ',
  'UPDATE_DEVICE | FIND_EXT_FW_FILE_RES',
  'UPDATE_DEVICE | FIND_EXT_FW_FILE_ERR',
)<void, File.FindFileResponse, string>();

export const findBluetoothFileAsync = createAsyncAction(
  'UPDATE_DEVICE | FIND_BLUETOOTH_FILE_REQ',
  'UPDATE_DEVICE | FIND_BLUETOOTH_FILE_RES',
  'UPDATE_DEVICE | FIND_BLUETOOTH_FILE_ERR',
)<void, File.FindFileResponse, string>();

export const setFindConfigFileName = createAction('UPDATE_DEVICE | SET_FIND_CONFIG_FILE_NAME')<
  File.ExternalDeviceFileMetadata['fileName']
>();

export const setFindFirmwareFileName = createAction('UPDATE_DEVICE | SET_FIND_FIRMWARE_FILE_NAME')<
  File.ExternalDeviceFileMetadata['fileName']
>();

export const setFindBluetoothFileName = createAction(
  'UPDATE_DEVICE | SET_FIND_BLUETOOTH_FILE_NAME',
)<File.ExternalDeviceFileMetadata['fileName']>();

export const setFindExtFwFileName = createAction('UPDATE_DEVICE | SET_FIND_EXT_FW_FILE_NAME')<
  File.ExternalDeviceFileMetadata['fileName']
>();

export const endOfConfigListReached = createAction('UPDATE_DEVICE | CONFIG.END_OF_LIST_REACHED')();
export const endOfFirmwareListReached = createAction(
  'UPDATE_DEVICE | FIRMWARE.END_OF_LIST_REACHED',
)();

export const endOfExtFwListReached = createAction('UPDATE_DEVICE | EXT_FW.END_OF_LIST_REACHED')();

export const endOfBluetoothListReached = createAction(
  'UPDATE_DEVICE | BLUETOOTH.END_OF_LIST_REACHED',
)();

export const resetFindConfig = createAction('UPDATE_DEVICE | CONFIG.RESET_FIND')();

export const resetFindFirmware = createAction('UPDATE_DEVICE | FIRMWARE.RESET_FIND')();
export const resetFindExtFw = createAction('UPDATE_DEVICE | EXT_FW.RESET_FIND')();

export const resetFindBluetooth = createAction('UPDATE_DEVICE | BLUETOOTH.RESET_FIND')();

export const setUpdateDeviceType = createAction(
  'UPDATE_DEVICE | SET_UPDATE_DEVICE_TYPE',
)<IoT.DeviceType>();

export const commandsUploadAsync = createAsyncAction(
  'UPDATE_DEVICE | COMMAND_UPLOAD_REQ',
  'UPDATE_DEVICE | COMMAND_UPLOAD_RES',
  'UPDATE_DEVICE | COMMAND_UPLOAD_ERR',
)<IoT.CommandUploadRequest, void, string>();

export const getBasicParametersAsync = createAsyncAction(
  'UPDATE_DEVICE | GET_BASIC_PARAMETERS_REQ',
  'UPDATE_DEVICE | GET_BASIC_PARAMETERS_RES',
  'UPDATE_DEVICE | GET_BASIC_PARAMETERS_ERR',
)<IoT.Device['lastCommandStatus']['basicParametersId'], IoT.BasicParameters, string>();

export const uploadBasicParametersAsync = createAsyncAction(
  'UPDATE_DEVICE | UPLOAD_BASIC_PARAMETERS_REQ',
  'UPDATE_DEVICE | UPLOAD_BASIC_PARAMETERS_RES',
  'UPDATE_DEVICE | UPLOAD_BASIC_PARAMETERS_ERR',
)<IoT.BasicParameters, IoT.UploadBasicParametersResponse, string>();

export const asyncActions = {
  findConfigFileAsync,
  findFirmwareFileAsync,
  findBluetoothFileAsync,
  findExtFwFileAsync,
  commandsUploadAsync,
  getBasicParametersAsync,
  uploadBasicParametersAsync,
};
