import { deleteFileAsync } from 'store/File/File.actions';
import { File } from 'store/File/File.types';
import { pageReducerCreator } from 'store/utils';
import { ActionCreatorBuilder, createReducer } from 'typesafe-actions';
import {
  findBluetoothFileAsync,
  findConfigFileAsync,
  findExtFwFileAsync,
  findFirmwareFileAsync,
  resetFindBluetooth,
  resetFindConfig,
  resetFindExtFw,
  resetFindFirmware,
  setFindBluetoothFileName,
  setFindConfigFileName,
  setFindExtFwFileName,
  setFindFirmwareFileName,
} from './UpdateDevice.actions';

export const fileFinderReducer = (
  findAsyncAction:
    | typeof findConfigFileAsync
    | typeof findFirmwareFileAsync
    | typeof findBluetoothFileAsync
    | typeof findExtFwFileAsync,
  filterChangeAction:
    | typeof setFindConfigFileName
    | typeof setFindFirmwareFileName
    | typeof setFindBluetoothFileName
    | typeof setFindExtFwFileName,
  resetAction:
    | typeof resetFindConfig
    | typeof resetFindFirmware
    | typeof resetFindBluetooth
    | typeof resetFindExtFw,
) => {
  const page = pageReducerCreator({
    listAsyncAction: findAsyncAction,
    resetAction: ([resetAction, filterChangeAction] as any) as ActionCreatorBuilder<any>, // this is a hack
  });
  const items = createReducer<File.State.Find['items']>([])
    .handleAction(findAsyncAction.success, (state, { payload }) => state.concat(payload.items))
    .handleAction([filterChangeAction, resetAction], () => [])
    .handleAction(deleteFileAsync.success, (state, { payload }) =>
      state.filter((file) => !payload.includes(file.uuid)),
    );
  // TODO: use filterReducerCreator from /utils.ts, need to change of actions

  const filter = createReducer<File.ExternalDeviceFileMetadata['fileName']>('')
    .handleAction(filterChangeAction, (_state, { payload }) => {
      console.log(payload);
      return payload;
    })
    .handleAction(resetAction, () => '');

  return {
    page,
    items,
    filter,
  };
};

export default {
  fileFinderReducer,
};
