import Icon from '@tt2/components/build/Atoms/Icon';
import React from 'react';
import cn from 'classnames';
import styles from './ExpandedArrow.css';

export interface ExpandedArrowProps {
  isOpen: boolean;
}

export const ExpandedArrow: React.FC<ExpandedArrowProps> = ({ isOpen }) => {
  return (
    <Icon
      name="keyboard_arrow_down"
      className={cn(styles.icon, { [styles.rotate]: isOpen })}
      size={10}
      isCentered
      data-test-id={isOpen ? 'up' : 'down'}
    />
  );
};
