/* eslint-disable @typescript-eslint/naming-convention */
import { RequestState } from './types/common';

export type Environment = 'test' | 'prep' | 'prod';
declare const SYSTEM_ENV: Environment;
declare const VERSION: string;
declare const BRANCH: string;
declare const DEV_SERVER: boolean;
const __SYSTEM_ENV__ = SYSTEM_ENV;
const __VERSION__ = VERSION;
const __BRANCH__ = BRANCH;
const __DEV_SERVER__ = DEV_SERVER;

export {
  __SYSTEM_ENV__ as SYSTEM_ENV,
  __VERSION__ as VERSION,
  __BRANCH__ as BRANCH,
  __DEV_SERVER__ as DEV_SERVER,
};

export const DEFAULT_REQUEST_STATE: RequestState = {
  error: null,
  loading: false,
};

export const GA_PROPERTY_ID = 'UA-63176091-20';
export const HOTJAR_ID = 2312780;
export const SENTRY_DSN = 'https://19c964fc2d194d5599bb438cf1197b48@error.ruptela.lt/116';

export enum MediaQuerySize {
  SM = 600,
  MD = 768,
  LG = 992,
  XL = 1200,
}

export const SESSION_TTL = 1200000;
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGINATION_PAGE_SIZE = 25;
export const EMPTY_VALUE = '-';
export const EMPTY_VALUE_BIG = '\u2014'; // em dash
export const ANIMATION_TIME = 300;
export const DEFAULT_LANGUAGE = 'en';
export const STARS = '* * * * * * * *';

export default {
  DETAILED_ICON_SIZE: 48,
  LIST_MAX_HEIGHT: 200,
  DIALOG_WIDTH: 620,
  WARNING_DIALOG_WIDTH: 400,
  SIDEBAR_WIDTH: 500,
  shortDelay: 100,
  defaultDelay: 500,
  longDelay: 1000,
  tableRowHeight: 60,
  tableActionsWidth: 18,
  tableSelectWidth: 35,
  FILTER_POPOVER_WIDTH: 327,
  dateFormat: 'L',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss z',
  dataJavaFormat: 'YYYY-MM-DDTHH:mm:ss.SSS',
};
