// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ftGdun8-S--C4Zi5_oAEL {\n  display: flex;\n  align-items: center;\n}\n\n._1Nr2emoctrBNiMvGOFdRem {\n  font-size: var(--font-size6);\n  margin: 0;\n  font-weight: normal;\n  display: flex;\n  line-height: var(--font-size9);\n}\n\n._3a2b3Y7fHTGhnrZ2jg0Okk {\n  margin: 0 var(--length2);\n}", ""]);
// Exports
exports.locals = {
	"breadcrumb": "_2ftGdun8-S--C4Zi5_oAEL",
	"section": "_1Nr2emoctrBNiMvGOFdRem",
	"divider": "_3a2b3Y7fHTGhnrZ2jg0Okk"
};
module.exports = exports;
