import { get } from 'js-cookie';

/**
 * Default headers for all requests
 * Can be overriden on each individual request
 */
export const getDefaultHeaders = () => {
  return {
    'Content-Type': 'application/json',
  };
};

export const getPostRequests = (): Record<string, string> => {
  const xsrf = get('XSRF-TOKEN');
  return {
    ...getDefaultHeaders(),
    ...(!!xsrf && { 'X-XSRF-TOKEN': xsrf }),
  };
};
