import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { closeWebsocket, initWebsocket } from './Websocket.actions';

export const connectionStarted = createReducer(false)
  .handleAction(initWebsocket, () => true)
  .handleAction(closeWebsocket, () => false);

export const WebsocketReducer = combineReducers({
  connectionStarted,
});
