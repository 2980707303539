import { RootState } from 'typesafe-actions';
import { UpdateDevice } from './UpdateDevice.types';

export const updateDeviceLoadingSelector = (name: UpdateDevice.AsyncActionKeys) => (
  state: RootState,
) => state.updateDevice.loading[name];

export const updateDeviceErrorSelector = (name: UpdateDevice.AsyncActionKeys) => (
  state: RootState,
) => state.updateDevice.error[name];

export const updateDeviceConfigPageSelector = (state: RootState) =>
  state.updateDevice.configFiles.page;

export const updateDeviceFirmwarePageSelector = (state: RootState) =>
  state.updateDevice.firmwareFiles.page;

export const updateDeviceExtFwPageSelector = (state: RootState) =>
  state.updateDevice.extFwFiles.page;

export const updateDeviceBluetoothPageSelector = (state: RootState) =>
  state.updateDevice.bluetoothFiles.page;

export const updateDeviceTypeSelector = (state: RootState) => state.updateDevice.updateDeviceType;

export const validDisabledValidationSelector = (state: RootState) =>
  state.updateDevice.validDisabledValidation;

export const basicParametersSelector = (state: RootState) => state.updateDevice.basicParameters;
