import Tag from '@tt2/components/build/Atoms/Tag';
import useDropdown from '@tt2/components/build/hooks/useDropdown';
import { ExpandedArrow } from 'components/ExpandedArrow';
import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { useClickOutside } from 'src/utils/useClickOutside';
import { currentUserSelector } from 'store/Authorization/Authorization.selectors';
import { User } from 'store/User/User.types';
import { RootState } from 'typesafe-actions';
import { UserDetails } from 'components/UserDetails/UserDetails';
import { UserDropdownOptions } from 'components/UserDropdownOptions/UserDropdownOptions';
import styles from './UserDropdown.css';

type StateProps = User.Base;
type OwnProps = unknown;
type ActionProps = unknown;

export type UserDropdownProps = StateProps & OwnProps & ActionProps;

export const UserDropdown: React.FC<UserDropdownProps> = ({ email }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const containerRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const toggleDropdown = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useDropdown(dropdownRef, containerRef, isOpen);
  // useClickOutside(dropdownRef, () => setIsOpen(false), isOpen);
  const [setRef] = useClickOutside(() => {
    setIsOpen(false);
  });

  React.useEffect(() => {
    if (isOpen) {
      setRef(dropdownRef.current);
    } else {
      setRef(null);
    }
  }, [isOpen, setRef]);

  return (
    <div ref={containerRef} data-test-id="dropdown">
      <div onClick={toggleDropdown} className={styles.container} data-test-id="expand-dropdown">
        <Tag className={styles.tag} data-test-id="dropdown-tag">
          {email}{' '}
          <div className={styles.arrow}>
            <ExpandedArrow isOpen={isOpen} />
          </div>
        </Tag>
      </div>

      {isOpen && (
        <div ref={dropdownRef} className={styles.dropdown} data-test-id="dropdown-expanded">
          <UserDetails />
          <UserDropdownOptions className={styles.option} navLinkOnClick={() => setIsOpen(false)} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  ...currentUserSelector(state),
});

export default connect(mapStateToProps, null)(UserDropdown);
