import { createAction, createAsyncAction } from 'typesafe-actions';
import { Authorization } from './Authorization.types';

export const loginAsync = createAsyncAction(
  'AUTHORIZATION | LOGIN_REQ',
  'AUTHORIZATION | LOGIN_RES',
  'AUTHORIZATION | LOGIN_ERR',
)<Authorization.LoginParams, Authorization.Token, string>();

export const logoutAsync = createAsyncAction(
  'AUTHORIZATION | LOGOUT_REQ',
  'AUTHORIZATION | LOGOUT_RES',
  'AUTHORIZATION | LOGOUT_ERR',
)<void, void, string>();

export const getCurrentAsync = createAsyncAction(
  'AUTHORIZATION | GET_CURRENT_REQ',
  'AUTHORIZATION | GET_CURRENT_RES',
  'AUTHORIZATION | GET_CURRENT_ERR',
)<void, Authorization.Me, string>();

// eslint-disable-next-line prettier/prettier
export const updateCurrentUser = createAction(
  'AUTHORIZATION | UPDATE_USER_DATA'
)<Pick<Authorization.Me, 'user'>>();

export const clearUserData = createAction('AUTHORIZATION | CLEAR_USER_DATA')();

export const asyncActions = {
  loginAsync,
  logoutAsync,
  getCurrentAsync,
};
