import { combineEpics } from 'redux-observable';
import * as deviceEpics from './Device/Device.epic';
import * as fileEpics from './File/File.epic';
import * as updateDeviceEpics from './UpdateDevice/UpdateDevice.epic';
import * as commandHistoryEpics from './CommandHistory/CommandHistory.epic';
import * as authorizationEpics from './Authorization/Authorization.epic';
import * as Websockets from './Websocket/Websocket.epic';
import * as clientEpics from './Client/Client.epic';
import * as assignDeviceEpics from './AssignDevice/AssignDevice.epic';
import * as userEpics from './User/User.epic';
import * as userAssignClient from './AssignClient/AssignClient.epic';
import * as analyticsEpics from './Analytics/Analytics.epic';

export default combineEpics(
  ...Object.values(deviceEpics),
  ...Object.values(fileEpics),
  ...Object.values(updateDeviceEpics),
  ...Object.values(commandHistoryEpics),
  ...Object.values(authorizationEpics),
  ...Object.values(Websockets),
  ...Object.values(clientEpics),
  ...Object.values(assignDeviceEpics),
  ...Object.values(userEpics),
  ...Object.values(userAssignClient),
  ...Object.values(analyticsEpics),
);
