import React from 'react';

export interface Tag {
  value: string;
  valid: boolean;
}

export type TagMap = Record<string, Tag>;

export const DEFAULT_INPUT_VALUE = '';
export const toInt = (val: string) => parseInt(val, 10);
export const digitPattern = /\d+/g;
export const tagPattern = '^\\d{15}$';
export const parseValue = (value: string): string[] => value.match(digitPattern) || [];
export const testTag = (val: string) => new RegExp(tagPattern).test(val);
export const toTag = (val: string): Tag => ({
  value: val,
  valid: testTag(val),
});

export function arrayToTagMap(itemArray: React.ReactText[], initMap?: TagMap): TagMap;
export function arrayToTagMap(itemArray: never[], initMap: TagMap = {}): TagMap {
  return itemArray.reduce<TagMap>(
    (acc, val) => ({
      ...acc,
      [typeof val === 'number' ? String(val) : val]: toTag(val),
    }),
    initMap,
  );
}
