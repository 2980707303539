import * as React from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import styles from './PageWrapper.css';

export interface PageWrapperProps {
  className?: string;
}
export const PageWrapper: React.FC<PageWrapperProps> = ({ children, className }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    style={{ height: '100%' }}
    className={classnames(className, styles.wrapper)}
  >
    {children}
  </motion.div>
);

export default PageWrapper;
