import React from 'react';
import { stopPropagation } from 'src/utils/keyboard';
import Popover from '@tt2/components/build/Atoms/Popover';
import Icon from '@tt2/components/build/Atoms/Icon';
import { TestId } from '@tt2/components';
import styles from './CellActionPopover.css';

type CellActionPopoverProps = TestId & React.HTMLAttributes<HTMLDivElement>;

export const CellActionPopover: React.FC<CellActionPopoverProps> = ({
  children,
  'data-test-id': testId,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOnClose = React.useCallback(() => setIsOpen(false), []);
  const handleOnOpen = React.useCallback(() => setIsOpen(true), []);
  return (
    <div onClick={stopPropagation} data-test-id={testId} {...rest}>
      <Popover
        preventOverflowPriority={['bottom']}
        arrowEnabled={false}
        isExpanded={isOpen}
        onPopoverOpen={handleOnOpen}
        onPopoverClose={handleOnClose}
        placement="bottom-end"
        width={140}
        popoverContent={<div onClick={handleOnClose}>{children}</div>}
      >
        <Icon className={styles.action} name="dots-vertical" size={18} />
      </Popover>
    </div>
  );
};
