import { LOCATION_CHANGE, push, replace } from 'connected-react-router';
import { matchPath } from 'react-router';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import constants from 'src/constants';
import { routePaths } from 'src/routePaths';
import { parse } from 'src/utils/queryParams';
import { isObserverSelector } from 'store/Authorization/Authorization.selectors';
import {
  commandsUploadAsync,
  uploadBasicParametersAsync,
} from 'store/UpdateDevice/UpdateDevice.actions';
import { isActionOf, isOfType, RootEpic } from 'typesafe-actions';
import {
  assignDeviceAsync,
  checkImeisAsync,
  clearListFilters,
  deleteDeviceAsync,
  deleteMultipleDevices,
  deviceListDestroy,
  deviceListInit,
  editDeviceAsync,
  endOfListReached,
  findDeviceAsync,
  getDeviceAsync,
  initDeviceListOrder,
  initListFilters,
  initPage,
  initPageSize,
  registerDeviceAsync,
  resetList,
  setDeviceListOrder,
  setDialogState,
  setListFilters,
  setPage,
  setPageSize,
  setSelectedDevices,
  toggleAssignSidebar,
  toggleConfigureSidebar,
  toggleDeviceSelect,
  validateDisabledAsync,
} from './Device.actions';
import {
  deviceChangedFiltersSelector,
  deviceRootPathSelector,
  selectedDeviceCountSelector,
  selectedDeviceIdsSelector,
} from './Device.selectors';
import { IoT } from './Device.types';

export const toCheckImeisReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(checkImeisAsync.request)),
    mergeMap(({ payload }) =>
      from(api.device.checkImeis(payload)).pipe(
        map(checkImeisAsync.success),
        catchError((err) => of(checkImeisAsync.failure(String(err)))),
      ),
    ),
  );

export const toGetDeviceReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(getDeviceAsync.request)),
    mergeMap(({ payload }) =>
      from(api.device.getDevice(payload)).pipe(
        map(getDeviceAsync.success),
        catchError((err) => of(getDeviceAsync.failure(String(err)))),
      ),
    ),
  );

export const toFindDeviceReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findDeviceAsync.request)),
    mergeMap(() =>
      from(
        api.device.findDevice({
          sortDescriptor: store$.value.device.list.sortDescriptor,
          pageDescriptor: store$.value.device.list.pageDescriptor,
          ...deviceChangedFiltersSelector(store$.value),
        }),
      ).pipe(
        map(findDeviceAsync.success),
        catchError((err) => of(findDeviceAsync.failure(String(err)))),
      ),
    ),
  );

export const toUpdateQuery: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(findDeviceAsync.request)),
    map(() =>
      matchPath(store$.value.router.location.pathname, {
        path: routePaths.device.root,
        exact: true,
      }),
    ),
    filter((match) => !!match),
    map(() => replace(deviceRootPathSelector(store$.value))),
  );

export const toRegisterDeviceReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(registerDeviceAsync.request)),
    mergeMap(({ payload }) =>
      from(api.device.registerDevice(payload)).pipe(
        map(registerDeviceAsync.success),
        catchError((err) => of(registerDeviceAsync.failure(String(err)))),
      ),
    ),
  );

export const toEditDeviceReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(editDeviceAsync.request)),
    mergeMap(({ payload: { deviceId, params } }) =>
      from(api.device.editDevice(deviceId, params)).pipe(
        map(editDeviceAsync.success),
        catchError((err) => of(editDeviceAsync.failure(String(err)))),
      ),
    ),
  );

export const toAssignDeviceReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(assignDeviceAsync.request)),
    mergeMap(({ payload }) =>
      from(
        api.device.assignDevices({
          clientId: payload,
          deviceIds: selectedDeviceIdsSelector(store$.value),
        }),
      ).pipe(
        map(assignDeviceAsync.success),
        catchError((err) => of(assignDeviceAsync.failure(String(err)))),
      ),
    ),
  );

export const toAssignDeviceRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(assignDeviceAsync.success)),
    mergeMap(() => of(push(routePaths.device.root), findDeviceAsync.request())),
  );

export const toEditDeviceRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(editDeviceAsync.success)),
    mergeMap(() =>
      of(
        setDialogState({
          [IoT.Dialogs.EDIT_DEVICE]: {
            isOpen: false,
            deviceId: null,
          },
        }),
        resetList(),
        findDeviceAsync.request(),
      ),
    ),
  );

/**
 * @description On success registration closes registration dialog and fetch device list
 */
export const toRegisterDeviceRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(registerDeviceAsync.success)),
    mergeMap(() =>
      of(
        setDialogState({ [IoT.Dialogs.REGISTER_DEVICE]: false }),
        resetList(),
        findDeviceAsync.request(),
      ),
    ),
  );

/**
 * @description Makes initial request to get list of files
 */
export const onInit: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(deviceListInit)),
    mergeMap(() => of(findDeviceAsync.request())),
  );

export const onInitStore: RootEpic = (action$) =>
  action$.pipe(
    filter((action) => isOfType(LOCATION_CHANGE)(action)),
    filter((action) => action.payload?.location?.pathname === routePaths.device.root),
    first(),
    map(({ payload }) => payload?.location?.search),
    filter((data) => !!data),
    map<string, IoT.DeviceListLocationQuery>((search) => parse(search)),
    mergeMap((data) =>
      of(
        initPage(data.page),
        initPageSize(data.size),
        initListFilters(data),
        initDeviceListOrder({
          direction: data.sortDir,
          identifier: data.sort,
        }),
      ),
    ),
  );

/**
 * @description Cancel all requests on module unmount
 */
export const onDestroy: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(deviceListDestroy)),
    tap(() => {
      api.device.deviceService.cancelAll();
    }),
    mergeMap(() => EMPTY),
  );

/**
 * @description On device list table endOfListReached,
 * checking if there is continuationToken not null,
 * calling findDeviceAsync.request to load more devices
 */
export const toGetMoreDevicesReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfListReached)),
    filter(() => state$.value.device.list.pageDescriptor.page != null),
    map(() => findDeviceAsync.request()),
  );

export const toSetDeviceListOrder: RootEpic = (action$, _store, { api }) =>
  action$.pipe(
    filter(isActionOf([setDeviceListOrder, clearListFilters])),
    tap(() => {
      api.device.deviceService.subgroup('findDevice').cancelAll();
    }),
    mergeMap(() => of(resetList(), findDeviceAsync.request())),
  );

export const toSetFilter: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setListFilters)),
    tap(() => {
      api.device.deviceService.subgroup('findDevice').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findDeviceAsync.request()),
  );

export const toSetPagination: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf([setPage, setPageSize])),
    tap(() => {
      api.device.deviceService.subgroup('findDevice').cancelAll();
    }),
    map(() => findDeviceAsync.request()),
  );

export const toDeleteMultipleDevices: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(deleteMultipleDevices)),
    map(() =>
      setDialogState({
        [IoT.Dialogs.DELETE_CONFIRMATION]: {
          isOpen: true,
          deviceIdList: selectedDeviceIdsSelector(store$.value),
        },
      }),
    ),
  );

export const toDeleteDeviceReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteDeviceAsync.request)),
    mergeMap(({ payload }) =>
      from(api.device.deleteDevices(payload)).pipe(
        map(deleteDeviceAsync.success),
        catchError((err) => of(deleteDeviceAsync.failure(String(err)))),
      ),
    ),
  );

export const toDeleteDeviceRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(deleteDeviceAsync.success)),
    mergeMap(() =>
      of(
        setDialogState({
          [IoT.Dialogs.DELETE_CONFIRMATION]: {
            isOpen: false,
            deviceIdList: [],
          },
        }),
        resetList(),
        findDeviceAsync.request(),
      ),
    ),
  );

export const toToggleConfigureSidebar: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(toggleConfigureSidebar)),
    map(() => {
      const isConfigureRoute = matchPath(store$.value.router.location.pathname, {
        path: routePaths.device.configure.root,
      });
      const isObserverRole = isObserverSelector(store$.value);
      const tabPathname = isObserverRole
        ? routePaths.device.configure.basicParameters
        : routePaths.device.configure.fwCfg;
      return push(isConfigureRoute ? routePaths.device.root : tabPathname);
    }),
  );

export const toToggleAssignSidebar: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(toggleAssignSidebar)),
    map(() => {
      const path = routePaths.device.assign;
      const isAssignRoute = matchPath(store$.value.router.location.pathname, {
        path,
        exact: true,
      });
      return push(isAssignRoute ? routePaths.device.root : path);
    }),
  );

export const toResetList: RootEpic = (action$) =>
  action$.pipe(
    filter(
      isActionOf([
        clearListFilters,
        setListFilters,
        setPage,
        setPageSize,
        deviceListDestroy,
        commandsUploadAsync.success,
        uploadBasicParametersAsync.success,
        assignDeviceAsync.success,
      ]),
    ),
    map(resetList),
  );

export const toValidateDisabledAsync: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(validateDisabledAsync.request)),
    mergeMap(() =>
      from(api.device.validateDisabled(store$.value.device.list.selected)).pipe(
        map(validateDisabledAsync.success),
        catchError((err) => of(validateDisabledAsync.failure(String(err)))),
      ),
    ),
  );

export const toValidateDisabledOnBp: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(
      (action) =>
        isOfType(LOCATION_CHANGE)(action) ||
        isActionOf([toggleDeviceSelect, setSelectedDevices, resetList])(action),
    ),
    map(() =>
      matchPath(store$.value.router.location.pathname, {
        path: [routePaths.device.configure.root],
      }),
    ),
    filter((match) => !!match),
    filter(() => !isObserverSelector(store$.value)),
    filter(() => !!selectedDeviceCountSelector(store$.value)),
    map(() => selectedDeviceIdsSelector(store$.value)),
    distinctUntilChanged(),
    map(() => validateDisabledAsync.request()),
  );
