import { File } from 'store/File/File.types';
import { getPostRequests } from '../defaultHeaders';
import { http2 } from '../http2';
import { handleResponse } from '../utils';

type FileServiceRequests = 'findFile' | 'uploadFile' | 'validateFiles' | 'getFile';

export const fileService = http2.createGroup<FileServiceRequests>('file');

export const findFile = async (
  params: File.FindFileRequest,
  version = 1,
): Promise<File.FindFileResponse> => {
  const url = `/api/files/find?version=${version}`;
  return fileService
    .subgroup('findFile')
    .fetch(url, { method: 'POST', body: JSON.stringify(params), headers: getPostRequests() })
    .request.then(handleResponse('Error finding files'));
};

export const uploadFile = async (
  data: File.UploadFile,
  version = 1,
): Promise<File.ExternalDeviceFileMetadata> => {
  const url = `/api/files/upload?version=${version}`;

  const formData = new FormData();
  formData.append('file', new Blob([data.file], { type: 'multipart/form-data' }), data.file.name);
  formData.append(
    'metadata',
    new Blob([JSON.stringify({ visibility: data.visibility })], { type: 'application/json' }),
    'metadata.json',
  );

  return fileService
    .subgroup('uploadFile')
    .fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        ...getPostRequests(),
        'Content-Type': undefined,
        Accept: 'application/json, text/plain, */*',
      },
    })
    .request.then(handleResponse(`Error uploading ${data.file.name}`));
};

export const validateFiles = async (
  uuidList: File.ExternalDeviceFileMetadata['uuid'][],
  version = 1,
): Promise<File.ValidateResponse> => {
  const url = `/api/files/validate/delete?version=${version}`;

  return fileService
    .fetch(url, { method: 'POST', body: JSON.stringify(uuidList), headers: getPostRequests() })
    .request.then(handleResponse('Error validating files'));
};

export const deleteFiles = async (
  uuidList: File.ExternalDeviceFileMetadata['uuid'][],
  version = 1,
): Promise<File.ExternalDeviceFileMetadata['uuid'][]> => {
  const url = `/api/files?version=${version}`;

  return fileService
    .subgroup('validateFiles')
    .fetch(url, { method: 'DELETE', body: JSON.stringify(uuidList), headers: getPostRequests() })
    .request.then(handleResponse<File.DeleteFilesResponse>('Error deleting files'))
    .then((res) => res.deletedFiles);
};

export const getFile = async (
  data: {
    fileId: File.ExternalDeviceFileMetadata['uuid'];
    fileName: File.ExternalDeviceFileMetadata['fileName'];
  },
  version = 1,
): Promise<Blob | void> => {
  const url = `/api/files/${data.fileId}?version=${version}`;

  return fileService
    .subgroup('getFile')
    .fetch(url, {
      headers: {
        'Content-Type': 'text/plain',
        responseType: 'blob',
      },
    })
    .request.then((res) => {
      if (res.ok) {
        return res.blob();
      }
      throw Error(res.status === 400 ? 'Device file was not found' : 'Error in getting file');
    })
    .then((blob) => {
      // 2. Create blob link to download
      const href = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', data.fileName);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(href);
    });
};

export default {
  fileService,
  findFile,
  uploadFile,
  deleteFiles,
  validateFiles,
  getFile,
};
