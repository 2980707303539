import { ListResponse, PageDescriptor, SortDescriptor } from 'src/types/common';
import { IoT } from 'store/Device/Device.types';
import { File } from 'store/File/File.types';
import { asyncActions } from './CommandHistory.actions';

export namespace CommandHistory {
  export enum HistorySortIdentifier {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    STATUS = 'STATUS',
  }
  export type HistoryListSortDescriptor = SortDescriptor<HistorySortIdentifier>;

  export interface FindFilters {
    basicParametersId?: IoT.Device['lastCommandStatus']['basicParametersId'];
    createdFrom?: string;
    createdTo?: string;
    fileId?: File.ExternalDeviceFileMetadata['uuid'];
    status?: IoT.CommandStatus[];
    type?: File.FileType[];
  }

  export type ListFilters = Extract<
    ListColumnKey,
    ListColumnKey.DATE | ListColumnKey.FILE_TYPE | ListColumnKey.STATUS
  >;
  export type ListFiltersState = { [K in CommandHistory.ListFilters]: boolean };
  export interface FindHistoryRequest extends FindFilters {
    deviceId: IoT.Device['id'];
    pageDescriptor: PageDescriptor;
    sortDescriptor: HistoryListSortDescriptor;
  }

  export interface ExternalIoTDeviceCommand {
    basicParametersId: IoT.Device['lastCommandStatus']['basicParametersId'];
    created: string;
    deviceId: IoT.Device['id'];
    fileId: File.ExternalDeviceFileMetadata['uuid'];
    fileName: File.ExternalDeviceFileMetadata['fileName'];
    fileSize: number;
    fileType: File.FileType;
    lastUploadedFileOffset: number;
    status: IoT.CommandStatus;
    updated: string;
    id: number;
  }
  export type FindHistoryResponse = ListResponse<ExternalIoTDeviceCommand>;

  export enum ListColumnKey {
    DATE = 'created',
    STATUS = 'status',
    FILE_TYPE = 'fileType',
    FILE_NAME = 'fileName',
    ACTIONS = 'actions',
  }

  export type CancelParams = Pick<ExternalIoTDeviceCommand, 'id'>;

  export enum Dialogs {
    BASIC_PARAMETER = 'BASIC_PARAMETER',
    NO_FILE_WARNING = 'NO_FILE_WARNING',
  }

  export type DialogStates = {
    [Dialogs.BASIC_PARAMETER]: {
      isOpen: boolean;
      id: ExternalIoTDeviceCommand['id'];
    };
    [Dialogs.NO_FILE_WARNING]: boolean;
  };

  export type AsyncActionKeys = keyof typeof asyncActions;
}
