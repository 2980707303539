// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/login-background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._3r41DOwZyiWjyzqOz43Cyr {\n  justify-content: center;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  flex: 1;\n  align-items: center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n._3-RG0orWiWtCsMD9nt7czo {\n  border-radius: var(--border-radius-strong);\n  background: var(--color-gray6);\n  box-sizing: border-box;\n  margin: var(--length5);\n  padding: var(--length5) var(--length8);\n  padding-bottom: var(--length6);\n  border: 1px solid var(--border-color-main);\n  max-width: 320px;\n  width: 100%;\n}\n\n.EHBlWBRXxxQQ2lcs8sRQk {\n  display: flex;\n  justify-content: center;\n}\n\n._2XNDmerfRKDbubNdRD0Qxp {\n  display: flex;\n  justify-content: center;\n}\n\n.sg2sgT-TEZ0FaUweChWxS {\n  margin-bottom: var(--length4);\n}\n", ""]);
// Exports
exports.locals = {
	"background": "_3r41DOwZyiWjyzqOz43Cyr",
	"form": "_3-RG0orWiWtCsMD9nt7czo",
	"logo": "EHBlWBRXxxQQ2lcs8sRQk",
	"footer": "_2XNDmerfRKDbubNdRD0Qxp",
	"error": "sg2sgT-TEZ0FaUweChWxS"
};
module.exports = exports;
