import { memoize } from 'lodash';
import t, { TranslateKey } from 'src/utils/translate';
import { Client } from './Client.types';

export const clientTypeMemo = memoize((type) => t(`Client.Type.${type}` as TranslateKey));

export const CREATE_USER_CLIENT_TYPES = [
  Client.Type.DISTRIBUTOR,
  Client.Type.RUPTELA_BRANCH,
  Client.Type.RUPTELA_HQ,
  Client.Type.SERVICE_PROVIDER,
];
