import { combineReducers } from 'redux';
import { errorReducerCreator, listReducerCreator, loadingReducerCreator } from 'store/utils';
import { User } from 'store/User/User.types';
import {
  asyncActions,
  clearListFilters,
  deleteMultipleUsers,
  deleteUserAsync,
  findUserAsync,
  initListFilters,
  initListOrdering,
  resetList,
  setDialogState,
  setListFilters,
  setSelectedUsers,
  setUserListOrder,
  toggleUserSelect,
} from 'store/User/User.actions';
import { SortDirection } from 'src/types/common';
import { createReducer } from 'typesafe-actions';
import { assignClientReducer } from 'src/store/AssignClient/AssignClient.reducer';

export const DEFAULT_USER_FILTERS: User.FindFilters = {
  name: '',
  clientName: '',
  email: '',
  roles: [],
};

export const DEFAULT_USER_SORTING: User.ListSortDescriptor = {
  direction: SortDirection.ASC,
  identifier: User.SortIdentifier.EMAIL,
};

export const list = listReducerCreator<
  User.UserRes,
  User.ListSortDescriptor,
  User.UserRes['id'],
  User.FindFilters
>(
  {
    getterAsyncAction: findUserAsync,
    resetAction: resetList,
  },
  {
    initAction: initListOrdering,
    setterAction: setUserListOrder,
    defaultState: DEFAULT_USER_SORTING,
  },
  {
    setterAction: setSelectedUsers,
    toggleAction: toggleUserSelect,
    deleteAction: deleteUserAsync,
  },
  {
    initAction: initListFilters,
    setterAction: setListFilters,
    resetAction: clearListFilters,
    defaultState: DEFAULT_USER_FILTERS,
  },
);

const defaultDialogState: User.DialogStates = {
  [User.Dialogs.CREATE_USER]: { isOpen: false },
  [User.Dialogs.EDIT_USER]: {
    isOpen: false,
    userId: null,
  },
  [User.Dialogs.DELETE_CONFIRMATION]: {
    isOpen: false,
    userIdList: [],
  },
};

export const dialogStates = createReducer<User.DialogStates>(
  defaultDialogState,
).handleAction(setDialogState, (state, { payload }) => ({ ...state, ...payload }));

/**
 * Delete user related reducers ↓
 */
export const userIdList = createReducer<User.UserRes['id'][]>([]).handleAction(
  setDialogState,
  (_state, { payload }) => {
    if (payload.DELETE_CONFIRMATION?.isOpen) {
      return payload.DELETE_CONFIRMATION.userIdList;
    }
    return [];
  },
);

export const multipleDelete = createReducer<boolean>(false)
  .handleAction(deleteMultipleUsers, () => true)
  .handleAction(setDialogState, (state, { payload }) =>
    payload?.DELETE_CONFIRMATION?.isOpen === false ? false : state,
  );

export const deleteUsers = combineReducers({
  userIdList,
  multipleDelete,
});

/**
 * Loading reducer ↓
 */

export const loading = loadingReducerCreator(asyncActions, {
  findUserAsync: { set: [resetList] },
});

/**
 * Error reducer ↓
 */

export const error = errorReducerCreator(asyncActions, {
  createUserAsync: { reset: [setDialogState] },
  editUserAsync: { reset: [setDialogState] },
  editUserAndOrPasswordAsync: { reset: [setDialogState] },
  deleteUserAsync: { reset: [setDialogState] },
});

export default combineReducers({
  list,
  loading,
  error,
  dialogStates,
  deleteUsers,
  assignClient: assignClientReducer,
});
