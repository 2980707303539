import qs from 'qs';

export function stringify(params: any) {
  return qs.stringify(params, {
    encode: false,
    arrayFormat: 'comma',
    addQueryPrefix: true,
    skipNulls: true,
  });
}

export function parse(search: string) {
  return qs.parse(search, {
    depth: 1,
    parameterLimit: 20,
    ignoreQueryPrefix: true,
    comma: true,
  });
}