import * as React from 'react';
import translate from 'src/utils/translate';
import { RouteLink } from 'src/types/common';
import { routePaths } from './routePaths';

const routes: RouteLink[] = [
  {
    name: 'Devices',
    path: routePaths.device.root,
    component: React.lazy(() => import('./components/DevicePage')),
    navigationIcon: 'device',
  },
  {
    name: 'Details',
    path: routePaths.device.details.root,
    isSubpage: true,
  },
  {
    name: 'Your Files',
    path: routePaths.file.root,
    component: React.lazy(() => import('./components/FilePage')),
    navigationIcon: 'updates',
  },
  {
    name: 'Clients',
    path: routePaths.client.root,
    component: React.lazy(() => import('./components/ClientPage')),
    navigationIcon: 'client',
  },
  {
    name: translate('Users.Title'),
    path: routePaths.user.root,
    component: React.lazy(() => import('./components/UserPage')),
    navigationIcon: 'users',
  },
  {
    name: translate('AccountSettings.Title'),
    path: routePaths.settings,
    component: React.lazy(() => import('./components/SettingsPage')),
    hide: true,
  },
];

export default routes;
