import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { DEFAULT_REQUEST_STATE } from 'src/constants';
import {
  errorReducerCreator,
  loadingReducerCreator,
  listReducerCreator,
  dialogReducerCreator,
} from 'store/utils';
import { SortDescriptor, SortDirection } from '../../types/common';
import { File } from './File.types';
import {
  findFileAsync,
  setFileListOrder,
  setSelectedFiles,
  toggleFileSelect,
  setDialogState,
  startUploadFiles,
  uploadFileAsync,
  deleteFileAsync,
  finishUploadFile,
  uploadedFilesChanged,
  setListFilters,
  clearListFilters,
  validateFileListAsync,
  deleteMultipleFiles,
  resetList,
  asyncActions,
  initListFilters,
  initListOrdering,
} from './File.actions';

export const DEFAULT_FILE_FILTERS: File.FindFilters = {
  deviceTypes: [],
  fileName: '',
  fileTypes: [],
  visibility: [],
  lastTimeUsedFrom: undefined,
  lastTimeUsedTo: undefined,
  uploadedFrom: undefined,
  uploadedTo: undefined,
};

export const DEFAULT_FILE_SORTING: SortDescriptor<File.SortIdentifier> = {
  direction: SortDirection.ASC,
  identifier: File.SortIdentifier.FILE_NAME,
};

export const list = listReducerCreator<
  File.ExternalDeviceFileMetadata,
  File.FileListSortDescriptor,
  File.ExternalDeviceFileMetadata['uuid'],
  File.FindFilters
>(
  {
    getterAsyncAction: findFileAsync,
    resetAction: resetList,
  },
  {
    initAction: initListOrdering,
    setterAction: setFileListOrder,
    defaultState: DEFAULT_FILE_SORTING,
  },
  {
    setterAction: setSelectedFiles,
    toggleAction: toggleFileSelect,
    deleteAction: deleteFileAsync,
  },
  {
    initAction: initListFilters,
    setterAction: setListFilters,
    resetAction: clearListFilters,
    defaultState: DEFAULT_FILE_FILTERS,
  },
);

/**
 * Dialog states reducer ↓
 */

export const dialogStates = dialogReducerCreator<File.DialogStates>(setDialogState, {
  [File.Dialogs.UPLOAD_FILE]: false,
  [File.Dialogs.DELETE_CONFIRMATION]: {
    isOpen: false,
    fileUUIDList: [],
  },
});
/**
 * Upload files related reducers ↓
 */
const formData = createReducer<File.UploadFormData>({
  files: [],
  visibility: File.Visibility.PRIVATE,
}).handleAction(startUploadFiles, (_state, { payload }) => payload);

const changeStatus = (status: Partial<File.UploadFileState>, index: number) => (
  x: File.UploadFileState,
  ind: number,
) => (ind === index ? { ...x, ...status } : x);

const fileUploadStatus = createReducer<File.UploadFileState[]>([])
  .handleAction(startUploadFiles, (_state, { payload }) =>
    new Array(payload.files.length).fill(DEFAULT_REQUEST_STATE),
  )
  .handleAction(uploadFileAsync.request, (state, { payload }) =>
    state.map(changeStatus({ error: null, loading: true }, payload.index)),
  )
  .handleAction(uploadFileAsync.success, (state, { payload }) =>
    state.map(changeStatus({ loading: false, isDone: true }, payload.index)),
  )
  .handleAction(uploadFileAsync.failure, (state, { payload }) =>
    state.map(changeStatus({ loading: false, isDone: true, error: payload.error }, payload.index)),
  )
  .handleAction(uploadedFilesChanged, () => []);

const isUploading = createReducer<boolean>(false)
  .handleAction(startUploadFiles, () => true)
  .handleAction([finishUploadFile, setDialogState], () => false);

export const upload = combineReducers({
  formData,
  fileUploadStatus,
  isUploading,
});

/**
 * Loading reducer ↓
 */
export const loading = loadingReducerCreator(asyncActions, {
  findFileAsync: { set: [setFileListOrder, finishUploadFile, setListFilters, clearListFilters] },
});

/**
 * Error reducer ↓
 */

export const error = errorReducerCreator(asyncActions, {
  deleteFileAsync: { reset: [setDialogState] },
});

/**
 * Delete files related reducers ↓
 */
export const fileUuidList = createReducer<File.ExternalDeviceFileMetadata['uuid'][]>(
  [],
).handleAction(setDialogState, (_state, { payload }) => {
  if (payload.DELETE_CONFIRMATION?.isOpen) {
    return payload.DELETE_CONFIRMATION.fileUUIDList;
  }
  return [];
});

export const validateFiles = createReducer<File.ValidateResponse>(null)
  .handleAction(validateFileListAsync.success, (_state, { payload }) => payload)
  .handleAction(setDialogState, () => null);

export const multipleDelete = createReducer<boolean>(false)
  .handleAction(deleteMultipleFiles, () => true)
  .handleAction(setDialogState, (state, { payload }) =>
    payload?.DELETE_CONFIRMATION?.isOpen === false ? false : state,
  );

export const deleteFiles = combineReducers({
  fileUuidList,
  validateFiles,
  multipleDelete,
});

export default combineReducers({
  list,
  dialogStates,
  upload,
  loading,
  error,
  deleteFiles,
});
