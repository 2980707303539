import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button, { ButtonProps } from '@tt2/components/build/Atoms/Button';
import Icon from '@tt2/components/build/Atoms/Icon';
import { TestId } from '@tt2/components';
import { CellActionPopover } from 'components/CellActionPopover';
import ListItem from '@tt2/components/build/Atoms/ListItem';
import translate from 'src/utils/translate';
import { PayloadActionCreator } from 'typesafe-actions';

type Meta = Record<string | number | symbol, unknown>;

type UseDialogReturn = {
  open: (meta?: Meta) => void;
  close: (meta?: Meta) => void;
};
export const opened = (dialogName: string, meta?: Meta) => ({
  [dialogName]: { isOpen: true, ...meta },
});
export const closed = (dialogName: string, meta?: Meta) => ({
  [dialogName]: { isOpen: false, ...meta },
});
export const useDialog = (
  dialogName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: PayloadActionCreator<string, any>,
): UseDialogReturn => {
  const dispatch = useDispatch();
  const open = (meta?: Meta) => dispatch(action(opened(dialogName, meta)));
  const close = (meta?: Meta) => dispatch(action(closed(dialogName, meta)));
  return { open, close };
};

export const PlusButton: React.FC<Partial<ButtonProps>> = ({ children, ...rest }) => (
  <Button {...rest}>
    <Icon name="add_plus" size={24} /> {children}
  </Button>
);

interface ActionCellProps extends TestId {
  onDelete: () => void;
  onEdit: () => void;
}

export const ActionCell: React.FC<ActionCellProps> = ({
  onDelete,
  onEdit,
  'data-test-id': dataTestId,
}) => (
  <CellActionPopover data-test-id={dataTestId || 'list-action-cell'}>
    <ListItem onClick={onEdit} data-test-id="edit-click">
      {translate('Global.Button.Edit')}
    </ListItem>
    <ListItem onClick={onDelete} data-test-id="delete-click">
      {translate('Global.Button.Delete')}
    </ListItem>
  </CellActionPopover>
);
