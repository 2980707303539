import { testTag } from 'components/TagInput/utils';
import { IoT } from 'store/Device/Device.types';
import React from 'react';

export type Imei = IoT.Device['imei'] | string;

type Valid = boolean;
type ImeiTag = string;
export type TagMap = Record<ImeiTag, Valid>;

export function arrayToTagMap(itemArray: React.ReactText[], initMap?: TagMap): TagMap;
export function arrayToTagMap(itemArray: never[], initMap: TagMap = {}): TagMap {
  return itemArray.reduce<TagMap>(
    (acc, val) => ({
      ...acc,
      [typeof val === 'number' ? String(val) : val]: testTag(val),
    }),
    initMap,
  );
}

export const isNotValidImei = (tagMap: TagMap) => (imei: string) => !tagMap[imei];
export const isValidImei = (tagMap: TagMap) => (imei: string) => tagMap[imei];

export enum TabKeys {
  NON_EXIST = 'NON_EXIST',
  EXIST = 'EXIST',
  NOT_VALID = 'NOT_VALID',
}

export const TAB_ORDER = [TabKeys.NON_EXIST, TabKeys.EXIST, TabKeys.NOT_VALID];

export type TabImeiArrayMap = { [key in TabKeys]: Imei[] };

export const getFirstTab = (tabImeiArrayMap: TabImeiArrayMap): TabKeys =>
  TAB_ORDER.find((key) => tabImeiArrayMap[key].length);

export const isTabNotEmpty = (tabImeiMap: TabImeiArrayMap) => (tab: TabKeys): boolean =>
  Boolean(tabImeiMap[tab].length);
