// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1MnX5p6BqiT-K4PSnIQzKf {\n  display: flex;\n  flex-direction: column;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_1MnX5p6BqiT-K4PSnIQzKf"
};
module.exports = exports;
