import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import _ from 'lodash';
import { stringify } from './queryParams';

export const calculateURLQuery = <F extends { [key: string]: any }, S>(
  filter$: Observable<F>,
  sort$: Observable<S>,
  defaultFilters: F,
  defaultSort: S,
) =>
  combineLatest([filter$, sort$]).pipe(
    switchMap(([filter, sort]) => {
      const comparedFilters = {
        ...Object.entries(filter).reduce(
          (out, [name, value]: [keyof F, any]) => ({
            ...out,
            [name]: value === defaultFilters[name] ? null : value,
          }),
          {},
        ),
      };
      const comparedSort = _.isEqual(sort, defaultSort) ? null : sort;
      return of(stringify({ ...comparedFilters, ...comparedSort }));
    }),
  );
