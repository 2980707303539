/* eslint-disable @typescript-eslint/no-explicit-any */
import { compose, combineReducers, Reducer, CombinedState, AnyAction } from 'redux';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGINATION_PAGE_SIZE, SYSTEM_ENV } from 'src/constants';
import { ListResponse, ListPagination } from 'src/types/common';
import {
  ActionCreatorBuilder,
  AsyncActionCreatorBuilder,
  createReducer,
  PayloadAction,
  PayloadActionCreator,
  RootAction,
} from 'typesafe-actions';

export const composeEnhancers =
  (SYSTEM_ENV === 'test' && window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const defaults = <T extends Record<string, any>>(data: Partial<T>, defaultData: T) =>
  Object.entries(defaultData).reduce<T>((out, [name, value]) => {
    let next = data[name] ?? value;
    if (Array.isArray(value) && !Array.isArray(next)) {
      next = next ? [next] : [];
    }
    return {
      ...out,
      [name]: next,
    };
  }, defaultData);

type A = AsyncActionCreatorBuilder<any, any, any>;
type Extended<T extends string> = {
  [key in T]?: {
    set?: (ActionCreatorBuilder<any, any> | ActionCreatorBuilder<any>)[];
    reset?: (ActionCreatorBuilder<any, any> | ActionCreatorBuilder<any>)[];
  };
};
/**
 * @description Creator of Error state reducer. Handles async actions.
 * On failure of async action - sets state to payload.
 * On async action request - resets state to null.
 * handleAction of reducer can be extended with additional actions
 * @param  {Record<T, A} propAsyncActionMap - state prop and async action map
 * @example { getFile: getFileAsync, deleteFile: deleteFileAsync }
 * - will producer reducer with initial state { getFile: null, deleteFile: null }
 * @param  {Extended<T>} extended - can extend reducer with additional set / reset actions
 */
export const errorReducerCreator = <T extends string>(
  propAsyncActionMap: Record<T, A>,
  extended: Extended<T> = {},
) =>
  combineReducers(
    Object.entries<A>(propAsyncActionMap).reduce(
      (prev, [key, action]) => ({
        ...prev,
        [key]: createReducer(null)
          .handleAction([action.request, ...(extended[key as T]?.reset ?? [])], () => null)
          .handleAction(
            [action.failure, ...((extended[key as T]?.set as any) ?? [])],
            (_state, { payload }) => payload,
          ),
      }),
      {},
    ),
  ) as Reducer<CombinedState<Record<T, string>>, AnyAction>;

/**
 * @description Creator of Loading state reducer. Handles async actions.
 * On async action request - sets state to true.
 * On success or failure of async action - resets state to false.
 * handleAction of reducer can be extended with additional actions
 * @param  {Record<T, A} propAsyncActionMap - state prop and async action map
 * @example { getFile: getFileAsync, deleteFile: deleteFileAsync }
 * - will producer reducer with initial state { getFile: false, deleteFile: false }
 * @param  {Extended<T>} extended - can extend reducer with additional set / reset actions
 */
export const loadingReducerCreator = <T extends string>(
  propAsyncActionMap: Record<T, A>,
  extended: Extended<T> = {},
) =>
  combineReducers(
    Object.entries<A>(propAsyncActionMap).reduce(
      (prev, [key, action]) => ({
        ...prev,
        [key]: createReducer(false)
          .handleAction([action.request, ...(extended[key as T]?.set ?? [])], () => true)
          .handleAction(
            [action.failure, action.success, ...((extended[key as T]?.reset as any) ?? [])],
            () => false,
          ),
      }),
      {},
    ),
  ) as Reducer<CombinedState<Record<T, boolean>>, AnyAction>;

type PageParams<T> = {
  listAsyncAction: ListGetterAsyncAction<T>;
  resetAction: ResetAction;
  defaultState?: number;
};

export const pageReducerCreator = <T>({
  listAsyncAction,
  resetAction,
  defaultState = 0,
}: PageParams<T>): Reducer<number> =>
  createReducer<number>(defaultState)
    .handleAction<any, PayloadAction<any, ListResponse<T>>, never>(
      listAsyncAction.success,
      (_state, { payload: { continuationToken = null } }) => continuationToken,
    )
    .handleAction<any, never, never>(resetAction, () => defaultState);

type PaginationParams<T> = {
  listAsyncAction: ListGetterAsyncAction<T>;
  defaultState?: ListPagination;
};

const paginationReducerCreator = <T>({
  listAsyncAction,
  defaultState = {
    pageNumber: 0,
    pageSize: 0,
    totalItems: 0,
    totalPages: 0,
  },
}: PaginationParams<T>): Reducer<ListPagination> =>
  createReducer<ListPagination>(defaultState).handleAction(
    listAsyncAction.success,
    (_state, { payload: { page } }) => ({
      pageNumber: page?.pageNumber ?? defaultState.pageNumber,
      pageSize: page?.pageSize ?? defaultState.pageSize,
      totalItems: page?.totalItems ?? defaultState.totalItems,
      totalPages: page?.totalPages ?? defaultState.totalPages,
    }),
  );

type SelectedParams<T> = {
  toggleAction: PayloadActionCreator<any, T>;
  setterAction: PayloadActionCreator<any, T[]>;
  resetAction: ActionCreatorBuilder<any>;
  deleteAction: AsyncActionCreatorBuilder<[any, T[]], [any, T[]], [any, string]>;
};

export const selectedReducerCreator = <T extends any>({
  toggleAction,
  setterAction,
  resetAction,
  deleteAction,
}: SelectedParams<T>) =>
  createReducer<T[]>([])
    .handleAction<any, PayloadAction<any, T>, never>(toggleAction, (state, { payload }) => {
      const existingIndex = state.findIndex((index) => index === payload);

      if (existingIndex !== -1) {
        const newState = [...state];
        newState.splice(existingIndex, 1);
        return newState;
      }

      return [...state, payload];
    })
    .handleAction<any, PayloadAction<any, T[]>, never>(
      setterAction,
      (_state, { payload }) => payload,
    )
    .handleAction<any, never, never>(resetAction, () => [])
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .handleAction<any, PayloadAction<any, T[]>, never>(deleteAction.success, (state, { payload }) =>
      state.filter((id) => !payload.includes(id)),
    );

export const dialogReducerCreator = <T extends Record<string, unknown>>(
  setterAction: PayloadActionCreator<any, Partial<T>>,
  defaultState: T,
) =>
  createReducer<T>(defaultState).handleAction(setterAction, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

type FilterParams<T> = {
  initAction?: PayloadActionCreator<any, Partial<T>>;
  setterAction: PayloadActionCreator<any, Partial<T>>;
  resetAction: ResetAction;
  defaultState: T;
};

export const filterReducerCreator = <T extends Record<string, any>>({
  initAction,
  resetAction,
  setterAction,
  defaultState,
}: FilterParams<T>) =>
  createReducer<T>(defaultState)
    .handleAction(resetAction, () => defaultState)
    .handleAction<any, PayloadAction<any, Partial<T>>, never>(
      setterAction,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    )
    .handleAction<any, PayloadAction<any, Partial<T>>, never>(
      [...(initAction ? [initAction] : [])],
      (_state, { payload }) => defaults<T>(payload, defaultState),
    );

type SortDescriptorParams<T> = {
  initAction?: PayloadActionCreator<any, T>;
  setterAction: PayloadActionCreator<any, T>;
  defaultState: T;
};

export const sortDescriptorReducerCreator = <T extends Record<string, any>>({
  defaultState,
  initAction,
  setterAction,
}: SortDescriptorParams<T>) =>
  createReducer<T>(defaultState)
    .handleAction<any, PayloadAction<any, T>, never>(setterAction, (_state, { payload }) => payload)
    .handleAction<any, PayloadAction<any, Partial<T>>, never>(
      [...(initAction ? [initAction] : [])],
      (_state, { payload }) => defaults<T>(payload, defaultState),
    );

type ListGetterAsyncAction<T> = AsyncActionCreatorBuilder<
  [any, any],
  [any, ListResponse<T>],
  [any, string]
>;
type ResetAction = ActionCreatorBuilder<any>;
type ItemsParams<T> = {
  getterAsyncAction: ListGetterAsyncAction<T>;
  resetAction: ResetAction;
  extended?: [RootAction[], (state: T[], action: RootAction) => T[]][];
};

export const itemsReducerCreator = <T extends any>({
  getterAsyncAction,
  resetAction,
  extended = [],
}: ItemsParams<T>): Reducer<T[]> =>
  extended.reduce(
    (pre, [actions, handler]) => pre.handleAction(actions, handler),
    createReducer<T[]>([])
      .handleAction<any, PayloadAction<any, ListResponse<T>>, never>(
        getterAsyncAction.success,
        (state, { payload }) => state.concat(payload.items),
      )
      .handleAction<any, never, never>(resetAction, (): T[] => []),
  );

export const paginationItemsReducerCreator = <T extends any>({
  getterAsyncAction,
  resetAction,
  extended = [],
}: ItemsParams<T>): Reducer<T[]> =>
  extended.reduce(
    (pre, [actions, handler]) => pre.handleAction(actions, handler),
    createReducer<T[]>([])
      .handleAction<any, PayloadAction<any, ListResponse<T>>, never>(
        getterAsyncAction.success,
        (_state, { payload }) => payload.items,
      )
      .handleAction<any, never, never>(resetAction, (): T[] => []),
  );

type OmitReset<T extends { resetAction: any }> = Omit<T, 'resetAction'>;
type PaginationCreatorParams = {
  initPageAction?: ActionCreatorBuilder<any, string | number>;
  setterPageAction: PayloadActionCreator<any, number>;
  initPageSizeAction?: PayloadActionCreator<any, string | number>;
  setterPageSizeAction: PayloadActionCreator<any, number>;
};

export const listReducerCreator = <Item, SortDescriptor, ItemId, Filter>(
  itemParams: ItemsParams<Item>,
  sortParams: SortDescriptorParams<SortDescriptor>,
  selectedParams: OmitReset<SelectedParams<ItemId>>,
  filterParams: FilterParams<Filter>,
  paginationParams?: PaginationCreatorParams,
) =>
  combineReducers({
    items: paginationParams
      ? paginationItemsReducerCreator<Item>(itemParams)
      : itemsReducerCreator<Item>(itemParams),
    pagination: paginationReducerCreator<Item>({
      listAsyncAction: itemParams.getterAsyncAction,
    }),
    pageDescriptor: combineReducers({
      ...(paginationParams
        ? {
            page: createReducer<number>(0)
              .handleAction(
                [
                  paginationParams.setterPageAction,
                  ...(paginationParams.initPageAction ? [paginationParams.initPageAction] : []),
                ],
                (_state, { payload }) => Number(payload ?? 0),
              )
              .handleAction(
                [
                  paginationParams.setterPageSizeAction,
                  filterParams.setterAction,
                  filterParams.resetAction,
                ],
                () => 0,
              ),
            size: createReducer<number>(DEFAULT_PAGINATION_PAGE_SIZE).handleAction(
              [
                paginationParams.setterPageSizeAction,
                ...(paginationParams.initPageSizeAction
                  ? [paginationParams.initPageSizeAction]
                  : []),
              ],
              (_state, { payload }) => Number(payload ?? DEFAULT_PAGINATION_PAGE_SIZE),
            ),
          }
        : {
            page: pageReducerCreator<Item>({
              listAsyncAction: itemParams.getterAsyncAction,
              resetAction: itemParams.resetAction,
            }),
            size: createReducer<number>(DEFAULT_PAGE_SIZE),
          }),
    }),
    sortDescriptor: sortDescriptorReducerCreator<SortDescriptor>(sortParams),
    selected: selectedReducerCreator<ItemId>({
      ...selectedParams,
      resetAction: itemParams.resetAction,
    }),
    filters: filterReducerCreator<Filter>(filterParams),
  }) as Reducer<
    CombinedState<{
      items: Item[];
      pagination: ListPagination;
      pageDescriptor: {
        page: number;
        size: number;
      };
      sortDescriptor: SortDescriptor;
      selected: ItemId[];
      filters: Filter;
    }>,
    AnyAction
  >;

export default {
  composeEnhancers,
};
