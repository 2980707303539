// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js!roboto-fontface/css/roboto/roboto-fontface.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js!@tt2/components/build/icons.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "#app {\n  height: 100%;\n  width: 100%;\n  display: flex;\n}\n\nhtml, body {\n  position: fixed;\n  width: 100%;\n  margin: 0;\n  color: var(--color-gray1);\n  font-size: var(--font-size3);\n  height: 100%;\n  line-height: 1.4;\n}\n\n[data-test-id=\"form-item--error-message\"]::first-letter {\n  text-transform: capitalize;\n}\n\nbody, input, textarea, button {\n  font-family: var(--font-family);\n}\n\ninput, textarea {\n  line-height: 1.5;\n}\n\nbody, input {\n  overflow: hidden;\n}\n*:focus {\n  outline: 0;\n}\n\n::-webkit-scrollbar-track {\n  -webkit-box-shadow: inset 0 0 8px var(--color-gray3);\n  background-color: var(--color-gray6);\n}\n\n::-webkit-scrollbar {\n  width: 8px;\n  background-color: var(--color-gray6);\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: var(--color-gray2-3);\n  border-radius: 3px;\n}\na#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {\nborder-radius: 5px !important;\n    border: 1px solid #ccc !important;\n    padding: 8px 10px !important;\n    background-color: #fff !important;\n    color: #999999 !important;}\n\na#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {\n    border-radius: 5px !important;\n    border: 1px solid #32CD32 !important;\n    padding: 8px 10px !important;\n    background-color: #32CD32 !important;\n    color: #fff !important;}\n\na#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover {\n    background-color: #006400 !important;\nborder: 1px solid #006400 !important;}\t", ""]);
// Exports
module.exports = exports;
