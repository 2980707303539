import { from, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, tap } from 'rxjs/operators';
import { isActionOf, RootEpic } from 'typesafe-actions';
import constants, { DEFAULT_PAGE_SIZE } from 'src/constants';
import { SortDirection } from 'src/types/common';
import { Client } from 'store/Client/Client.types';
import { setDialogState } from 'store/User/User.actions';
import { User } from 'store/User/User.types';
import {
  currentClientSelector,
  userRoleSelector,
} from 'store/Authorization/Authorization.selectors';
import { Authorization } from 'store/Authorization/Authorization.types';
import {
  endOfListReached,
  findClientAsync,
  resetFind,
  setFindClientName,
} from './AssignClient.actions';
import { filterSelector } from './AssignClient.selectors';

export const toFindClientReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findClientAsync.request)),
    mergeMap(() =>
      from(
        api.client.findClient({
          sortDescriptor: {
            direction: SortDirection.ASC,
            identifier: Client.SortIdentifier.NAME,
          },
          pageDescriptor: {
            page: store$.value.user.assignClient.page,
            size: DEFAULT_PAGE_SIZE,
          },
          ...filterSelector(store$.value),
        }),
      ).pipe(
        map((response) => {
          if (userRoleSelector(store$.value) === Authorization.ROLE.OPERATOR) {
            const { items: clientList, continuationToken } = response;
            const myClientId = currentClientSelector(store$.value)?.id;
            const withoutOwnClient = clientList.filter((client) => client.id !== myClientId);
            return findClientAsync.success({ items: withoutOwnClient, continuationToken });
          }
          return findClientAsync.success(response);
        }),
        catchError((err) => of(findClientAsync.failure(String(err)))),
      ),
    ),
  );

export const toGetMoreClientsReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfListReached)),
    filter(() => state$.value.user.assignClient.page != null),
    map(() => findClientAsync.request()),
  );

export const toSetFindClientName: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setFindClientName)),
    tap(() => {
      api.client.clientService.subgroup('findClient').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findClientAsync.request()),
  );

export const toFindInitClients: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(setDialogState)),
    filter(
      ({ payload }) =>
        payload[User.Dialogs.CREATE_USER]?.isOpen || payload[User.Dialogs.EDIT_USER]?.isOpen,
    ),
    map(() => findClientAsync.request()),
  );

export const toResetClientFind: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(setDialogState)),
    filter(
      ({ payload }) =>
        !payload[User.Dialogs.CREATE_USER]?.isOpen && !payload[User.Dialogs.EDIT_USER]?.isOpen,
    ),
    map(() => resetFind()),
  );

export const toResetList: RootEpic = (action$) =>
  action$.pipe(filter(isActionOf([setFindClientName])), map(resetFind));
