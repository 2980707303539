import '@tt2/components/build/variables.css';
import './assets/styles/_variables.global.css';
import './assets/styles/app.global.css';

import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from 'components/Layout';
import Spinner from '@tt2/components/build/Atoms/Spinner';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import LoginPage from 'components/LoginPage';
import { appDestroy, appInit } from 'store/App/App.actions';
import { RootState } from 'typesafe-actions';
import { preparedSelector } from 'store/Authorization/Authorization.selectors';
import { AnimatePresence } from 'framer-motion';
import { setDateLocale } from 'src/utils/date';
import { LayoutMobile } from 'components/LayoutMobile/LayoutMobile';
import * as Sentry from '@sentry/react';
import routes from './routes';
import { history } from './store';
import { routePaths } from './routePaths';
import { isMediaWidth } from './utils/isMediaWidth';
import { MediaQuerySize } from './constants';

type StateProps = {
  prepared: boolean;
};
type OwnProps = unknown;
type ActionProps = {
  onInit: () => void;
  onDestroy: () => void;
};

export type AppProps = StateProps & OwnProps & ActionProps;

export const App: React.FC<AppProps> = ({ onInit, onDestroy, prepared }) => {
  React.useEffect(() => {
    onInit();
    setDateLocale();
    return () => {
      onDestroy();
    };
  }, [onDestroy, onInit]);
  const isDesktop = isMediaWidth(MediaQuerySize.LG, 'min');
  const LayoutComponent = React.useMemo(() => (isDesktop ? Layout : LayoutMobile), [isDesktop]);
  const app = React.useMemo(
    () => (
      <ConnectedRouter history={history}>
        {!prepared && <Spinner size={30} isCentered />}
        {prepared && (
          <AnimatePresence>
            <Switch>
              <Route path={routePaths.login} exact>
                <LoginPage />
              </Route>
              <Route>
                <LayoutComponent>
                  <Switch>
                    <Route path={routePaths.root} exact>
                      <Redirect to={routes[0].path as string} />
                    </Route>
                    {routes
                      .filter(({ isSubpage }) => !isSubpage)
                      .map(({ name, component: Component, ...routeProps }) => {
                        return (
                          <Route
                            {...routeProps}
                            key={name}
                            render={(props) => (
                              <React.Suspense fallback={<Spinner isCentered size={30} />}>
                                {Component && <Component {...props} />}
                              </React.Suspense>
                            )}
                          />
                        );
                      })}
                  </Switch>
                </LayoutComponent>
              </Route>
            </Switch>
          </AnimatePresence>
        )}
      </ConnectedRouter>
    ),
    [LayoutComponent, prepared],
  );
  return app;
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  prepared: preparedSelector(state),
});

const mapDispatchToProps: MapDispatchToProps<ActionProps, OwnProps> = {
  onInit: appInit,
  onDestroy: appDestroy,
};

export default Sentry.withProfiler(connect(mapStateToProps, mapDispatchToProps)(App));
