import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import isEqual from 'fast-deep-equal';
import { DEFAULT_HISTORY_FILTERS } from './CommandHistory.reducer';
import { CommandHistory } from './CommandHistory.types';

export const historyFilterSelector = (state: RootState) => state.commandHistory.list.filters;

export const commandsSelector = (state: RootState) => state.commandHistory.list.items;

export const basicParametersCommandIdSelector = (state: RootState) =>
  state.commandHistory.dialogStates.BASIC_PARAMETER.id;

export const basicParametersDialogIsOpenSelector = (state: RootState) =>
  state.commandHistory.dialogStates.BASIC_PARAMETER.isOpen;

export const noFileDialogIsOpenSelector = (state: RootState) =>
  state.commandHistory.dialogStates.NO_FILE_WARNING;

export const basicParamsSelector = (state: RootState) => state.commandHistory.commandBasicParams;

export const sortDescriptorSelector = (state: RootState) =>
  state.commandHistory.list.sortDescriptor;

export const commandFilterSelector = <T extends keyof CommandHistory.FindFilters>(name: T) =>
  createSelector(historyFilterSelector, (filters): CommandHistory.FindFilters[T] => filters[name]);

export const historyFiltersCountSelector = createSelector(
  historyFilterSelector,
  (filterState: CommandHistory.FindFilters): number =>
    Object.keys(DEFAULT_HISTORY_FILTERS)
      // todo - refactor the shape of filters to fix this issue permanently
      .filter((key) => !['createdTo'].includes(key)) // despite the fact that date range filter has two values, it must be counted as one filter.
      .filter(
        (key: keyof CommandHistory.FindFilters) =>
          !isEqual(filterState[key], DEFAULT_HISTORY_FILTERS[key]),
      ).length,
);

export const basicParametersCommandSelector = createSelector(
  basicParametersCommandIdSelector,
  commandsSelector,
  (id, commands) => commands.find((command) => command.id === id),
);

export const commandLoadingSelector = (name: CommandHistory.AsyncActionKeys) => (
  state: RootState,
) => state.commandHistory.loading[name];

export const commandErrorSelector = (name: CommandHistory.AsyncActionKeys) => (state: RootState) =>
  state.commandHistory.error[name];
