import { isActionOf, RootEpic } from 'typesafe-actions';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';

import {
  clearListFilters,
  clientListDestroy,
  clientListInit,
  createClientAsync,
  deleteClientAsync,
  deleteMultipleClients,
  editClientAsync,
  endOfListReached,
  findClientAsync,
  initListFilters,
  initListOrdering,
  queryParseFinish,
  resetList,
  setClientListOrder,
  setDialogState,
  setListFilters,
  validateClientListAsync,
} from 'store/Client/Client.actions';
import constants from 'src/constants';
import { calculateURLQuery } from 'src/utils/calculateURLQuery';
import { SortDescriptor } from 'types/common';
import { DEFAULT_CLIENT_FILTERS, DEFAULT_CLIENT_SORTING } from 'store/Client/Client.reducer';
import { replace } from 'connected-react-router';
import { routePaths } from 'src/routePaths';
import {
  clientChangedFiltersSelector,
  editClientIdSelector,
  selectedClientIdsSelector,
} from './Client.selectors';
import { Client } from './Client.types';
import { parse } from '../../utils/queryParams';

export const toFindClientReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findClientAsync.request)),
    mergeMap(() =>
      from(
        api.client.findClient({
          // TODO: create selectors for sort and page descriptor
          sortDescriptor: store$.value.client.list.sortDescriptor,
          pageDescriptor: store$.value.client.list.pageDescriptor,
          ...clientChangedFiltersSelector(store$.value),
        }),
      ).pipe(
        map(findClientAsync.success),
        catchError((err) => of(findClientAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description triggers initialization of query params
 */
export const onInit: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(clientListInit)),
    map(() => parse(state$.value.router.location.search)),
    mergeMap((queryParams) => {
      const { direction, identifier, ...rest } = queryParams;
      const {
        direction: directionState,
        identifier: identifierState,
      } = state$.value.client.list.sortDescriptor;

      const orderingParams = {
        direction: direction || directionState,
        identifier: identifier || identifierState,
      } as Client.ListSortDescriptor;

      return of(
        initListFilters({ ...state$.value.client.list.filters, ...rest }),
        initListOrdering(orderingParams),
        queryParseFinish(),
      );
    }),
  );

/**
 * @description Makes initial request to get list of clients
 */

export const onQueryParamsInit: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(queryParseFinish)),
    map(() => findClientAsync.request()),
  );

export const toUpdateURL: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf([setListFilters, setClientListOrder, queryParseFinish, clearListFilters])),
    switchMap(() =>
      calculateURLQuery<Client.FindFilters, SortDescriptor<Client.SortIdentifier>>(
        store$.pipe(pluck('client', 'list', 'filters'), take(1)),
        store$.pipe(pluck('client', 'list', 'sortDescriptor'), take(1)),
        DEFAULT_CLIENT_FILTERS,
        DEFAULT_CLIENT_SORTING,
      ).pipe(distinctUntilChanged()),
    ),
    map((res) => replace(`${routePaths.client.root}${res}`)),
  );

/**
 * @description Cancel all requests on module unmount
 */
export const onDestroy: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(clientListDestroy)),
    tap(() => {
      api.client.clientService.cancelAll();
    }),
    mergeMap(() => EMPTY),
  );

export const toGetMoreClientsReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfListReached)),
    filter(() => state$.value.client.list.pageDescriptor.page != null),
    map(() => findClientAsync.request()),
  );

export const toCreateClientReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(createClientAsync.request)),
    mergeMap(({ payload }) =>
      from(api.client.createClient(payload)).pipe(
        map(createClientAsync.success),
        catchError(({ message }: Error) => of(createClientAsync.failure(message))),
      ),
    ),
  );

/**
 * @description On success creation closes creation dialog and fetch client list
 */
export const toCreateClientRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(createClientAsync.success)),
    mergeMap(() =>
      of(
        setDialogState({ [Client.Dialogs.CREATE_CLIENT]: { isOpen: false } }),
        resetList(),
        findClientAsync.request(),
      ),
    ),
  );

export const toResetList: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([clearListFilters, setListFilters, clientListDestroy])),
    map(resetList),
  );

export const toSetClientListOrder: RootEpic = (action$, _store, { api }) =>
  action$.pipe(
    filter(isActionOf([setClientListOrder, clearListFilters])),
    tap(() => {
      api.client.clientService.subgroup('findClient').cancelAll();
    }),
    mergeMap(() => of(resetList(), findClientAsync.request())),
  );

export const toSetFilter: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setListFilters)),
    tap(() => {
      api.client.clientService.subgroup('findClient').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findClientAsync.request()),
  );

export const toDeleteMultipleClients: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(deleteMultipleClients)),
    map(() =>
      setDialogState({
        [Client.Dialogs.DELETE_CONFIRMATION]: {
          isOpen: true,
          clientIdList: selectedClientIdsSelector(store$.value),
        },
      }),
    ),
  );

export const toDeleteClientReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteClientAsync.request)),
    mergeMap(({ payload }) =>
      from(api.client.deleteClients(payload)).pipe(
        map(deleteClientAsync.success),
        catchError(({ message }) => of(deleteClientAsync.failure(message))),
      ),
    ),
  );

export const toDeleteClientRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(deleteClientAsync.success)),
    mergeMap(() =>
      of(
        setDialogState({
          [Client.Dialogs.DELETE_CONFIRMATION]: {
            isOpen: false,
            clientIdList: [],
          },
        }),
        resetList(),
        findClientAsync.request(),
      ),
    ),
  );

export const toEditClientReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(editClientAsync.request)),
    mergeMap(({ payload }) =>
      from(api.client.editClient(editClientIdSelector(store$.value), payload)).pipe(
        map(editClientAsync.success),
        catchError(({ message }: Error) => of(editClientAsync.failure(message))),
      ),
    ),
  );

export const toEditClientRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(editClientAsync.success)),
    mergeMap(() =>
      of(
        setDialogState({
          [Client.Dialogs.EDIT_CLIENT]: {
            isOpen: false,
            clientId: null,
          },
        }),
        resetList(),
        findClientAsync.request(),
      ),
    ),
  );

/**
 * @description Epic that handles API request to validate clients if they can be deleted
 */
export const toValidateFileListeReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(validateClientListAsync.request)),
    mergeMap(({ payload }) =>
      from(api.client.validateClients(payload)).pipe(
        map(validateClientListAsync.success),
        catchError((err) => of(validateClientListAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description Emits validateClientListAsync.request action when Delete confirmation dialog opens
 */
export const toDeleteConfirmationDialog: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(setDialogState)),
    filter(({ payload }) => payload.DELETE_CONFIRMATION?.isOpen === true),
    map(({ payload }) => validateClientListAsync.request(payload.DELETE_CONFIRMATION.clientIdList)),
  );
