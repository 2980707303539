// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n  --header-height: 60px;\n}\n\n._1dlEmAx4R0rt_kUt3g8GiT {\n  display: flex;\n  flex: 1;\n}\n\n._1uDuEbtxxJqR5NhgabwDc_ {\n  height: var(--header-height);\n  background: var(--color-gray6);\n  position: fixed;\n  top: 0;\n  flex: 1;\n  z-index: 2;\n  display: flex;\n  width: 100%;\n}\n._1KjvuyOJDCgyrdDOmENV-4 {\n  width: var(--header-height);\n  height: var(--header-height);\n  background: var(--color-blue1);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n._1KjvuyOJDCgyrdDOmENV-4 > a {\n    display: flex;\n  }\n._1BJi2E8sEMZHd0fPwcSTvo {\n  flex: 1;\n  position: relative;\n  margin-top: var(--header-height);\n  padding: var(--length6);\n  overflow: auto;\n}\n._3Oumo4s-2qzFFyW9xPZxof {\n  flex: 1;\n  display: flex;\n  padding-left: var(--length3);\n}\n\n.f00CoGq0C-a4T6R89TQAn {\n  position: fixed;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: 260px;\n  z-index: 2;\n  background: var(--color-gray1);\n  display: flex;\n  flex-direction: column;\n}\n\n.f00CoGq0C-a4T6R89TQAn ._1Fm66g_edLAtWLswJs_znJ {\n    display: flex;\n    align-items: center;\n    padding-left: var(--length3);\n    height: var(--header-height);\n    background: var(--color-blue1);\n    color: var(--color-gray6);\n    font-size: var(--font-size5);\n  }\n._1-BDe-jy-3K4kucMTrFhJ0 {\n  background: var(--color-gray1);\n  opacity: 0.5;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n}\n\n.Eo5YXmGcyu4dkKfif9cXZ {\n  background: transparent;\n  color: var(--color-gray6);\n  border-bottom: 0 !important;\n  font-size: var(--font-size2);\n}\n\n.rHXhiErB5sA7sLpkkqHWR {\n  flex: 1;\n}\n\n.W98k8SZg73fUW3qt2GAfY {\n  margin-bottom: var(--length3);\n}\n", ""]);
// Exports
exports.locals = {
	"layout": "_1dlEmAx4R0rt_kUt3g8GiT",
	"header": "_1uDuEbtxxJqR5NhgabwDc_",
	"logo": "_1KjvuyOJDCgyrdDOmENV-4",
	"main": "_1BJi2E8sEMZHd0fPwcSTvo",
	"breadcrumb": "_3Oumo4s-2qzFFyW9xPZxof",
	"aside": "f00CoGq0C-a4T6R89TQAn",
	"navigationHeader": "_1Fm66g_edLAtWLswJs_znJ",
	"backdrop": "_1-BDe-jy-3K4kucMTrFhJ0",
	"listOption": "Eo5YXmGcyu4dkKfif9cXZ",
	"navMenu": "rHXhiErB5sA7sLpkkqHWR",
	"footer": "W98k8SZg73fUW3qt2GAfY"
};
module.exports = exports;
