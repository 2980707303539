import { Client } from 'store/Client/Client.types';
import { getPostRequests } from '../defaultHeaders';
import { http2 } from '../http2';
import { handleResponse } from '../utils';

type ClientServiceRequests = 'findClient' | 'createClient' | 'editClient';

export const clientService = http2.createGroup<ClientServiceRequests>('client');

export const findClient = async (
  params: Client.FindClientRequest,
  version = 1,
): Promise<Client.FindClientResponse> => {
  const url = `/api/clients/find?version=${version}`;

  return clientService
    .subgroup('findClient')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: getPostRequests(),
    })
    .request.then(handleResponse('Error finding clients'));
};

export const createClient = async (
  params: Client.CreateClientRequest,
  version = 1,
): Promise<Client.ExternalClient> => {
  const url = `/api/clients?version=${version}`;

  return clientService
    .subgroup('createClient')
    .fetch(url, { method: 'POST', body: JSON.stringify(params), headers: getPostRequests() })
    .request.then(
      handleResponse('Error creating client', {
        400: 'Such Client name already exists',
      }),
    );
};

export const deleteClients = async (
  idList: Client.ExternalClient['id'][],
  version = 1,
): Promise<Client.ExternalClient['id'][]> => {
  const url = `/api/clients?version=${version}`;

  return clientService
    .fetch(url, { method: 'DELETE', body: JSON.stringify(idList), headers: getPostRequests() })
    .request.then(handleResponse<Client.DeleteClientsResponse>('Error deleting clients'))
    .then(({ deletedIds }) => deletedIds);
};

export const editClient = async (
  clientId: Client.ExternalClient['id'],
  params: Client.CreateClientRequest,
  version = 1,
): Promise<Client.ExternalClient> => {
  const url = `/api/clients/${clientId}?version=${version}`;

  return clientService
    .subgroup('editClient')
    .fetch(url, { method: 'PUT', body: JSON.stringify(params), headers: getPostRequests() })
    .request.then(
      handleResponse('Error editing client', {
        400: 'Such Client name already exists',
      }),
    );
};

export const validateClients = async (
  idList: Client.ExternalClient['id'][],
  version = 1,
): Promise<Client.ValidateResponse> => {
  const url = `/api/clients/validate/delete?version=${version}`;

  return clientService
    .fetch(url, { method: 'POST', body: JSON.stringify(idList), headers: getPostRequests() })
    .request.then(handleResponse('Error validating clients'));
};

export default {
  clientService,
  findClient,
  createClient,
  deleteClients,
  editClient,
  validateClients,
};
