import { TabKeys } from 'components/MultipleImei/utils';
import { Client } from 'store/Client/Client.types';
import { Websocket } from 'store/Websocket/Websocket.types';
import { createAsyncAction, createAction } from 'typesafe-actions';
import { IoT } from './Device.types';

export const getDeviceAsync = createAsyncAction(
  'DEVICE | GET_DEVICE_REQ',
  'DEVICE | GET_DEVICE_RES',
  'DEVICE | GET_DEVICE_ERR',
)<number, IoT.Device, string>();

export const findDeviceAsync = createAsyncAction(
  'DEVICE | FIND_DEVICE_REQ',
  'DEVICE | FIND_DEVICE_RES',
  'DEVICE | FIND_DEVICE_ERR',
)<void, IoT.FindDeviceResponse, string>();

export const registerDeviceAsync = createAsyncAction(
  'DEVICE | REGISTER_DEVICE_REQ',
  'DEVICE | REGISTER_DEVICE_RES',
  'DEVICE | REGISTER_DEVICE_ERR',
)<IoT.RegisterDeviceRequest, IoT.RegisterDeviceResponse, string>();

export const editDeviceAsync = createAsyncAction(
  'DEVICE | EDIT_DEVICE_REQ',
  'DEVICE | EDIT_DEVICE_RES',
  'DEVICE | EDIT_DEVICE_ERR',
)<IoT.EditDeviceRequest, IoT.Device, string>();

export const assignDeviceAsync = createAsyncAction(
  'DEVICE | ASSIGN_DEVICE_REQ',
  'DEVICE | ASSIGN_DEVICE_RES',
  'DEVICE | ASSIGN_DEVICE_ERR',
)<Client.ExternalClient['id'], void, string>();

export const deleteDeviceAsync = createAsyncAction(
  'DEVICE | DELETE_DEVICE_REQ',
  'DEVICE | DELETE_DEVICE_RES',
  'DEVICE | DELETE_DEVICE_ERR',
)<IoT.Device['id'][], IoT.Device['id'][], string>();

export const checkImeisAsync = createAsyncAction(
  'DEVICE | CHECK_IMEIS_REQ',
  'DEVICE | CHECK_IMEIS_RES',
  'DEVICE | CHECK_IMEIS_ERR',
)<IoT.Device['imei'][], IoT.CheckImeisResponse, string>();

export const validateDisabledAsync = createAsyncAction(
  'DEVICE | VALIDATE_DISABLED_REQ',
  'DEVICE | VALIDATE_DISABLED_RES',
  'DEVICE | VALIDATE_DISABLED_ERR',
)<void, IoT.ValidateDisabledResponse, string>();

export const toggleDeviceSelect = createAction('DEVICE | TOGGLE_DEVICE_SELLECT')<
  IoT.Device['id']
>();
export const setSelectedDevices = createAction('DEVICE | LIST.SET_SELECTED_DEVICES')<
  IoT.Device['id'][]
>();
export const setDialogState = createAction('DEVICE | SET_DIALOG_STATE')<
  Partial<IoT.DialogStates>
>();

export const deviceListInit = createAction('DEVICE | DEVICE_LIST_INIT')();

export const deviceListDestroy = createAction('DEVICE | DEVICE_LIST_DESTROY')();

export const endOfListReached = createAction('DEVICE | LIST.END_OF_LIST_REACHED')();

export const initPage = createAction('DEVICE | LIST.INIT_PAGE')<string | number>();
export const setPage = createAction('DEVICE | LIST.SET_PAGE')<number>();

export const initPageSize = createAction('DEVICE | LIST.INIT_PAGE_SIZE')<string | number>();
export const setPageSize = createAction('DEVICE | LIST.SET_PAGE_SIZE')<number>();

export const setDeviceListOrder = createAction(
  'DEVICE | LIST.SET_DEVICE_ORDER',
)<IoT.DeviceListSortDescriptor>();
export const initDeviceListOrder = createAction(
  'DEVICE | LIST.INIT_DEVICE_ORDER',
)<IoT.DeviceListSortDescriptor>();

export const initListFilters = createAction('DEVICE | LIST.INIT_FILTERS')<
  Partial<IoT.FindFilters>
>();
export const setListFilters = createAction('DEVICE | LIST.SET_FILTERS')<Partial<IoT.FindFilters>>();

export const clearListFilters = createAction('DEVICE | LIST.CLEAR_FILTERS')();

export const deleteMultipleDevices = createAction('DEVICE | DELETE_MULTIPLE_DEVICES')();

export const toggleConfigureSidebar = createAction('DEVICE | TOGGLE_CONFIGURE_SIDEBAR')();
export const toggleAssignSidebar = createAction('DEVICE | TOGGLE_ASSIGN_SIDEBAR')();

export const resetList = createAction('DEVICE | RESET_LIST')();

export const updateDeviceFromWS = createAction(
  'DEVICE | UPDATE_DEVICE_FROM_WS',
)<Websocket.DeviceEvent>();

export const removeImeiFromChecked = createAction('DEVICE | REMOVE_IMEI_FROM_CHECKED')<{
  from: TabKeys.EXIST | TabKeys.NON_EXIST;
  imei: IoT.Device['imei'];
}>();

export const resetCheckedImeis = createAction('DEVICE | RESET_CHECKED_IMEIS')();

export const asyncActions = {
  getDeviceAsync,
  findDeviceAsync,
  registerDeviceAsync,
  editDeviceAsync,
  deleteDeviceAsync,
  assignDeviceAsync,
  checkImeisAsync,
  validateDisabledAsync,
};
