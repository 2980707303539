import { Encodable, Payload } from 'rsocket-types';
import { User } from 'store/User/User.types';
import { Client } from 'store/Client/Client.types';
import { CommandHistory } from 'store/CommandHistory/CommandHistory.types';
import { IoT } from 'store/Device/Device.types';
import { File } from 'store/File/File.types';

export namespace Websocket {
  export enum Action {
    UPDATED = 'UPDATED',
    CREATED = 'CREATED',
    DELETED = 'DELETED',
  }

  export enum EventDataType {
    COMMAND = 'ExternalDeviceCommand',
    DEVICE = 'ExternalDevice',
    FILE = 'ExternalDeviceFileMetadata',
    AUTH = 'ExternalAuthEvent',
  }

  export type OutputData = ConnectionRequest;
  export type InputData = Events;

  export type CommunicationData = OutputData | InputData;

  export type Message = Payload<InputData, Encodable>;

  interface BaseEvent {
    action: Action;
    resourceId: number;
  }

  export type Events = FileEvent | DeviceEvent | CommandEvent | AuthEvent;

  export interface AuthEvent extends BaseEvent {
    data: {
      clientId: Client.ExternalClient['id'];
      userId: User.Base['id'];
    };
    type: EventDataType.AUTH;
  }

  export interface FileEvent extends BaseEvent {
    data: File.ExternalDeviceFileMetadata;
    type: EventDataType.FILE;
  }

  export interface DeviceEvent extends BaseEvent {
    type: EventDataType.DEVICE;
    data: IoT.Device;
  }

  export interface CommandEvent extends BaseEvent {
    type: EventDataType.COMMAND;
    data: CommandHistory.ExternalIoTDeviceCommand;
  }

  export interface ConnectionRequest {
    clientId: number;
  }
}
