const DEVICE_PATH = '/device';
const FILE_PATH = '/file';
const CLIENT_PATH = '/client';
const USER_PATH = '/user';

export const routePaths = {
  root: '/',
  login: '/login',
  device: {
    root: DEVICE_PATH,
    configure: {
      root: `${DEVICE_PATH}/configure/:tab`,
      fwCfg: `${DEVICE_PATH}/configure/fw-cfg`,
      basicParameters: `${DEVICE_PATH}/configure/basic-parameters`,
      obtain: `${DEVICE_PATH}/configuration/obtain`,
    },
    sendCommand: {
      root: `${DEVICE_PATH}/send-command/:tab`,
      sms: `${DEVICE_PATH}/send-command/sms`,
      dataLink: `${DEVICE_PATH}/send-command/data-link`,
    },
    history: `${DEVICE_PATH}/history/:id`,
    assign: `${DEVICE_PATH}/assign`,
    details: {
      root: `${DEVICE_PATH}/details/:id`,
      sim: `${DEVICE_PATH}/details/:id/sim`,
      basicParameters: `${DEVICE_PATH}/details/:id/basic-parameters`,
      updateHistory: `${DEVICE_PATH}/details/:id/update-history`,
      smsHistory: `${DEVICE_PATH}/details/:id/sms-history`,
      files: `${DEVICE_PATH}/details/:id/files`,
      operations: `${DEVICE_PATH}/details/:id/operations`,
    },
  },
  settings: '/settings',
  file: {
    root: FILE_PATH,
  },
  client: {
    root: CLIENT_PATH,
  },
  user: {
    root: USER_PATH,
  },
};
