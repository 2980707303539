import { TabKeys } from 'components/MultipleImei/utils';
import { combineReducers } from 'redux';
import { SortDirection } from 'src/types/common';
import { updateCommandFromWS } from 'store/CommandHistory/CommandHistory.actions';
import {
  dialogReducerCreator,
  errorReducerCreator,
  listReducerCreator,
  loadingReducerCreator,
} from 'store/utils';
import { createReducer } from 'typesafe-actions';
import {
  asyncActions,
  checkImeisAsync,
  clearListFilters,
  deleteDeviceAsync,
  findDeviceAsync,
  getDeviceAsync,
  initDeviceListOrder,
  initListFilters,
  initPage,
  initPageSize,
  removeImeiFromChecked,
  resetCheckedImeis,
  resetList,
  setDeviceListOrder,
  setDialogState,
  setListFilters,
  setPage,
  setPageSize,
  setSelectedDevices,
  toggleDeviceSelect,
  updateDeviceFromWS,
} from './Device.actions';
import { IoT } from './Device.types';
import { updateDeviceCommand, updateDeviceByEvent, updateImeiArray } from './Device.utils';

export const DEFAULT_DEVICE_FILTERS: IoT.FindFilters = {
  search: '',
  imeis: [],
  imei: '',
  name: '',
  cfgVersion: '',
  deviceTypes: [],
  commandStatuses: [],
  commandTypes: [],
  fwVersion: '',
  clientName: '',
};

export const list = listReducerCreator<
  IoT.Device,
  IoT.DeviceListSortDescriptor,
  IoT.Device['id'],
  IoT.FindFilters
>(
  {
    getterAsyncAction: findDeviceAsync,
    resetAction: resetList,
    extended: [
      [[updateCommandFromWS], (state, { payload }) => state.map(updateDeviceCommand(payload))],
      [
        [getDeviceAsync.success],
        (state, { payload }) => state.map((item) => (item.id === payload.id ? payload : item)),
      ],
      [[updateDeviceFromWS], (state, { payload }) => state.map(updateDeviceByEvent(payload))],
    ],
  },
  {
    initAction: initDeviceListOrder,
    setterAction: setDeviceListOrder,
    defaultState: {
      direction: SortDirection.ASC,
      identifier: IoT.SortIdentifier.ID,
    },
  },
  {
    setterAction: setSelectedDevices,
    toggleAction: toggleDeviceSelect,
    deleteAction: deleteDeviceAsync,
  },
  {
    initAction: initListFilters,
    setterAction: setListFilters,
    resetAction: clearListFilters,
    defaultState: DEFAULT_DEVICE_FILTERS,
  },
  {
    initPageAction: initPage,
    setterPageAction: setPage,
    initPageSizeAction: initPageSize,
    setterPageSizeAction: setPageSize,
  },
);

export const dialogStates = dialogReducerCreator<IoT.DialogStates>(setDialogState, {
  [IoT.Dialogs.REGISTER_DEVICE]: false,
  [IoT.Dialogs.EDIT_DEVICE]: {
    isOpen: false,
    deviceId: null,
  },
  [IoT.Dialogs.DELETE_CONFIRMATION]: {
    isOpen: false,
    deviceIdList: [],
  },
});

const DEFAULT_CHECK_IMEIS_STATE: IoT.CheckImeisResponse = {
  existImeis: [],
  nonExistImeis: [],
};
export const checkImeis = createReducer<IoT.CheckImeisResponse>(DEFAULT_CHECK_IMEIS_STATE)
  .handleAction(checkImeisAsync.success, (_state, { payload }) => payload)
  .handleAction(removeImeiFromChecked, (state, { payload: { from, imei } }) => {
    return {
      existImeis: updateImeiArray(from === TabKeys.EXIST, state.existImeis, imei),
      nonExistImeis: updateImeiArray(from === TabKeys.NON_EXIST, state.nonExistImeis, imei),
    };
  })
  .handleAction(resetCheckedImeis, () => DEFAULT_CHECK_IMEIS_STATE);

/**
 * Loading reducer ↓
 */

export const loading = loadingReducerCreator(asyncActions, {
  findDeviceAsync: { set: [resetList] },
  editDeviceAsync: { reset: [setDialogState] },
});

/**
 * Error reducer ↓
 */

export const error = errorReducerCreator(asyncActions, {
  editDeviceAsync: { reset: [setDialogState] },
});

export const details = createReducer<IoT.Device>(null).handleAction(
  getDeviceAsync.success,
  (_state, { payload }) => payload,
);

export default combineReducers({
  list,
  dialogStates,
  loading,
  error,
  details,
  checkImeis,
});
