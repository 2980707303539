import React from 'react';
import { matchPath, useLocation } from 'react-router';
import routes from 'src/routes';
import Icon from '@tt2/components/build/Atoms/Icon';
import styles from './Breadcrumb.css';

export const Breadcrumb: React.FC = () => {
  const { pathname } = useLocation();

  const matchedRoutes = React.useMemo(
    () =>
      routes
        .filter(({ path, exact, strict }) =>
          matchPath(pathname, {
            path,
            exact,
            strict,
          }),
        )
        .map(({ name }) => name),
    [pathname],
  );

  return (
    <div className={styles.breadcrumb}>
      {matchedRoutes.map((name, index, arr) => (
        <div className={styles.section} key={name}>
          <span>{name}</span>
          {index + 1 !== arr.length && (
            <Icon className={styles.divider} name="keyboard_arrow_right" size={24} />
          )}
        </div>
      ))}
    </div>
  );
};
