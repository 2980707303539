import * as React from 'react';
import ListItem from '@tt2/components/build/Atoms/ListItem';
import { logoutAsync } from 'store/Authorization/Authorization.actions';
import transl from 'src/utils/translate';
import { routePaths } from 'src/routePaths';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import styles from './UserDropdownOptions.css';

interface UserDropdownOptionsProps {
  className?: string;
  navLinkOnClick?: () => void;
}

export const UserDropdownOptions: React.FC<UserDropdownOptionsProps> = ({
  className,
  navLinkOnClick,
}) => {
  const dispatch = useDispatch();
  const onLogout = React.useCallback(() => dispatch(logoutAsync.request()), [dispatch]);
  return (
    <>
      <ListItem className={cn(className, styles.listItem)} data-test-id="open-settings">
        <NavLink to={routePaths.settings} onClick={navLinkOnClick}>
          {transl('AccountSettings.Title')}
        </NavLink>
      </ListItem>
      <ListItem
        onClick={onLogout}
        className={cn(className, styles.listItem)}
        data-test-id="log-out"
      >
        {transl('Global.Logout')}
      </ListItem>
    </>
  );
};
