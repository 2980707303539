import { IoT } from 'store/Device/Device.types';
import { getPostRequests } from '../defaultHeaders';
import { http2 } from '../http2';
import { handleAcceptableResponse, handleResponse } from '../utils';

type DeviceServiceRequests =
  | 'getDevice'
  | 'findDevice'
  | 'deleteDevice'
  | 'registerDevice'
  | 'editDevice'
  | 'assignDevice'
  | 'checkImeis'
  | 'validateDisabled';

export const deviceService = http2.createGroup<DeviceServiceRequests>('device');

export const getDevice = async (deviceId: number, version = 1): Promise<IoT.Device> => {
  const url = `/api/devices/${deviceId}?version=${version}`;

  return deviceService
    .subgroup('getDevice')
    .fetch(url)
    .request.then(handleResponse('Error getting device'));
};

export const findDevice = async (
  params: IoT.FindDeviceRequest,
  version = 1,
): Promise<IoT.FindDeviceResponse> => {
  const url = `/api/devices/find?version=${version}`;

  return deviceService
    .subgroup('findDevice')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: getPostRequests(),
    })
    .request.then(handleResponse('Error finding devices'));
};

export const editDevice = async (
  deviceId: IoT.Device['id'],
  params: IoT.RegisterDeviceRequest,
  version = 1,
): Promise<IoT.Device> => {
  const url = `/api/devices/${deviceId}?version=${version}`;

  return deviceService
    .subgroup('editDevice')
    .fetch(url, { method: 'PUT', body: JSON.stringify(params), headers: getPostRequests() })
    .request.then(
      handleResponse('Error editing device', {
        409: 'Device IMEI must be unique',
      }),
    );
};

export const registerDevice = async (
  params: IoT.RegisterDeviceRequest,
  version = 1,
): Promise<IoT.RegisterDeviceResponse> => {
  const url = `/api/devices/register?version=${version}`;

  return deviceService
    .subgroup('registerDevice')
    .fetch(url, { method: 'POST', body: JSON.stringify(params), headers: getPostRequests() })
    .request.then(
      handleResponse('Error registering device', {
        409: 'Device IMEI must be unique',
      }),
    );
};

export const deleteDevices = async (
  idList: IoT.Device['id'][],
  version = 1,
): Promise<IoT.Device['id'][]> => {
  const url = `/api/devices?version=${version}`;

  return deviceService
    .fetch(url, { method: 'DELETE', body: JSON.stringify(idList), headers: getPostRequests() })
    .request.then(handleResponse<IoT.DeleteDeviceResponse>('Error deleting devices'))
    .then(({ deletedDevices }) => deletedDevices);
};

export const assignDevices = async (
  params: IoT.AssignDeviceRequest,
  version = 1,
): Promise<void> => {
  const url = `/api/devices/assign?version=${version}`;

  return deviceService
    .subgroup('assignDevice')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: getPostRequests(),
    })
    .request.then(handleAcceptableResponse('Error assigning devices'));
};

export const checkImeis = async (
  imeis: IoT.Device['imei'][],
  version = 1,
): Promise<IoT.CheckImeisResponse> => {
  const url = `/api/devices/imei/check?version=${version}`;

  return deviceService
    .subgroup('checkImeis')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify(imeis),
      headers: getPostRequests(),
    })
    .request.then(handleResponse('Error checking imeis'));
};

export const validateDisabled = async (
  devicesIds: IoT.Device['id'][],
  version = 1,
): Promise<IoT.ValidateDisabledResponse> => {
  const url = `/api/commands/validate?version=${version}`;

  return deviceService
    .subgroup('validateDisabled')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify(devicesIds),
      headers: getPostRequests(),
    })
    .request.then(handleResponse('Error validating devices'));
};

export default {
  deviceService,
  getDevice,
  findDevice,
  registerDevice,
  deleteDevices,
  editDevice,
  assignDevices,
  checkImeis,
  validateDisabled,
};
