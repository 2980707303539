import { from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { isActionOf, isOfType, RootEpic } from 'typesafe-actions';
import { matchPath } from 'react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import constants from 'src/constants';
import { omitBy } from 'src/services/utils';
import { routePaths } from 'src/routePaths';
import {
  findHistoryAsync,
  endOfHistoryListReached,
  setHistoryListOrder,
  resetList,
  setListFilters,
  clearListFilters,
  historyDestroy,
  cancelCommandAsync,
  getBasicParametersAsync,
  setDialogState,
  getHistoryFileAsync,
} from './CommandHistory.actions';
import { CommandHistory } from './CommandHistory.types';
import { DEFAULT_HISTORY_FILTERS } from './CommandHistory.reducer';
import { basicParametersCommandSelector } from './CommandHistory.selectors';

export const toFindHistoryAsync: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findHistoryAsync.request)),
    mergeMap(() =>
      from(
        api.updateDevice.findHistory({
          sortDescriptor: store$.value.commandHistory.list.sortDescriptor,
          pageDescriptor: store$.value.commandHistory.list.pageDescriptor,
          deviceId: Number(
            matchPath<{ id: string }>(store$.value.router.location.pathname, {
              path: [routePaths.device.history, routePaths.device.details.operations],
              exact: true,
              strict: true,
            }).params.id,
          ),
          ...omitBy(store$.value.commandHistory.list.filters, (v) =>
            Object.values(DEFAULT_HISTORY_FILTERS).includes(v),
          ),
        }),
      ).pipe(
        map(findHistoryAsync.success),
        catchError((err) => of(findHistoryAsync.failure(String(err)))),
      ),
    ),
  );

export const toGetMoreHistoryReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfHistoryListReached)),
    filter(() => state$.value.commandHistory.list.pageDescriptor.page != null),
    map(() => state$.value.commandHistory.list.pageDescriptor.page),
    distinctUntilChanged(),
    map(() => findHistoryAsync.request()),
  );

export const toReqInitHistoryFind: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isOfType(LOCATION_CHANGE)),
    map(() =>
      matchPath(store$.value.router.location.pathname, {
        path: [routePaths.device.history],
      }),
    ),
    distinctUntilChanged(),
    filter((match) => !!match),
    map(() => findHistoryAsync.request()),
  );

export const toSetHistoryListOrder: RootEpic = (action$, _store, { api }) =>
  action$.pipe(
    filter(isActionOf([setHistoryListOrder])),
    tap(() => {
      api.updateDevice.updateDeviceService.subgroup('findHistory').cancelAll();
    }),
    mergeMap(() => of(resetList(), findHistoryAsync.request())),
  );

export const toSetFilter: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf([setListFilters, clearListFilters])),
    tap(() => {
      api.updateDevice.updateDeviceService.subgroup('findHistory').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    mergeMap(() => of(resetList(), findHistoryAsync.request())),
  );

export const toOnDestroy: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(historyDestroy)),
    mergeMap(() => of(resetList())),
  );

export const toCancelCommandReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(cancelCommandAsync.request)),
    mergeMap(({ payload }) =>
      from(api.updateDevice.cancelCommand(payload)).pipe(
        map(cancelCommandAsync.success),
        catchError((err) => of(cancelCommandAsync.failure(String(err)))),
      ),
    ),
  );

export const toGetBasicParametersReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(getBasicParametersAsync.request)),
    mergeMap(({ payload }) =>
      from(api.updateDevice.getBasicParameters(payload)).pipe(
        map(getBasicParametersAsync.success),
        catchError((err) => of(getBasicParametersAsync.failure(String(err)))),
      ),
    ),
  );

export const toOpenBasicParameters: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(setDialogState)),
    filter(({ payload }) => payload[CommandHistory.Dialogs.BASIC_PARAMETER]?.isOpen),
    map(() =>
      getBasicParametersAsync.request(
        basicParametersCommandSelector(store$.value).basicParametersId,
      ),
    ),
  );

export const toGetFileReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(getHistoryFileAsync.request)),
    mergeMap(({ payload }) =>
      from(api.file.getFile(payload)).pipe(
        map(getHistoryFileAsync.success),
        catchError((err) => of(getHistoryFileAsync.failure(String(err)))),
      ),
    ),
  );
export const toGetFileErr: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(getHistoryFileAsync.failure)),
    map(() =>
      setDialogState({
        [CommandHistory.Dialogs.NO_FILE_WARNING]: true,
      }),
    ),
  );
