import { Client } from 'store/Client/Client.types';
import { User } from 'store/User/User.types';
import { asyncActions } from './Authorization.actions';

export namespace Authorization {
  export enum ROLE {
    ADMINISTRATOR = 'ADMINISTRATOR',
    OPERATOR = 'OPERATOR',
    OBSERVER = 'OBSERVER',
  }

  export interface LoginParams {
    username: string;
    password: string;
  }

  export type AsyncActionKeys = keyof typeof asyncActions;

  export interface Token {
    access_token: string;
    expires_in: number;
    iat: number;
    jti: string;
    refresh_token: string;
    scope: string;
    token_type: 'bearer';
  }

  export interface Me {
    client: Client.Base;
    user: User.Base;
  }
}
