import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { IoT } from 'store/Device/Device.types';
import { toggleDeviceSelect, validateDisabledAsync } from 'store/Device/Device.actions';
import { errorReducerCreator, loadingReducerCreator } from 'store/utils';
import { fileFinderReducer } from './UpdateDevice.utils';
import {
  findConfigFileAsync,
  findFirmwareFileAsync,
  resetFindConfig,
  resetFindFirmware,
  setFindConfigFileName,
  setFindFirmwareFileName,
  setUpdateDeviceType,
  getBasicParametersAsync,
  uploadBasicParametersAsync,
  asyncActions,
  findBluetoothFileAsync,
  setFindBluetoothFileName,
  resetFindBluetooth,
  findExtFwFileAsync,
  setFindExtFwFileName,
  resetFindExtFw,
} from './UpdateDevice.actions';

export const updateDeviceType = createReducer<IoT.DeviceType>(null).handleAction(
  setUpdateDeviceType,
  (_state, { payload }) => payload,
);

const configFiles = combineReducers(
  fileFinderReducer(findConfigFileAsync, setFindConfigFileName, resetFindConfig),
);

const firmwareFiles = combineReducers(
  fileFinderReducer(findFirmwareFileAsync, setFindFirmwareFileName, resetFindFirmware),
);

const bluetoothFiles = combineReducers(
  fileFinderReducer(findBluetoothFileAsync, setFindBluetoothFileName, resetFindBluetooth),
);

const extFwFiles = combineReducers(
  fileFinderReducer(findExtFwFileAsync, setFindExtFwFileName, resetFindExtFw),
);
/**
 * Loading reducer ↓
 */
export const loading = loadingReducerCreator(asyncActions, {
  findConfigFileAsync: { set: [setFindConfigFileName] },
  findFirmwareFileAsync: { set: [setFindFirmwareFileName] },
  findExtFwFileAsync: { set: [setFindExtFwFileName] },
  findBluetoothFileAsync: { set: [setFindBluetoothFileName] },
});

/**
 * Error reducer ↓
 */

export const error = errorReducerCreator(asyncActions);

export const basicParameters = createReducer<IoT.BasicParameters>(null)
  .handleAction(getBasicParametersAsync.success, (_state, { payload }) => payload)
  .handleAction([toggleDeviceSelect, uploadBasicParametersAsync.success], () => null);

export const validDisabledValidation = createReducer<IoT.ValidateDisabledResponse | null>(null)
  .handleAction(validateDisabledAsync.success, (_state, { payload }) => payload)
  .handleAction(validateDisabledAsync.request, () => null);

export default combineReducers({
  updateDeviceType,
  configFiles,
  firmwareFiles,
  bluetoothFiles,
  extFwFiles,
  basicParameters,
  validDisabledValidation,
  loading,
  error,
});
