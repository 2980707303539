import { from, of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  catchError,
  debounceTime,
  tap,
  distinctUntilChanged,
} from 'rxjs/operators';
import { isActionOf, isOfType, RootEpic } from 'typesafe-actions';
import constants, { DEFAULT_PAGE_SIZE } from 'src/constants';
import { SortDirection } from 'src/types/common';
import { Client } from 'store/Client/Client.types';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { routePaths } from 'src/routePaths';
import { resetList, setSelectedDevices, toggleDeviceSelect } from 'store/Device/Device.actions';
import { selectedDeviceCountSelector } from 'store/Device/Device.selectors';
import { filterSelector } from './AssignDevice.selectors';
import {
  findClientAsync,
  setFindClientName,
  endOfListReached,
  resetFind,
} from './AssignDevice.actions';

export const toFindClientReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findClientAsync.request)),
    mergeMap(() =>
      from(
        api.client.findClient({
          sortDescriptor: {
            direction: SortDirection.ASC,
            identifier: Client.SortIdentifier.NAME,
          },
          pageDescriptor: {
            page: store$.value.assignDevice.page,
            size: DEFAULT_PAGE_SIZE,
          },
          ...filterSelector(store$.value),
        }),
      ).pipe(
        map(findClientAsync.success),
        catchError((err) => of(findClientAsync.failure(String(err)))),
      ),
    ),
  );

export const toGetMoreClientsReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfListReached)),
    filter(() => state$.value.assignDevice.page != null),
    map(() => findClientAsync.request()),
  );

export const toSetFindClientName: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setFindClientName)),
    tap(() => {
      api.client.clientService.subgroup('findClient').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findClientAsync.request()),
  );

export const toFindInitClients: RootEpic = (action$, store$) =>
  action$.pipe(
    filter((action) => isOfType(LOCATION_CHANGE)(action)),
    map(() =>
      matchPath(store$.value.router.location.pathname, {
        path: [routePaths.device.assign],
      }),
    ),
    filter((match) => !!match),
    distinctUntilChanged(),
    map(() => findClientAsync.request()),
  );

/**
 * @description Epic is used to observe location change and toggleDeviceSelect action.
 * If assign device sidebar is open and no devices selected, then assign device sidebar should be closed
 */
export const toCheckAssignedDevices: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(
      (action) =>
        isOfType(LOCATION_CHANGE)(action) ||
        isActionOf([toggleDeviceSelect, setSelectedDevices, resetList])(action),
    ),
    map(() =>
      matchPath(store$.value.router.location.pathname, {
        path: [routePaths.device.assign],
      }),
    ),
    filter((match) => !!match),
    filter(() => !selectedDeviceCountSelector(store$.value)),
    map(() => push(routePaths.device.root)),
  );

export const toResetClientFind: RootEpic = (action$, store$) =>
  action$.pipe(
    filter((action) => isOfType(LOCATION_CHANGE)(action)),
    map(() =>
      matchPath(store$.value.router.location.pathname, {
        path: [routePaths.device.assign],
      }),
    ),
    filter((match) => !!match),
    map(() => resetFind()),
  );

export const toResetList: RootEpic = (action$) =>
  action$.pipe(filter(isActionOf([setFindClientName])), map(resetFind));
