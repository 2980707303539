import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
  switchMap,
  take,
  takeLast,
  tap,
} from 'rxjs/operators';
import { isActionOf, RootEpic } from 'typesafe-actions';
import { concat, EMPTY, from, Observable, of } from 'rxjs';
import { updateCurrentUser } from 'store/Authorization/Authorization.actions';
import { Authorization } from 'store/Authorization/Authorization.types';

import {
  changePassword,
  clearListFilters,
  createUserAsync,
  deleteMultipleUsers,
  deleteUserAsync,
  editCurrentUserAsync,
  editUserAndOrPasswordAsync,
  editUserAsync,
  endOfListReached,
  findUserAsync,
  getUser,
  initListFilters,
  initListOrdering,
  queryParseFinish,
  resetList,
  setDialogState,
  setListFilters,
  setUserListOrder,
  userListDestroy,
  userListInit,
} from 'store/User/User.actions';
import constants from 'src/constants';
import { closed, opened } from 'components/PageComponents';
import { calculateURLQuery } from 'src/utils/calculateURLQuery';
import { SortDescriptor } from 'types/common';
import { replace } from 'connected-react-router';
import { routePaths } from 'src/routePaths';
import { DEFAULT_USER_FILTERS, DEFAULT_USER_SORTING } from 'store/User/User.reducer';
import { User } from './User.types';
import { selectedUserIdsSelector, userChangedFiltersSelector } from './User.selectors';
import { parse } from '../../utils/queryParams';

export const toChangePasswordReq: RootEpic = (action$, _store$, { api }) => {
  return action$.pipe(
    filter(isActionOf(changePassword.request)),
    mergeMap(({ payload: { userId, body } }) =>
      from(api.user.changePassword(userId, body)).pipe(
        map(changePassword.success),
        catchError((err) => of(changePassword.failure(String(err)))),
      ),
    ),
  );
};
export const toEditCurrentUserReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(editCurrentUserAsync.request)),
    mergeMap(({ payload: { userId, body } }) =>
      from(api.user.editUser(userId, body)).pipe(
        map(editCurrentUserAsync.success),
        catchError((err) => of(editCurrentUserAsync.failure(String(err)))),
      ),
    ),
  );

export const toEditCurrentUserRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(editCurrentUserAsync.success)),
    mergeMap(({ payload }) => {
      if (payload === null) {
        return of(() => EMPTY);
      }
      const { name, role, id, email } = payload;
      const me: Pick<Authorization.Me, 'user'> = { user: { name, role, id, email } };
      return of(updateCurrentUser(me));
    }),
  );

export const toGetUserReq: RootEpic = (action$, _store$, { api }) => {
  return action$.pipe(
    filter(isActionOf(getUser.request)),
    mergeMap(({ payload: userId }) =>
      from(api.user.getUser(userId)).pipe(
        map(getUser.success),
        catchError((err) => of(getUser.failure(String(err)))),
      ),
    ),
  );
};

// User Page
export const toFindUserReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findUserAsync.request)),
    mergeMap(() =>
      from(
        api.user.findUser({
          // TODO: create selectors for sort and page descriptor
          sortDescriptor: store$.value.user.list.sortDescriptor,
          pageDescriptor: store$.value.user.list.pageDescriptor,
          ...userChangedFiltersSelector(store$.value),
        }),
      ).pipe(
        map(findUserAsync.success),
        catchError((err) => of(findUserAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description triggers initialization of query params
 */
export const onInit: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(userListInit)),
    map(() => parse(state$.value.router.location.search)),
    mergeMap((queryParams) => {
      const { direction, identifier, ...rest } = queryParams;
      const {
        direction: directionState,
        identifier: identifierState,
      } = state$.value.user.list.sortDescriptor;

      const orderingParams = {
        direction: direction || directionState,
        identifier: identifier || identifierState,
      } as User.ListSortDescriptor;

      return of(
        initListFilters({ ...state$.value.user.list.filters, ...rest }),
        initListOrdering(orderingParams),
        queryParseFinish(),
      );
    }),
  );

/**
 * @description Makes initial request to get list of users
 */

export const onQueryParamsInit: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(queryParseFinish)),
    map(() => findUserAsync.request()),
  );

export const toUpdateURL: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf([setListFilters, setUserListOrder, queryParseFinish, clearListFilters])),
    switchMap(() => {
      return calculateURLQuery<User.FindFilters, SortDescriptor<User.SortIdentifier>>(
        store$.pipe(pluck('user', 'list', 'filters'), take(1)),
        store$.pipe(pluck('user', 'list', 'sortDescriptor'), take(1)),
        DEFAULT_USER_FILTERS,
        DEFAULT_USER_SORTING,
      ).pipe(distinctUntilChanged());
    }),
    map((res) => replace(`${routePaths.user.root}${res}`)),
  );

/**
 * @description Cancel all requests on module unmount
 */
export const onDestroy: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(userListDestroy)),
    tap(() => {
      api.user.userService.cancelAll();
    }),
    mergeMap(() => EMPTY),
  );

export const toGetMoreUsersReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfListReached)),
    filter(() => state$.value.user.list.pageDescriptor.page != null),
    map(() => findUserAsync.request()),
  );

export const toCreateUserReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(createUserAsync.request)),
    mergeMap(({ payload }) =>
      from(api.user.createUser(payload)).pipe(
        map(createUserAsync.success),
        catchError(({ message }: Error) => of(createUserAsync.failure(message))),
      ),
    ),
  );

/**
 * @description On success creation closes creation dialog and fetch user list
 */
export const toCreateUserRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(createUserAsync.success)),
    mergeMap(() =>
      of(setDialogState(closed(User.Dialogs.CREATE_USER)), resetList(), findUserAsync.request()),
    ),
  );

export const toResetList: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([clearListFilters, setListFilters, userListDestroy])),
    map(resetList),
  );

export const toSetUserListOrder: RootEpic = (action$, _store, { api }) =>
  action$.pipe(
    filter(isActionOf([setUserListOrder, clearListFilters])),
    tap(() => {
      api.user.userService.subgroup('findUser').cancelAll();
    }),
    mergeMap(() => of(resetList(), findUserAsync.request())),
  );

export const toSetFilter: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setListFilters)),
    tap(() => {
      api.user.userService.subgroup('findUser').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findUserAsync.request()),
  );

export const toDeleteMultipleUsers: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(deleteMultipleUsers)),
    map(() =>
      setDialogState(
        opened(User.Dialogs.DELETE_CONFIRMATION, {
          userIdList: selectedUserIdsSelector(store$.value),
        }),
      ),
    ),
  );

export const toDeleteUserReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteUserAsync.request)),
    mergeMap(({ payload }) =>
      from(api.user.deleteUsers(payload)).pipe(
        map(deleteUserAsync.success),
        catchError((err) => of(deleteUserAsync.failure(String(err)))),
      ),
    ),
  );

export const toDeleteUserRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(deleteUserAsync.success)),
    mergeMap(() =>
      of(
        setDialogState(closed(User.Dialogs.DELETE_CONFIRMATION, { userIdList: [] })),
        resetList(),
        findUserAsync.request(),
      ),
    ),
  );

export const toEditUserReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(editUserAsync.request)),
    mergeMap(({ payload: { userId, body } }) =>
      from(api.user.editUser(userId, body)).pipe(
        map(editUserAsync.success),
        catchError((err) => of(editUserAsync.failure(String(err)))),
      ),
    ),
  );

export const toEditUserRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([editUserAsync.success, editUserAndOrPasswordAsync.success])),
    mergeMap(() =>
      of(
        setDialogState(closed(User.Dialogs.EDIT_USER, { userId: null })),
        resetList(),
        findUserAsync.request(),
      ),
    ),
  );

const handleEditAndPw = (obs$: Observable<User.UserRes>) =>
  obs$.pipe(
    map(editUserAndOrPasswordAsync.success),
    catchError((err) => of(editUserAndOrPasswordAsync.failure(String(err)))),
  );

export const toEditUserAndOrPasswordReq: RootEpic = (action$, _store$, { api }) => {
  return action$.pipe(
    filter(isActionOf(editUserAndOrPasswordAsync.request)),
    mergeMap(({ payload: { userId, editBody, pwBody } }) => {
      const sendEditUser = (body: User.EditUserReq['body']) =>
        from(api.user.editUser(userId, body));
      const sendChangePass = (body: User.ChangePassReq['body']) =>
        from(api.user.changePassword(userId, body));

      if (editBody && pwBody) {
        return concat(sendEditUser(editBody), sendChangePass(pwBody)).pipe(
          takeLast(1),
          handleEditAndPw,
        );
      }
      if (editBody) return sendEditUser(editBody).pipe(handleEditAndPw);
      if (pwBody) return sendChangePass(pwBody).pipe(handleEditAndPw);

      return of(editUserAndOrPasswordAsync.failure('Error toEditUserAndOrPasswordReq'));
    }),
  );
};
