// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2QJBLPhxnlkZREhhelnWFb {\n  display: flex;\n  flex: 1;\n}\n._2O1VLOvk8Ij9NchGLZkbls {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n._2WTtTveI5ZuZrXLv3LNzx9 {\n  display: flex;\n  flex-direction: column;\n  width: 206px;\n  background: var(--color-gray1);\n\n}\n._2WTtTveI5ZuZrXLv3LNzx9 ._3nvPnWDHA80rYG9LwCk6iV {\n    height: var(--length12);\n    background: var(--color-blue1);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n._3vmdES6Ofv69dpCCaPLSis {\n  display: flex;\n  height: var(--length12);\n  border-bottom: 1px solid var(--color-gray3);\n  align-items: center;\n  padding: 0 var(--length6);\n  justify-content: space-between;\n}\n._1oEyHDElonaSGgJfZqIyvr {\n  display: flex;\n  flex: 1;\n  overflow: auto;\n}\n._1oEyHDElonaSGgJfZqIyvr .JTR6Wa3GPxLFVoCbETBld {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    flex: 1;\n    padding: var(--length6);\n    box-sizing: border-box;\n    position: relative;\n  }\n", ""]);
// Exports
exports.locals = {
	"layout": "_2QJBLPhxnlkZREhhelnWFb",
	"main": "_2O1VLOvk8Ij9NchGLZkbls",
	"aside": "_2WTtTveI5ZuZrXLv3LNzx9",
	"logo": "_3nvPnWDHA80rYG9LwCk6iV",
	"header": "_3vmdES6Ofv69dpCCaPLSis",
	"section": "_1oEyHDElonaSGgJfZqIyvr",
	"wrapper": "JTR6Wa3GPxLFVoCbETBld"
};
module.exports = exports;
