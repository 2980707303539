// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._35GjjGyp8etkr4iB8uZ1MV {\n  background: var(--color-gray1);\n  margin-top: var(--length6);\n}\n\n  ._35GjjGyp8etkr4iB8uZ1MV a {\n    margin: 0 8px;\n    border-radius: var(--border-radius-strong);\n    color: var(--color-gray6);\n    text-decoration: none;\n    display: flex;\n    height: var(--length8);\n    padding: 0 var(--length2);\n    align-items: center;\n  }\n\n  ._35GjjGyp8etkr4iB8uZ1MV a._9ljijqozpJlR3Oaj5OXDH {\n      background: var(--color-blue4);\n    }\n._3qfP8mQ4OHRKJhDAGiakEb {\n  display: flex;\n  align-items: center;\n}\n._3qfP8mQ4OHRKJhDAGiakEb ._2cQmBqdYoFvNhN41BYzV3X {\n    margin-top: calc(var(--length1)*-1);\n    margin-right: var(--length2);\n  }", ""]);
// Exports
exports.locals = {
	"navMenu": "_35GjjGyp8etkr4iB8uZ1MV",
	"activeNav": "_9ljijqozpJlR3Oaj5OXDH",
	"navItem": "_3qfP8mQ4OHRKJhDAGiakEb",
	"icon": "_2cQmBqdYoFvNhN41BYzV3X"
};
module.exports = exports;
