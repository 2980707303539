import { createSelector } from 'reselect';
import { omitBy } from 'src/services/utils';
import { RootState } from 'typesafe-actions';
import { DEFAULT_USER_FILTERS } from './User.reducer';
import { User } from './User.types';

export const userFiltersSelector = (state: RootState) => state.user.list.filters;

export const userChangedFiltersSelector = createSelector(userFiltersSelector, (filters) =>
  omitBy(filters, (v) => Object.values(DEFAULT_USER_FILTERS).includes(v)),
);

export const sortDescriptorSelector = (state: RootState) => state.user.list.sortDescriptor;

export const userLoadingSelector = (name: User.AsyncActionKeys) => (state: RootState) =>
  state.user.loading[name];

export const userErrorSelector = (name: User.AsyncActionKeys) => (state: RootState) =>
  state.user.error[name];

export const userItemsSelector = (state: RootState) => state.user.list.items;

export const userIdsSelector = createSelector(userItemsSelector, (items) =>
  items.map(({ id }) => id),
);

export const selectedUserIdsSelector = (state: RootState) => state.user.list.selected;

export const isCreateDialogOpenSelector = (state: RootState) =>
  state.user.dialogStates[User.Dialogs.CREATE_USER].isOpen;

export const editDialogSelector = (state: RootState) =>
  state.user.dialogStates[User.Dialogs.EDIT_USER];

export const isEditDialogOpenSelector = createSelector(editDialogSelector, ({ isOpen }) => isOpen);

export const editUserIdSelector = createSelector(editDialogSelector, ({ userId }) => userId);

export const editUserSelector = createSelector(
  editUserIdSelector,
  userItemsSelector,
  (userId, users) => {
    return (users.find(({ id }) => id === userId) ?? {}) as User.UserRes;
  },
);

export const userIdsForDeleteSelector = (state: RootState) =>
  state.user.dialogStates[User.Dialogs.DELETE_CONFIRMATION].userIdList;

export const selectedUsersSelector = createSelector(
  userItemsSelector,
  selectedUserIdsSelector,
  (users, selectedIds) => users.filter(({ id }) => selectedIds.includes(id)),
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore ( strict doesnt understand .filter(Boolean) gives me return type (UserRes | undefined)[] )
export const usersReadyForDeletionSelector: (
  state: RootState,
) => User.UserRes[] = createSelector(
  userIdsForDeleteSelector,
  userItemsSelector,
  (userIdsForDelete, userItems) =>
    userIdsForDelete && userItems
      ? userIdsForDelete.map((id) => userItems.find((item) => item.id === id)).filter(Boolean)
      : [],
);

export const selectedUserCountSelector = createSelector(
  selectedUserIdsSelector,
  (selected) => selected.length,
);
