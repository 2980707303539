import { Authorization } from 'store/Authorization/Authorization.types';
import { getPostRequests } from '../defaultHeaders';
import { http2 } from '../http2';
import { handleResponse } from '../utils';

type AuthServiceRequests = 'login' | 'logout' | 'token' | 'me';

export const authService = http2.createGroup<AuthServiceRequests>('auth');

export const login = async (params: Authorization.LoginParams): Promise<Authorization.Token> => {
  const url = `/auth/login`;

  return authService
    .subgroup('login')
    .fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: getPostRequests(),
    })
    .request.then(
      handleResponse('Incorrect username or password', {
        403: 'User has no access granted for this application',
      }),
    );
};

export const logout = async (): Promise<void> => {
  const url = `/auth/logout`;

  return authService
    .subgroup('logout')
    .fetch(url, {
      method: 'POST',
      headers: getPostRequests(),
    })
    .request.then(handleResponse('Error trying to logout'));
};

export const getCurrentUser = async (): Promise<Authorization.Me> => {
  const url = '/auth/me';

  return authService
    .subgroup('me')
    .fetch(url)
    .request.then(handleResponse('Error trying to get current user'));
};

export default {
  authService,
  login,
  logout,
  getCurrentUser,
};
