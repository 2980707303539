import Icon from '@tt2/components/build/Atoms/Icon/Icon';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import routes from 'src/routes';
import cn from 'classnames';
import styles from './NavMenu.css';

interface NavMenuProps {
  onClick?: () => void;
  className?: string;
}

export const NavMenu: React.FC<NavMenuProps> = ({ onClick, className }) => {
  return (
    <nav className={cn(styles.navMenu, className)}>
      {routes
        .filter(({ isSubpage, hide }) => !isSubpage && !hide)
        .map(({ name, path, exact, navigationIcon }) => (
          <NavLink
            key={name}
            onClick={onClick}
            to={path as string}
            exact={exact}
            activeClassName={styles.activeNav}
          >
            {navigationIcon ? (
              <div className={styles.navItem}>
                <Icon className={styles.icon} name={navigationIcon} size={16} />
                {name}
              </div>
            ) : (
              name
            )}
          </NavLink>
        ))}
    </nav>
  );
};
