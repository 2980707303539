import { LOCATION_CHANGE, push } from 'connected-react-router';
import { matchPath } from 'react-router';
import { EMPTY, from, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, tap } from 'rxjs/operators';
import { routePaths } from 'src/routePaths';
import { appInit } from 'store/App/App.actions';
import { isActionOf, isOfType, RootEpic } from 'typesafe-actions';
import { remove } from 'js-cookie';
import { asyncActions as AssignClientAsyncActions } from 'store/AssignClient/AssignClient.actions';
import { asyncActions as AssignDeviceAsyncActions } from 'store/AssignDevice/AssignDevice.actions';
import { asyncActions as ClientAsyncActions } from 'store/Client/Client.actions';
import { asyncActions as CommandHistoryAsyncActions } from 'store/CommandHistory/CommandHistory.actions';
import { asyncActions as DeviceAsyncActions } from 'store/Device/Device.actions';
import { asyncActions as FileAsyncActions } from 'store/File/File.actions';
import { asyncActions as UpdateDeviceAsyncActions } from 'store/UpdateDevice/UpdateDevice.actions';
import { asyncActions as UserAsyncActions } from 'store/User/User.actions';
import { SESSION_TTL } from 'src/constants';
import {
  loginAsync,
  getCurrentAsync,
  logoutAsync,
  clearUserData,
  asyncActions as AuthorizationAsyncActions,
} from './Authorization.actions';

const asyncActionsRequests = Object.values({
  ...AuthorizationAsyncActions,
  ...AssignClientAsyncActions,
  ...AssignDeviceAsyncActions,
  ...ClientAsyncActions,
  ...CommandHistoryAsyncActions,
  ...DeviceAsyncActions,
  ...FileAsyncActions,
  ...UpdateDeviceAsyncActions,
  ...UserAsyncActions,
}).map((action) => action.request);

export const healthcheck: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(asyncActionsRequests)),
    debounceTime(SESSION_TTL + 500),
    map(() => getCurrentAsync.request()),
  );

/**
 * @description Epic that handles login api request
 */
export const toLoginReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(loginAsync.request)),
    mergeMap(({ payload }) =>
      from(api.authorization.login(payload)).pipe(
        map(loginAsync.success),
        catchError((err) => of(loginAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description Epic that handles logout api request
 */
export const toLogoutReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(logoutAsync.request)),
    mergeMap(() =>
      from(api.authorization.logout()).pipe(
        map(logoutAsync.success),
        catchError((err) => of(logoutAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description Epic that redirects to /login page after getting response from logout request
 * failure is here as 203 status code is recieved
 */
export const toLogoutRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([logoutAsync.request])),
    map(() => push(routePaths.login)),
  );

/**
 * @description Epic that emits action to get current user info after application starts or after login success
 * loginAsync.success requered as for now because payload from loginAsync.success and getCurrentAsync.success is different
 * TODO: BE should return same info in loginAsync.success, so FE wouldn't make additional request
 */
export const onAppInit: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([appInit, loginAsync.success])),
    map(() => getCurrentAsync.request()),
  );

/**
 * @description Epic that handles getCurrent user info api request
 */
export const toGetCurrentReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(getCurrentAsync.request)),
    mergeMap(() =>
      from(api.authorization.getCurrentUser()).pipe(
        map(getCurrentAsync.success),
        catchError((err) => of(getCurrentAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description Epic that redirects after successfull get current user info to initial pathname or root path if initial path was /login
 */
export const toGetCurrentRes: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(getCurrentAsync.success)),
    map(() => store$.value.router.location.pathname),
    map((pathname) => ({
      pathname,
      redirectPath: pathname === routePaths.login ? routePaths.root : pathname,
    })),
    mergeMap(({ redirectPath, pathname }) => {
      if (pathname !== redirectPath) {
        return of(push(redirectPath));
      }
      return EMPTY;
    }),
  );

/**
 * @description Epic that redirects to /login page if request to get current user info failed
 */
export const toGetCurrentErr: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(getCurrentAsync.failure)),
    map(() => push(routePaths.login)),
  );

export const toLoginPageChange: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isOfType(LOCATION_CHANGE)),
    map(() =>
      matchPath(store$.value.router.location.pathname, {
        path: [routePaths.login],
      }),
    ),
    filter((match) => !!match),
    tap(() => {
      remove('XSRF-TOKEN', { path: '/', domain: document.location.pathname });
    }),
    map(() => clearUserData()),
  );
