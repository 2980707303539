// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ss8XyMeIInmToLuiwTrhN {\n  position: relative;\n  width: var(--header-height);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  outline: none;\n  background: transparent;\n  border: 0;\n}\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw {\n    display: flex;\n    align-items: center;\n    position: relative;\n\n    width: 21px;\n    height: 16px;\n\n    cursor: pointer;\n    z-index: 3;\n  }\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw > span, ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw > span::before, ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw > span::after {\n      display: block;\n      position: absolute;\n\n      width: 100%;\n      height: 2px;\n\n      background-color: #616161;\n\n      transition-duration: .25s;\n    }\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw > span::before {\n      content: '';\n      top: -8px;\n    }\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw > span::after {\n      content: '';\n      top: 8px;\n    }\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw._1JPOKdhmUHVbGJLZW50Pdf {\n      width: 16px;\n    }\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw._1JPOKdhmUHVbGJLZW50Pdf > span, ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw._1JPOKdhmUHVbGJLZW50Pdf > span::before, ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw._1JPOKdhmUHVbGJLZW50Pdf > span::after {\n        background-color: #000;\n        height: 1px;\n      }\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw._1JPOKdhmUHVbGJLZW50Pdf > span {\n        transform: rotate(45deg);\n      }\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw._1JPOKdhmUHVbGJLZW50Pdf > span::before {\n          top: 0;\n          transform: rotate(0);\n        }\n\n  ._2ss8XyMeIInmToLuiwTrhN ._1hmRX1ZeSLQPwTwmPnO4Bw._1JPOKdhmUHVbGJLZW50Pdf > span::after {\n          top: 0;\n          transform: rotate(90deg);\n        }", ""]);
// Exports
exports.locals = {
	"hamburger": "_2ss8XyMeIInmToLuiwTrhN",
	"icon": "_1hmRX1ZeSLQPwTwmPnO4Bw",
	"checked": "_1JPOKdhmUHVbGJLZW50Pdf"
};
module.exports = exports;
