import { IoT } from 'store/Device/Device.types';
import { Websocket } from 'store/Websocket/Websocket.types';
import { File } from 'store/File/File.types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { CommandHistory } from './CommandHistory.types';

export const findHistoryAsync = createAsyncAction(
  'COMMAND_HISTORY | FIND_HISTORY_REQ',
  'COMMAND_HISTORY | FIND_HISTORY_RES',
  'COMMAND_HISTORY | FIND_HISTORY_ERR',
)<void, CommandHistory.FindHistoryResponse, string>();

export const resetList = createAction('COMMAND_HISTORY | RESET_FIND')();

export const endOfHistoryListReached = createAction('COMMAND_HISTORY | END_OF_LIST_REACHED')();

export const historyDestroy = createAction('COMMAND_HISTORY | DESTROY')();

export const setListFilters = createAction('COMMAND_HISTORY | LIST.SET_FILTERS')<
  Partial<CommandHistory.FindFilters>
>();

export const setHistoryListOrder = createAction(
  'COMMAND_HISTORY | LIST.SET_HISTORY_ORDER',
)<CommandHistory.HistoryListSortDescriptor>();

export const clearListFilters = createAction('COMMAND_HISTORY | LIST.CLEAR_FILTERS')();

export const cancelCommandAsync = createAsyncAction(
  'COMMAND_HISTORY | CANCEL_COMMAND_REQ',
  'COMMAND_HISTORY | CANCEL_COMMAND_RES',
  'COMMAND_HISTORY | CANCEL_COMMAND_ERR',
)<CommandHistory.CancelParams, CommandHistory.ExternalIoTDeviceCommand, string>();

export const setDialogState = createAction('COMMAND_HISTORY | SET_DIALOG_STATE')<
  Partial<CommandHistory.DialogStates>
>();

export const getBasicParametersAsync = createAsyncAction(
  'COMMAND_HISTORY | GET_BASIC_PARAMETERS_REQ',
  'COMMAND_HISTORY | GET_BASIC_PARAMETERS_RES',
  'COMMAND_HISTORY | GET_BASIC_PARAMETERS_ERR',
)<CommandHistory.ExternalIoTDeviceCommand['basicParametersId'], IoT.BasicParameters, string>();

export const updateCommandFromWS = createAction(
  'COMMAND_HISTORY | UPDATE_COMMAND_FROM_WS',
)<Websocket.CommandEvent>();

export const getHistoryFileAsync = createAsyncAction(
  'COMMAND_HISTORY | GET_HISTORY_FILE_REQ',
  'COMMAND_HISTORY | GET_HISTORY_FILE_RES',
  'COMMAND_HISTORY | GET_HISTORY_FILE_ERR',
)<
  {
    fileId: File.ExternalDeviceFileMetadata['uuid'];
    fileName: File.ExternalDeviceFileMetadata['fileName'];
  },
  void,
  string
>();

export const asyncActions = {
  findHistoryAsync,
  getBasicParametersAsync,
  getHistoryFileAsync,
};
