import { isActionOf, RootEpic } from 'typesafe-actions';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import constants from 'src/constants';
import { omitBy } from 'src/services/utils';
import { calculateURLQuery } from 'src/utils/calculateURLQuery';
import { SortDescriptor } from 'types/common';
import { replace } from 'connected-react-router';
import { routePaths } from 'src/routePaths';
import { parse } from '../../utils/queryParams';
import { File } from './File.types';
import {
  clearListFilters,
  deleteFileAsync,
  deleteMultipleFiles,
  destroyUploadDialog,
  endOfListReached,
  fileListDestroy,
  fileListInit,
  findFileAsync,
  finishUploadFile,
  getFileAsync,
  initListFilters,
  initListOrdering,
  queryParseFinish,
  resetList,
  setDialogState,
  setFileListOrder,
  setListFilters,
  startUploadFiles,
  uploadFileAsync,
  validateFileListAsync,
} from './File.actions';
import { DEFAULT_FILE_FILTERS, DEFAULT_FILE_SORTING } from './File.reducer';

/**
 * @description Epic that handles API request to get files
 */
export const toFindFileReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findFileAsync.request)),
    mergeMap(() =>
      from(
        api.file.findFile({
          sortDescriptor: store$.value.file.list.sortDescriptor,
          pageDescriptor: store$.value.file.list.pageDescriptor,
          ...omitBy(store$.value.file.list.filters, (v) =>
            Object.values(DEFAULT_FILE_FILTERS).includes(v),
          ),
        }),
      ).pipe(
        map(findFileAsync.success),
        catchError((err) => of(findFileAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description triggers initialization of query params
 */
export const onInit: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fileListInit)),
    map(() => parse(state$.value.router.location.search)),
    mergeMap((queryParams) => {
      const { direction, identifier, ...rest } = queryParams;
      const {
        direction: directionState,
        identifier: identifierState,
      } = state$.value.file.list.sortDescriptor;

      const orderingParams = {
        direction: direction || directionState,
        identifier: identifier || identifierState,
      } as File.FileListSortDescriptor;

      return of(
        initListFilters({ ...state$.value.file.list.filters, ...rest }),
        initListOrdering(orderingParams),
        queryParseFinish(),
      );
    }),
  );

export const onQueryParamsInit: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(queryParseFinish)),
    map(() => findFileAsync.request()),
  );

export const toUpdateURL: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf([setListFilters, setFileListOrder, clearListFilters, queryParseFinish])),
    switchMap(() =>
      calculateURLQuery<File.FindFilters, SortDescriptor<File.SortIdentifier>>(
        store$.pipe(pluck('file', 'list', 'filters'), take(1)),
        store$.pipe(pluck('file', 'list', 'sortDescriptor'), take(1)),
        DEFAULT_FILE_FILTERS,
        DEFAULT_FILE_SORTING,
      ).pipe(distinctUntilChanged()),
    ),
    map((res) => replace(`${routePaths.file.root}${res}`)),
  );

/**
 * @description cancel all requests of fileService
 */
export const onDestroy: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(fileListDestroy)),
    tap(() => {
      api.file.fileService.cancelAll();
    }),
    mergeMap(() => EMPTY),
  );

export const toSetFileListOrder: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf([setFileListOrder])),
    tap(() => {
      api.file.fileService.subgroup('findFile').cancelAll();
    }),
    mergeMap(() => of(resetList(), findFileAsync.request())),
  );

export const toGetMoreFileReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfListReached)),
    filter(() => state$.value.file.list.pageDescriptor.page != null),
    map(() => findFileAsync.request()),
  );

export const toUploadFileReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(uploadFileAsync.request)),
    mergeMap(({ payload }) =>
      from(api.file.uploadFile(payload)).pipe(
        map((data) => uploadFileAsync.success({ ...data, index: payload.index })),
        catchError((err) => of(uploadFileAsync.failure({ error: err, index: payload.index }))),
      ),
    ),
  );

/**
 * @description Epic emits multiple uploadFileAsync.request action depending on startUploadFiles payload
 */
export const toStartUploadFile: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(startUploadFiles)),
    mergeMap(({ payload: { files, visibility } }) =>
      files.map((file, index) => uploadFileAsync.request({ file, visibility, index })),
    ),
  );

export const toDestroyUploadDialog: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(destroyUploadDialog)),
    tap(() => api.file.fileService.subgroup('uploadFile').cancelAll()),
    mergeMap(() => EMPTY),
  );

export const toFinishUploadFile: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf([uploadFileAsync.success, uploadFileAsync.failure])),
    filter(() => store$.value.file.upload.fileUploadStatus.every(({ isDone }) => isDone)),
    mergeMap(() => of(finishUploadFile(), resetList(), findFileAsync.request())),
  );

export const toFinishUpload: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(finishUploadFile)),
    filter(() => store$.value.file.upload.fileUploadStatus.every(({ error }) => !error)),
    map(() => setDialogState({ UPLOAD_FILE: false })),
  );

/**
 * @description Opens Delete confirmation dialog on deleteMultipleFiles action
 */
export const toDeleteMultipleFiles: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(deleteMultipleFiles)),
    map(() =>
      setDialogState({
        DELETE_CONFIRMATION: {
          isOpen: true,
          fileUUIDList: store$.value.file.list.selected,
        },
      }),
    ),
  );

export const toDeleteFilesRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(deleteFileAsync.success)),
    mergeMap(() =>
      of(
        setDialogState({
          DELETE_CONFIRMATION: {
            isOpen: false,
            fileUUIDList: [],
          },
        }),
        resetList(),
        findFileAsync.request(),
      ),
    ),
  );

export const toDeleteFileReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteFileAsync.request)),
    mergeMap(({ payload }) =>
      from(api.file.deleteFiles(payload)).pipe(
        map(deleteFileAsync.success),
        catchError((err) => of(deleteFileAsync.failure(String(err)))),
      ),
    ),
  );

export const toSetFilter: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(setListFilters)),
    tap(() => {
      api.file.fileService.subgroup('findFile').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findFileAsync.request()),
  );

export const toClearFilter: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(clearListFilters)),
    tap(() => {
      api.file.fileService.subgroup('findFile').cancelAll();
    }),
    map(() => findFileAsync.request()),
  );

/**
 * @description Epic that handles API request to validate files if they can be deleted
 */
export const toValidateFileListeReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(validateFileListAsync.request)),
    mergeMap(({ payload }) =>
      from(api.file.validateFiles(payload)).pipe(
        map(validateFileListAsync.success),
        catchError((err) => of(validateFileListAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description Emits validateFileListAsync.request action when Delete confirmation dialog opens
 */
export const toDeleteConfirmationDialog: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(setDialogState)),
    filter(({ payload }) => payload.DELETE_CONFIRMATION?.isOpen === true),
    map(({ payload }) => validateFileListAsync.request(payload.DELETE_CONFIRMATION.fileUUIDList)),
  );

export const toResetList: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([finishUploadFile, clearListFilters, setListFilters, fileListDestroy])),
    map(resetList),
  );

export const toGetFileReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(getFileAsync.request)),
    mergeMap(({ payload }) =>
      from(api.file.getFile(payload)).pipe(
        map(getFileAsync.success),
        catchError((err) => of(getFileAsync.failure(String(err)))),
      ),
    ),
  );
