import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// eslint-disable-next-line import/no-named-as-default
import App from './App';
import store from './store';
import { VERSION, SYSTEM_ENV, BRANCH, SENTRY_DSN, DEV_SERVER } from './constants';
// eslint-disable-next-line no-console
console.info(
  `%c VERSION: ${VERSION} | SYSTEM_ENV: ${SYSTEM_ENV} | BRANCH: ${BRANCH}`,
  'background: #222; color: #bada55',
);

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  enabled: !DEV_SERVER,
  environment: SYSTEM_ENV,
  release: `dmp-app@${VERSION}`,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('app'),
);

/*
    Browser config for debug.
    More how to use debug:
    https://www.npmjs.com/package/debug
  */
if (DEV_SERVER || SYSTEM_ENV === 'test') {
  localStorage.debug = 'App:*';
}
