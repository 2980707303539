import { createAction, createAsyncAction } from 'typesafe-actions';
import { File } from './File.types';

export const findFileAsync = createAsyncAction(
  'FILE | FIND_FILE_REQ',
  'FILE | FIND_FILE_RES',
  'FILE | FIND_FILE_ERR',
)<void, File.FindFileResponse, string>();

export const fileListInit = createAction('FILE | FILE_LIST_INIT')();

export const initListFilters = createAction('FILE | LIST.INIT_FILTERS')<
  Partial<File.FindFilters>
>();

export const initListOrdering = createAction(
  'FILE | LIST.INIT_FILE_ORDER',
)<File.FileListSortDescriptor>();

export const queryParseFinish = createAction('FILE | LIST.QUERY_PARSE_FINISH')<void>();

export const fileListDestroy = createAction('FILE | FILE_LIST_DESTROY')();

export const setFileListOrder = createAction(
  'FILE | LIST.SET_FILE_ORDER',
)<File.FileListSortDescriptor>();

export const toggleFileSelect = createAction('FILE | TOGGLE_FILE_SELECT')<
  File.ExternalDeviceFileMetadata['uuid']
>();
export const setSelectedFiles = createAction('FILE | LIST.SET_SELECTED_FILES')<
  File.ExternalDeviceFileMetadata['uuid'][]
>();

export const endOfListReached = createAction('FILE | LIST.END_OF_LIST_REACHED')();

export const setDialogState = createAction('FILE | SET_DIALOG_STATE')<Partial<File.DialogStates>>();

export const startUploadFiles = createAction('FILE | START_UPLOAD_FILES')<File.UploadFormData>();

export const uploadedFilesChanged = createAction('FILE | UPLOADED_FILES_CHANGED')();

export const finishUploadFile = createAction('FILE | FINISH_UPLOAD_FILES')();

export const destroyUploadDialog = createAction('FILE | DESTROY_UPLOAD_DIALOG')();

export const uploadFileAsync = createAsyncAction(
  'FILE | UPLOAD_FILE_REQ',
  'FILE | UPLOAD_FILE_RES',
  'FILE | UPLOAD_FILE_ERR',
)<
  File.UploadFile & { index: number },
  File.ExternalDeviceFileMetadata & { index: number },
  { error: string; index: number }
>();

export const deleteMultipleFiles = createAction('FILE | DELETE_MULTIPLE_FILES')();

export const deleteFileAsync = createAsyncAction(
  'FILE | DELETE_FILE_REQ',
  'FILE | DELETE_FILE_RES',
  'FILE | DELETE_FILE_ERR',
)<File.ExternalDeviceFileMetadata['uuid'][], File.ExternalDeviceFileMetadata['uuid'][], string>();

export const setListFilters = createAction('FILE | LIST.SET_FILTERS')<Partial<File.FindFilters>>();

export const clearListFilters = createAction('FILE | LIST.CLEAR_FILTERS')();

export const validateFileListAsync = createAsyncAction(
  'FILE | VALIDATE_FILE_LIST_REQ',
  'FILE | VALIDATE_FILE_LIST_RES',
  'FILE | VALIDATE_FILE_LIST_ERR',
)<File.ExternalDeviceFileMetadata['uuid'][], File.ValidateResponse, string>();

export const resetList = createAction('FILE | RESET_LIST')();

export const getFileAsync = createAsyncAction(
  'FILE | GET_FILE_REQ',
  'FILE | GET_FILE_RES',
  'FILE | GET_FILE_ERR',
)<
  {
    fileId: File.ExternalDeviceFileMetadata['uuid'];
    fileName: File.ExternalDeviceFileMetadata['fileName'];
  },
  void,
  string
>();

export const asyncActions = {
  findFileAsync,
  uploadFileAsync,
  deleteFileAsync,
  validateFileListAsync,
  getFileAsync,
};
