import Button from '@tt2/components/build/Atoms/Button';
import FormikInput from '@tt2/components/build/Atoms/FormikInput';
import FormikPassword from '@tt2/components/build/Atoms/FormikPassword';
import { Form, Formik } from 'formik';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { loginAsync } from 'store/Authorization/Authorization.actions';
import { Authorization } from 'store/Authorization/Authorization.types';
import { object, string } from 'yup';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoImg from 'src/assets/login-logo.webp';
import PageWrapper from 'components/PageWrapper';
import { RootState } from 'typesafe-actions';
import {
  authorizationLoadingSelector,
  authorizationErrorSelector,
} from 'store/Authorization/Authorization.selectors';
import Alert from 'components/Alert';
import { RequestState } from 'src/types/common';
import usePrevious from 'src/utils/usePrevious';
import transl from 'src/utils/translate';
import styles from './LoginPage.css';

const validationSchema = object().shape({
  username: string().required('Username is required'),
  password: string().required('Password is required'),
});

type StateProps = RequestState;
type OwnProps = unknown;
type ActionProps = {
  onSubmit: (params: Authorization.LoginParams) => void;
};

export type LoginPageProps = StateProps & OwnProps & ActionProps;

export const LoginPage: React.FC<LoginPageProps> = ({ onSubmit, loading, error }) => (
  <PageWrapper className={styles.background}>
    <Formik<Authorization.LoginParams>
      initialValues={{ password: '', username: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isValid, values, validateForm }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const prevValues = usePrevious(values);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          const timer = setInterval(() => {
            if (Object.entries(values).toString() === Object.entries(prevValues).toString()) {
              validateForm(values);
            }
          }, 100);
          return () => {
            clearInterval(timer);
          };
        }, [prevValues, validateForm, values]);
        return (
          <Form className={styles.form}>
            <div className={styles.logo}>
              <img src={logoImg} alt="Ruptela" width={180} height={68} />
            </div>
            <FormikInput
              label="Username"
              type="text"
              name="username"
              autoComplete="username"
              placeholder=""
              style={{ width: '100%' }}
            />
            <FormikPassword
              label="Password"
              name="password"
              placeholder=""
              autoComplete="current-password"
            />
            {error && (
              <Alert type="error" className={styles.error}>
                {error}
              </Alert>
            )}
            <div className={styles.footer}>
              <Button disabled={!isValid} type="submit" isLoading={loading}>
                {transl('Global.Login')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  </PageWrapper>
);

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  loading: authorizationLoadingSelector('loginAsync')(state),
  error: authorizationErrorSelector('loginAsync')(state),
});

const mapDispatchToProps: MapDispatchToProps<ActionProps, OwnProps> = {
  onSubmit: loginAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
