// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3xbG5cGBL_65GBnemBXr5V {\n  position: absolute;\n  top: 0;\n  right: var(--length2);\n\n  display: flex !important;\n  align-items: center;\n  height: 100%;\n\n  transition: transform var(--animation-normal);\n  transform: rotate(0deg);\n}\n\n  ._3xbG5cGBL_65GBnemBXr5V._1LHkO0agTAgm0KxHTcVzNG {\n    transform: rotate(180deg);\n    color: var(--color-blue1)\n  }", ""]);
// Exports
exports.locals = {
	"icon": "_3xbG5cGBL_65GBnemBXr5V",
	"rotate": "_1LHkO0agTAgm0KxHTcVzNG"
};
module.exports = exports;
