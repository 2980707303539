/* eslint-disable no-param-reassign */
import { GA_PROPERTY_ID, HOTJAR_ID, SYSTEM_ENV } from 'src/constants';
import { LocationChangeAction } from 'connected-react-router';
import { pageview, initialize, EventArgs, event, set } from 'react-ga';
import { Location } from 'history';
import { Authorization } from 'store/Authorization/Authorization.types';

export const isProd = () => SYSTEM_ENV === 'prod';
export const isTest = () => SYSTEM_ENV === 'test';

export const toRouteLocation = ({ payload: { location } }: LocationChangeAction): Location =>
  location;

export const callPageView = ({ pathname, search }: Location): void => pageview(pathname + search);

export const initGA = () => initialize(GA_PROPERTY_ID, {});

export const setTypesOfCurrentDimensions = ({
  client: { type },
  user: { role },
}: Authorization.Me) => {
  set({ dimension1: type, dimension2: role });
};

export const sendEventGA = (arg: EventArgs) => () => event(arg);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadHotjarScript = (h: any, o: Document, t: string, j: string) => {
  h.hj =
    h.hj ||
    // eslint-disable-next-line func-names
    function () {
      // eslint-disable-next-line prefer-rest-params
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    hjid: HOTJAR_ID,
    hjsv: 6,
  };
  const a = o.getElementsByTagName('head')[0];
  const r = o.createElement('script');
  r.async = true;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
  return new Promise<void>((res, rej) => {
    r.onload = () => {
      const interval = setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window?.hj?.scriptLoaded) {
          res();
          clearInterval(interval);
        }
      }, 100);
    };
    r.onerror = rej;
  });
};

export const loadCookiebot = () => {
  const a = document.getElementsByTagName('head')[0];
  const r = document.createElement('script');
  r.src = 'https://consent.cookiebot.com/uc.js';
  r.setAttribute('data-cbid', '369ae10a-e14a-49ba-a688-1821777207ad');
  r.setAttribute('data-blockingmode', 'auto');
  a.appendChild(r);
};

export default {
  isProd,
  isTest,
  callPageView,
  initGA,
  sendEventGA,
  loadHotjarScript,
  loadCookiebot,
  toRouteLocation,
  setTypesOfCurrentDimensions,
};
