import { PageDescriptor, ListResponse, ListLocationQuery, SortDescriptor } from 'src/types/common';
import { Client } from 'store/Client/Client.types';
import { File } from 'store/File/File.types';
import React from 'react';
import { asyncActions } from './Device.actions';

export namespace IoT {
  export interface Device {
    created: string;
    id: number;
    imei: React.ReactText;
    name: string;
    deviceType?: DeviceType;
    cfgVersion?: string;
    fwVersion?: string;
    hwVersion?: number;
    lastTimeConnected?: string;
    lastCommandStatus?: LastCommandStatus;
    basicParametersId?: number;
    clientName: string;
    inactivityTimeout?: number;
    periodicConnect?: number;
    connectorType?: ConnectorType;
    extVersion?: string;
    supportSmsSending: boolean;
    dmpEnabled: boolean;
    clientId: number;
  }

  export interface LastCommandStatus {
    basicParametersId?: number;
    status: CommandStatus;
    type: File.FileType;
    created: string;
    updated: string;
  }

  export enum CommandStatus {
    PENDING = 'PENDING',
    UPLOADING = 'UPLOADING',
    UPLOADED = 'UPLOADED',
    UPDATED = 'UPDATED',
    ERROR = 'ERROR',
    QUEUE = 'QUEUE',
    CANCELED = 'CANCELED',
    TIMEOUT = 'TIMEOUT',
  }

  export enum ConnectorType {
    IOT = 'IOT',
    DCS = 'DCS',
    FLESPI = 'FLESPI',
  }

  export enum DeviceType {
    SM5 = 'SM5',
    PRO5 = 'PRO5',
    HCV5 = 'HCV5',
    LCV5 = 'LCV5',
    HCV5_LITE = 'HCV5_LITE',
    TRACE5 = 'TRACE5',
    TRACE5_LTM = 'TRACE5_LTM',
    TRACE5_NA = 'TRACE5_NA',
    TRACE5_2G = 'TRACE5_2G',
    TCO4_HCV = 'TCO4_HCV',
    TCO4_HCV_BT = 'TCO4_HCV_BT',
    TCO4_LCV = 'TCO4_LCV',
    TCO4_LCV_BT = 'TCO4_LCV_BT',
    PRO4 = 'PRO4',
    ECO4_RS_T = 'ECO4_RS_T',
    ECO4_S_T = 'ECO4_S_T',
    ECO4 = 'ECO4',
    ECO5 = 'ECO5',
    PLUG4 = 'PLUG4',
    PLUG5 = 'PLUG5',
    OTHER = 'OTHER',
    UNSPECIFIED = 'UNSPECIFIED',
  }

  export enum SortIdentifier {
    IMEI = 'IMEI',
    NAME = 'NAME',
    CLIENT_NAME = 'CLIENT_NAME',
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    DEVICE_TYPE = 'DEVICE_TYPE',
    FW_VERSION = 'FW_VERSION',
    CFG_VERSION = 'CFG_VERSION',
    COMMAND_STATUS = 'COMMAND_STATUS',
    OPERATION = 'COMMAND_TYPE',
    ID = 'ID',
  }

  export enum FilterIdentifier {
    CFG_VERSION = 'cfgVersion',
    CLIENT_NAME = 'clientName',
    COMMAND_STATUS = 'commandStatuses',
    DEVICE_TYPE = 'deviceTypes',
    FW_VERSION = 'fwVersion',
    IMEI = 'imei',
    NAME = 'name',
    OPERATION = 'commandTypes',
  }

  export type BasicParameters = {
    destination: string;
    port: number;
    protocol: Protocol;
    timeWithoutEngine: number;
    timeWithEngine: number;
    distance: number;
    angle: number;
    engineDetection: EngineDetectionConfigurationValue;
    sleep: SleepConfigurationValue;
    sleepTimer: number;
    connectivityTimer: ConnectivityTimer;
    dmpEnabled: boolean;
  };

  export enum ConnectivityTimer {
    NEAR_REAL_TIME = 'NEAR_REAL_TIME',
    NORMAL = 'NORMAL',
    CUSTOM = 'CUSTOM',
  }

  export enum Protocol {
    TCP = 'TCP',
    UDP = 'UDP',
  }

  export enum EngineDetectionConfigurationValue {
    DIN_1 = 'DIN_1',
    DIN_2 = 'DIN_2',
    DIN_3 = 'DIN_3',
    DIN_4 = 'DIN_4',
    MOVEMENT_SENSOR = 'MOVEMENT_SENSOR',
    POWER_SUPPLY_12V = 'POWER_SUPPLY_12V',
    POWER_SUPPLY_24V = 'POWER_SUPPLY_24V',
    CUSTOM = 'CUSTOM',
  }

  export enum SleepConfigurationValue {
    DISABLED = 'DISABLED',
    SLEEP = 'SLEEP',
    CUSTOM = 'CUSTOM',
  }

  export type UploadBasicParametersResponse = {
    deviceId: IoT.Device['id'];
    message: string;
  }[];

  export type AsyncActionKeys = keyof typeof asyncActions;

  export type DeviceListSortDescriptor = SortDescriptor<SortIdentifier>;

  export interface FindDeviceRequest extends FindFilters {
    pageDescriptor: PageDescriptor;
    sortDescriptor?: DeviceListSortDescriptor;
  }

  export interface CommandUploadRequest {
    fwFileId: File.ExternalDeviceFileMetadata['uuid'];
    cfgFileId: File.ExternalDeviceFileMetadata['uuid'];
    bleFileId: File.ExternalDeviceFileMetadata['uuid'];
    extFileId: File.ExternalDeviceFileMetadata['uuid'];
    deviceIds: Device['id'][];
  }

  export type FindFilters = {
    search?: string;
    imeis?: Device['imei'][];
    imei?: string;
    name?: Device['name'];
    clientName?: Device['clientName'];
    deviceTypes?: DeviceType[];
    commandStatuses?: CommandStatus[];
    commandTypes?: File.FileType[];
    fwVersion?: Device['fwVersion'];
    cfgVersion?: Device['cfgVersion'];
  };

  export interface DeviceListLocationQuery extends ListLocationQuery, FindFilters {}

  export enum DeviceListColumnKey {
    ID = 'id',
    NAME = 'name',
    IMEI = 'imei',
    CLIENT_NAME = 'clientName',
    ACTIONS = 'actions',
    ROW_SELECT = 'rowSelect',
    DEVICE_TYPE = 'deviceType',
    FM_VERSION = 'fwVersion',
    CFG_VERSION = 'cfgVersion',
    COMMAND_STATUS = 'lastCommandStatus',
    OPERATION = 'operation',
  }

  export type FindDeviceResponse = ListResponse<Device>;

  export type RegisterDeviceRequest = Pick<Device, 'imei' | 'name'>;

  export type EditDeviceRequest = { deviceId: IoT.Device['id']; params: IoT.RegisterDeviceRequest };

  export enum Dialogs {
    REGISTER_DEVICE = 'REGISTER_DEVICE',
    EDIT_DEVICE = 'EDIT_DEVICE',
    DELETE_CONFIRMATION = 'DELETE_CONFIRMATION',
  }

  export type DialogStates = {
    [Dialogs.REGISTER_DEVICE]: boolean;
    [Dialogs.EDIT_DEVICE]: {
      isOpen: boolean;
      deviceId: Device['id'] | null;
    };
    [Dialogs.DELETE_CONFIRMATION]: {
      isOpen: boolean;
      deviceIdList: Device['id'][];
    };
  };

  export interface RegisterDeviceResponse {
    sasToken: string;
    device: Device;
  }

  export interface DeleteDeviceResponse {
    deletedDevices: Device['id'][];
  }

  export interface CheckImeisResponse {
    existImeis: Device['imei'][];
    nonExistImeis: Device['imei'][];
  }

  export interface AssignDeviceRequest {
    clientId: Client.ExternalClient['id'];
    deviceIds: Device['id'][];
  }

  export interface ValidateDisabledResponse {
    dmpDisabledDevicesIds: Device['id'][];
    valid: boolean;
    errorMessage: string;
  }
}
