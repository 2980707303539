import { capitalize } from 'src/utils/strings';
import { Websocket } from 'store/Websocket/Websocket.types';
import { IoT } from './Device.types';
import t, { TranslateKey } from '../../utils/translate';
import { File } from '../File/File.types';

const toLastCommandStatus = ({
  data: { created, updated, status, fileType, basicParametersId },
}: Websocket.CommandEvent): IoT.Device['lastCommandStatus'] => ({
  updated,
  created,
  status,
  type: fileType,
  basicParametersId,
});

export const updateDeviceCommand = (commandEvent: Websocket.CommandEvent) => (
  device: IoT.Device,
): IoT.Device =>
  device.id === commandEvent.resourceId
    ? {
        ...device,
        basicParametersId: toLastCommandStatus(commandEvent).basicParametersId,
        lastCommandStatus: toLastCommandStatus(commandEvent),
      }
    : device;

export const updateDeviceByEvent = ({ data, resourceId }: Websocket.DeviceEvent) => (
  device: IoT.Device,
): IoT.Device => (device.id === resourceId ? data : device);

export const deviceNotInIdList = (idList: IoT.Device['id'][]) => ({ id }: IoT.Device): boolean =>
  !idList.includes(id);

export const removeImeiFromArray = (imeiToRemove: IoT.Device['imei']) => (
  imei: IoT.Device['imei'],
) => imeiToRemove !== imei;

export const updateImeiArray = (
  isRemoving: boolean,
  imeiArray: IoT.Device['imei'][],
  imei: IoT.Device['imei'],
) => (isRemoving ? imeiArray.filter(removeImeiFromArray(imei)) : imeiArray);

export const DeviceTypeListable = Object.keys(IoT.DeviceType).map((key: IoT.DeviceType) => ({
  eventKey: key,
  content: t(key),
}));

export const CommandStatusListable = Object.keys(IoT.CommandStatus).map((key) => ({
  eventKey: key,
  content: capitalize(key),
}));

export const CommandTypesListable = Object.keys(File.FileType).map((key: File.FileType) => ({
  eventKey: key,
  content: t(`Device.Table.LastCommand.Label.${key}` as TranslateKey),
}));
