// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3224mRe9mXyWV2HqDeSeXu {\n  cursor: pointer;\n  display: flex;\n  position: relative;\n}\n\n._3NEusnm6_d-z9B9tDau9D8 {\n  border-radius: var(--border-radius-strong);\n  border: 1px solid var(--color-gray3);\n  background-color: var(--color-gray6);\n  overflow: hidden;\n}\n\n._3NEusnm6_d-z9B9tDau9D8 ._2p4Uu7-5osr3VMtK3tPfDU {\n    padding-left: 16px;\n    padding-right: 16px;\n    border-bottom: none;\n  }\n\n._3lSUs8ZQCiuYQin0rU9Bhc {\n  width: var(--length8);\n  border-left: 1px solid var(--color-gray3);\n  height: 100%;\n  margin-left: var(--length2);\n  display: flex;\n  justify-content: center;\n}\n\n._3lSUs8ZQCiuYQin0rU9Bhc > div {\n    right: auto;\n  }\n._1il0BR6cEOHMdfB-EeAgDh > div {\n  display: inline-flex !important;\n  place-self: auto !important;\n  padding-right: 0 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_3224mRe9mXyWV2HqDeSeXu",
	"dropdown": "_3NEusnm6_d-z9B9tDau9D8",
	"option": "_2p4Uu7-5osr3VMtK3tPfDU",
	"arrow": "_3lSUs8ZQCiuYQin0rU9Bhc",
	"tag": "_1il0BR6cEOHMdfB-EeAgDh"
};
module.exports = exports;
