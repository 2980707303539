import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isActionOf, RootEpic } from 'typesafe-actions';
import { updateCommandFromWS } from 'store/CommandHistory/CommandHistory.actions';
import { getDeviceById } from 'store/Device/Device.selectors';
import { getCurrentAsync, logoutAsync } from 'store/Authorization/Authorization.actions';
import { updateDeviceFromWS } from 'store/Device/Device.actions';
import { clientIdSelector } from 'store/Authorization/Authorization.selectors';
import { initWebsocket, recieveMessage, closeWebsocket } from './Websocket.actions';
import { Websocket } from './Websocket.types';
import { connectionStartSelector } from './Websocket.selector';

export const toLoginRes: RootEpic = (action$) =>
  action$.pipe(filter(isActionOf(getCurrentAsync.success)), map(initWebsocket));

export const toLogoutRes: RootEpic = (action$) =>
  action$.pipe(filter(isActionOf([logoutAsync.success, logoutAsync.failure])), map(closeWebsocket));

export const toInitWebsockets: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(initWebsocket)),
    map(() => api.websocket.createConnection({ clientId: clientIdSelector(state$.value) })),
    switchMap((subs) =>
      subs.pipe(
        takeUntil(
          state$.pipe(
            map(connectionStartSelector),
            filter((isStarted) => !isStarted),
          ),
        ),
        map(recieveMessage),
        catchError(() => of(closeWebsocket())),
      ),
    ),
  );

export const toCloseWebsocketClient: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(closeWebsocket)),
    tap(() => {
      api.websocket.closeConnection();
    }),
    mergeMap(() => EMPTY),
  );

export const toRecieveCommandMessage: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(recieveMessage)),
    map(({ payload: { data } }) => data),
    filter(
      ({ type, action, resourceId }) =>
        type === Websocket.EventDataType.COMMAND &&
        action === Websocket.Action.UPDATED &&
        !!getDeviceById(resourceId)(state$.value),
    ),
    map(updateCommandFromWS),
  );

// export const toRecieveLogoutMessage: RootEpic = (action$) =>
//   action$.pipe(
//     filter(isActionOf(recieveMessage)),
//     map(({ payload: { data } }) => data),
//     filter(
//       ({ type, action }) =>
//         type === Websocket.EventDataType.AUTH && action === Websocket.Action.DELETED,
//     ),
//     // TODO: clear user data in redux
//     map(() => push(routePaths.login)),
//   );

export const toRecieveDeviceMessage: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(recieveMessage)),
    map(({ payload: { data } }) => data),
    filter(
      ({ type, action, resourceId }) =>
        type === Websocket.EventDataType.DEVICE &&
        action === Websocket.Action.UPDATED &&
        !!getDeviceById(resourceId)(state$.value),
    ),
    map(updateDeviceFromWS),
  );
