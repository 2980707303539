import * as React from 'react';
import { NavLink } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoImg from 'src/assets/Logo.png';
import { AnimatePresence } from 'framer-motion';
import { routePaths } from 'src/routePaths';
import UserDropdown from 'components/UserDropdown';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { NavMenu } from 'components/NavMenu/NavMenu';
import styles from './Layout.css';

export const Layout: React.FC = ({ children }) => (
  <div className={styles.layout} data-test-id="layout--div-0">
    <aside className={styles.aside}>
      <div className={styles.logo} data-test-id="layout--div-1">
        <NavLink to={routePaths.root} exact style={{ display: 'flex' }}>
          <img src={logoImg} alt="Ruptela" height={26} width={85} data-test-id="layout--img-0" />
        </NavLink>
      </div>
      <NavMenu />
    </aside>
    <main className={styles.main}>
      <header className={styles.header}>
        <Breadcrumb />
        <UserDropdown />
      </header>
      <section className={styles.section}>
        <AnimatePresence>
          <div className={styles.wrapper} data-test-id="layout--div-2">
            {children}
          </div>
        </AnimatePresence>
      </section>
    </main>
  </div>
);

export default Layout;
