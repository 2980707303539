import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import { Client } from 'store/Client/Client.types';
import { Authorization } from './Authorization.types';

export const preparedSelector = (state: RootState) => state.authorization.prepared;
export const currentUserSelector = (state: RootState) => state.authorization.user;
export const currentClientSelector = (state: RootState) => state.authorization.client;

export const clientTypeSelector = createSelector(currentClientSelector, (client) => client?.type);
export const userRoleSelector = createSelector(currentUserSelector, (user) => user?.role);

export const authorizationLoadingSelector = (name: Authorization.AsyncActionKeys) => (
  state: RootState,
) => state.authorization.loading[name];

export const authorizationErrorSelector = (name: Authorization.AsyncActionKeys) => (
  state: RootState,
) => state.authorization.error[name];

export const isAdminSelector = createSelector(
  clientTypeSelector,
  (clientType = Client.Type.END_USER) => ({
    isAdmin: clientType === Client.Type.RUPTELA_HQ || clientType === Client.Type.RUPTELA_BRANCH,
  }),
);

export const isObserverSelector = createSelector(
  currentUserSelector,
  (user) => user?.role === Authorization.ROLE.OBSERVER,
);

export type IsAdminRole = { isAdmin: boolean };

export const clientIdSelector = (state: RootState) => state.authorization.client?.id;

export const isUserCanToDisableDmpSelector = createSelector(
  clientTypeSelector,
  userRoleSelector,
  (type, role) =>
    type === Client.Type.RUPTELA_HQ &&
    [Authorization.ROLE.ADMINISTRATOR, Authorization.ROLE.OPERATOR].includes(role),
);
