import { LOCATION_CHANGE, push } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { from, of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  catchError,
  distinctUntilChanged,
  debounceTime,
  tap,
} from 'rxjs/operators';
import { SortDirection } from 'src/types/common';
import { File } from 'store/File/File.types';
import { isActionOf, isOfType, RootEpic } from 'typesafe-actions';
import {
  findDeviceAsync,
  resetList,
  setSelectedDevices,
  toggleDeviceSelect,
} from 'src/store/Device/Device.actions';
import constants, { DEFAULT_PAGE_SIZE } from 'src/constants';
import {
  selectedDeviceCountSelector,
  selectedItemsDeviceTypesSelector,
} from 'store/Device/Device.selectors';
import { routePaths } from 'src/routePaths';
import {
  commandsUploadAsync,
  endOfConfigListReached,
  endOfFirmwareListReached,
  findConfigFileAsync,
  findFirmwareFileAsync,
  resetFindConfig,
  resetFindFirmware,
  setFindConfigFileName,
  setFindFirmwareFileName,
  setUpdateDeviceType,
  getBasicParametersAsync,
  uploadBasicParametersAsync,
  resetFindBluetooth,
  findBluetoothFileAsync,
  endOfBluetoothListReached,
  setFindBluetoothFileName,
  findExtFwFileAsync,
  endOfExtFwListReached,
  setFindExtFwFileName,
  resetFindExtFw,
} from './UpdateDevice.actions';
import {
  updateDeviceBluetoothPageSelector,
  updateDeviceConfigPageSelector,
  updateDeviceExtFwPageSelector,
  updateDeviceFirmwarePageSelector,
  updateDeviceTypeSelector,
} from './UpdateDevice.selectors';

/**
 * @description Epic to handle find config files request
 */
export const toFindConfigReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findConfigFileAsync.request)),
    mergeMap(() =>
      from(
        api.file.findFile({
          sortDescriptor: {
            direction: SortDirection.ASC,
            identifier: File.SortIdentifier.FILE_NAME,
          },
          pageDescriptor: {
            page: updateDeviceConfigPageSelector(store$.value),
            size: DEFAULT_PAGE_SIZE,
          },
          deviceTypes: [updateDeviceTypeSelector(store$.value)],
          fileName: store$.value.updateDevice.configFiles.filter,
          fileTypes: [File.FileType.CFG, File.FileType.CFG_DIFF],
        }),
      ).pipe(
        map(findConfigFileAsync.success),
        catchError((err) => of(findConfigFileAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description Complex epic that fired after location changes to /device/configure - opens sidebar or device is selected.
 *
 * If Sidebar is opened and devicy type of each selected device is the same, then dispatching  {@link setUpdateDeviceType} action.
 */
export const toSetUpdateDeviceType: RootEpic = (action$, store$) =>
  action$.pipe(
    filter(
      (action) =>
        isOfType(LOCATION_CHANGE)(action) ||
        isActionOf([toggleDeviceSelect, setSelectedDevices, resetList])(action),
    ),
    map(() =>
      matchPath(store$.value.router.location.pathname, {
        path: [routePaths.device.configure.root],
      }),
    ),
    filter((match) => !!match),
    mergeMap(() => {
      if (selectedDeviceCountSelector(store$.value)) {
        return store$.pipe(
          map(selectedItemsDeviceTypesSelector),
          filter((deviceList) => deviceList.length === 1 && !!deviceList[0]),
          map((deviceList) => deviceList[0]),
          distinctUntilChanged(),
          map(setUpdateDeviceType),
        );
      }
      return of(push(routePaths.device.root));
    }),
  );

export const setUpdateDeviceTypeTrigger: RootEpic = (action$, _store, { api }) =>
  action$.pipe(
    filter(isActionOf(setUpdateDeviceType)),
    tap(() => {
      api.file.fileService.subgroup('findFile').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    mergeMap(() =>
      of(
        resetFindConfig(),
        resetFindFirmware(),
        resetFindBluetooth(),
        resetFindExtFw(),
        findConfigFileAsync.request(),
        findFirmwareFileAsync.request(),
        findBluetoothFileAsync.request(),
        findExtFwFileAsync.request(),
      ),
    ),
  );

export const toGetMoreConfigReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfConfigListReached)),
    filter(() => updateDeviceConfigPageSelector(state$.value) != null),
    map(() => findConfigFileAsync.request()),
  );

export const toGetMoreFirmwareReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfFirmwareListReached)),
    filter(() => updateDeviceFirmwarePageSelector(state$.value) != null),
    map(() => findFirmwareFileAsync.request()),
  );

export const toGetMoreBluetoothReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfBluetoothListReached)),
    filter(() => updateDeviceBluetoothPageSelector(state$.value) != null),
    map(() => findBluetoothFileAsync.request()),
  );

export const toGetMoreExtFwReq: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endOfExtFwListReached)),
    filter(() => updateDeviceExtFwPageSelector(state$.value) != null),
    map(() => findBluetoothFileAsync.request()),
  );

export const toSetFindConfigFileName: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setFindConfigFileName)),
    tap(() => {
      api.file.fileService.subgroup('findFile').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findConfigFileAsync.request()),
  );

export const toSetFindFirmwareFileName: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setFindFirmwareFileName)),
    tap(() => {
      api.file.fileService.subgroup('findFile').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findFirmwareFileAsync.request()),
  );

export const toSetFindBluetoothFileName: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setFindBluetoothFileName)),
    tap(() => {
      api.file.fileService.subgroup('findFile').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findBluetoothFileAsync.request()),
  );

export const toSetFindExtFwFileName: RootEpic = (action$, _state$, { api }) =>
  action$.pipe(
    filter(isActionOf(setFindExtFwFileName)),
    tap(() => {
      api.file.fileService.subgroup('findFile').cancelAll();
    }),
    debounceTime(constants.defaultDelay),
    map(() => findExtFwFileAsync.request()),
  );

export const toFindFirmwareReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findFirmwareFileAsync.request)),
    mergeMap(() =>
      from(
        api.file.findFile({
          sortDescriptor: {
            direction: SortDirection.ASC,
            identifier: File.SortIdentifier.FILE_NAME,
          },
          pageDescriptor: {
            page: updateDeviceFirmwarePageSelector(store$.value),
            size: DEFAULT_PAGE_SIZE,
          },
          deviceTypes: [updateDeviceTypeSelector(store$.value)],
          fileName: store$.value.updateDevice.firmwareFiles.filter,
          fileTypes: [File.FileType.FW],
        }),
      ).pipe(
        map(findFirmwareFileAsync.success),
        catchError((err) => of(findFirmwareFileAsync.failure(String(err)))),
      ),
    ),
  );

export const toFindFindExtFwReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findExtFwFileAsync.request)),
    mergeMap(() =>
      from(
        api.file.findFile({
          sortDescriptor: {
            direction: SortDirection.ASC,
            identifier: File.SortIdentifier.FILE_NAME,
          },
          pageDescriptor: {
            page: updateDeviceExtFwPageSelector(store$.value),
            size: DEFAULT_PAGE_SIZE,
          },
          deviceTypes: [updateDeviceTypeSelector(store$.value)],
          fileName: store$.value.updateDevice.extFwFiles.filter,
          fileTypes: [File.FileType.EXT],
        }),
      ).pipe(
        map(findExtFwFileAsync.success),
        catchError((err) => of(findExtFwFileAsync.failure(String(err)))),
      ),
    ),
  );

export const toFindBluetoothReq: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(findBluetoothFileAsync.request)),
    mergeMap(() =>
      from(
        api.file.findFile({
          sortDescriptor: {
            direction: SortDirection.ASC,
            identifier: File.SortIdentifier.FILE_NAME,
          },
          pageDescriptor: {
            page: updateDeviceBluetoothPageSelector(store$.value),
            size: DEFAULT_PAGE_SIZE,
          },
          // BLE file type is device type agnostic
          // deviceTypes: [updateDeviceTypeSelector(store$.value)],
          fileName: store$.value.updateDevice.bluetoothFiles.filter,
          fileTypes: [File.FileType.BLE],
        }),
      ).pipe(
        map(findBluetoothFileAsync.success),
        catchError((err) => of(findBluetoothFileAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description Epic to handle CommandsUpload request
 */
export const toCommandsUploadReq: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(commandsUploadAsync.request)),
    mergeMap(({ payload }) =>
      from(api.updateDevice.upload(payload)).pipe(
        map(commandsUploadAsync.success),
        catchError((err) => of(commandsUploadAsync.failure(String(err)))),
      ),
    ),
  );

export const toCommandsUploadRes: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([commandsUploadAsync.success, uploadBasicParametersAsync.success])),
    mergeMap(() => of(push(routePaths.device.root), findDeviceAsync.request())),
  );

/**
 * @description Epic to handle GetBasicParameters request
 */
export const toGetBasicParametersAsync: RootEpic = (action$, _store$, { api }) =>
  action$.pipe(
    filter(isActionOf(getBasicParametersAsync.request)),
    mergeMap(({ payload }) =>
      from(api.updateDevice.getBasicParameters(payload)).pipe(
        map(getBasicParametersAsync.success),
        catchError((err) => of(getBasicParametersAsync.failure(String(err)))),
      ),
    ),
  );

/**
 * @description Epic to handle UploadBasicParameters request
 */
export const toUploadBasicParametersAsync: RootEpic = (action$, store$, { api }) =>
  action$.pipe(
    filter(isActionOf(uploadBasicParametersAsync.request)),
    mergeMap(({ payload }) =>
      from(api.updateDevice.uploadBasicParameters(store$.value.device.list.selected, payload)).pipe(
        map(uploadBasicParametersAsync.success),
        catchError((err) => of(uploadBasicParametersAsync.failure(String(err)))),
      ),
    ),
  );
