import { combineReducers } from 'redux';
import { SortDirection } from 'src/types/common';
import { errorReducerCreator, loadingReducerCreator, listReducerCreator } from 'store/utils';
import { createReducer } from 'typesafe-actions';
import {
  findClientAsync,
  resetList,
  setClientListOrder,
  toggleClientSelect,
  setSelectedClients,
  setListFilters,
  clearListFilters,
  asyncActions,
  setDialogState,
  deleteClientAsync,
  validateClientListAsync,
  deleteMultipleClients,
  initListFilters,
  initListOrdering,
} from './Client.actions';
import { Client } from './Client.types';

export const DEFAULT_CLIENT_FILTERS: Client.FindFilters = {
  name: '',
  types: [],
};

export const DEFAULT_CLIENT_SORTING = {
  direction: SortDirection.ASC,
  identifier: Client.SortIdentifier.NAME,
};

export const list = listReducerCreator<
  Client.ExternalClient,
  Client.ListSortDescriptor,
  Client.ExternalClient['id'],
  Client.FindFilters
>(
  {
    getterAsyncAction: findClientAsync,
    resetAction: resetList,
  },
  {
    initAction: initListOrdering,
    setterAction: setClientListOrder,
    defaultState: DEFAULT_CLIENT_SORTING,
  },
  {
    setterAction: setSelectedClients,
    toggleAction: toggleClientSelect,
    deleteAction: deleteClientAsync,
  },
  {
    initAction: initListFilters,
    setterAction: setListFilters,
    resetAction: clearListFilters,
    defaultState: DEFAULT_CLIENT_FILTERS,
  },
);

const defaultDialogState: Client.DialogStates = {
  [Client.Dialogs.CREATE_CLIENT]: { isOpen: false },
  [Client.Dialogs.EDIT_CLIENT]: {
    isOpen: false,
    clientId: null,
  },
  [Client.Dialogs.DELETE_CONFIRMATION]: {
    isOpen: false,
    clientIdList: [],
  },
};

export const dialogStates = createReducer<Client.DialogStates>(
  defaultDialogState,
).handleAction(setDialogState, (state, { payload }) => ({ ...state, ...payload }));

/**
 * Delete client related reducers ↓
 */
export const clientIdList = createReducer<Client.ExternalClient['id'][]>([]).handleAction(
  setDialogState,
  (_state, { payload }) => {
    if (payload.DELETE_CONFIRMATION?.isOpen) {
      return payload.DELETE_CONFIRMATION.clientIdList;
    }
    return [];
  },
);

export const validateClients = createReducer<Client.ValidateResponse>(null)
  .handleAction(validateClientListAsync.success, (_state, { payload }) => payload)
  .handleAction(setDialogState, () => null);

export const multipleDelete = createReducer<boolean>(false)
  .handleAction(deleteMultipleClients, () => true)
  .handleAction(setDialogState, (state, { payload }) =>
    payload?.DELETE_CONFIRMATION?.isOpen === false ? false : state,
  );

export const deleteClients = combineReducers({
  clientIdList,
  validateClients,
  multipleDelete,
});

/**
 * Loading reducer ↓
 */

export const loading = loadingReducerCreator(asyncActions, {
  findClientAsync: { set: [resetList] },
});

/**
 * Error reducer ↓
 */

export const error = errorReducerCreator(asyncActions, {
  createClientAsync: { reset: [setDialogState] },
  editClientAsync: { reset: [setDialogState] },
  deleteClientAsync: { reset: [setDialogState] },
});

export default combineReducers({
  list,
  loading,
  error,
  dialogStates,
  deleteClients,
});
