import { combineReducers } from 'redux';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import { SortDirection } from 'src/types/common';
import { IoT } from 'store/Device/Device.types';
import {
  errorReducerCreator,
  itemsReducerCreator,
  loadingReducerCreator,
  pageReducerCreator,
} from 'store/utils';
import { createReducer } from 'typesafe-actions';
import {
  findHistoryAsync,
  resetList,
  setHistoryListOrder,
  setListFilters,
  clearListFilters,
  historyDestroy,
  cancelCommandAsync,
  setDialogState,
  getBasicParametersAsync,
  updateCommandFromWS,
  asyncActions,
} from './CommandHistory.actions';
import { CommandHistory } from './CommandHistory.types';

const items = itemsReducerCreator<CommandHistory.ExternalIoTDeviceCommand>({
  getterAsyncAction: findHistoryAsync,
  resetAction: resetList,
  extended: [
    [
      [cancelCommandAsync.success],
      (state, { payload }) =>
        state.map((command) => {
          if (command.id === payload.id) {
            return payload;
          }
          return command;
        }),
    ],
    [
      [updateCommandFromWS],
      (state, { payload }) =>
        state.map((command) => (command.id === payload.data.id ? payload.data : command)),
    ],
  ],
});
const page = pageReducerCreator({
  listAsyncAction: findHistoryAsync,
  resetAction: resetList,
});

export const size = createReducer<CommandHistory.FindHistoryRequest['pageDescriptor']['size']>(
  DEFAULT_PAGE_SIZE,
);

export const pageDescriptor = combineReducers({ page, size });

export const sortDescriptor = createReducer<CommandHistory.HistoryListSortDescriptor>({
  direction: SortDirection.DESC,
  identifier: CommandHistory.HistorySortIdentifier.CREATED,
}).handleAction(setHistoryListOrder, (_state, { payload }) => payload);

export const DEFAULT_HISTORY_FILTERS: CommandHistory.FindFilters = {
  basicParametersId: ('' as unknown) as number,
  createdFrom: '',
  createdTo: '',
  fileId: '',
  status: [],
  type: [],
};
export const filters = createReducer<CommandHistory.FindFilters>(DEFAULT_HISTORY_FILTERS)
  .handleAction(setListFilters, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction([clearListFilters, historyDestroy], () => DEFAULT_HISTORY_FILTERS);

export const list = combineReducers({
  items,
  pageDescriptor,
  sortDescriptor,
  filters,
});

/**
 * Loading reducer ↓
 */
export const loading = loadingReducerCreator(asyncActions);

/**
 * Error reducer ↓
 */

export const error = errorReducerCreator(asyncActions);

/**
 * Dialog states reducer ↓
 */
export const defaultDialogState: CommandHistory.DialogStates = {
  [CommandHistory.Dialogs.BASIC_PARAMETER]: {
    isOpen: false,
    id: null,
  },
  [CommandHistory.Dialogs.NO_FILE_WARNING]: false,
};

export const dialogStates = createReducer<CommandHistory.DialogStates>(
  defaultDialogState,
).handleAction(setDialogState, (state, { payload }) => ({ ...state, ...payload }));

export const commandBasicParams = createReducer<IoT.BasicParameters>(null).handleAction(
  getBasicParametersAsync.success,
  (_state, { payload }) => payload,
);

export default combineReducers({
  list,
  loading,
  error,
  dialogStates,
  commandBasicParams,
});
