import { createAction, createAsyncAction } from 'typesafe-actions';
import { User } from 'store/User/User.types';

export const initListFilters = createAction('USER | LIST.INIT_FILTERS')<
  Partial<User.FindFilters>
>();

export const initListOrdering = createAction(
  'USER | LIST.INIT_FILE_ORDER',
)<User.ListSortDescriptor>();

export const queryParseFinish = createAction('USER | LIST.QUERY_PARSE_FINISH')<void>();

export const findUserAsync = createAsyncAction(
  'USER | FIND_USER_REQ',
  'USER | FIND_USER_RES',
  'USER | FIND_USER_ERR',
)<void, User.FindUserRes, string>();

export const createUserAsync = createAsyncAction(
  'USER | CREATE_USER_REQ',
  'USER | CREATE_USER_RES',
  'USER | CREATE_USER_ERR',
)<User.CreateUserReq, User.CreateUserRes, string>();

export const deleteUserAsync = createAsyncAction(
  'USER | DELETE_USER_REQ',
  'USER | DELETE_USER_RES',
  'USER | DELETE_USER_ERR',
)<User.DeleteUsersReq, User.DeleteUsersRes, string>();

export const editUserAsync = createAsyncAction(
  'USER | EDIT_USER_REQ',
  'USER | EDIT_USER_RES',
  'USER | EDIT_USER_ERR',
)<User.EditUserReq, User.EditUserRes, string>();

export const editUserAndOrPasswordAsync = createAsyncAction(
  'USER | EDIT_USER_&_PW_REQ',
  'USER | EDIT_USER_&_PW_RES',
  'USER | EDIT_USER_&_PW_ERR',
)<User.EditUserAndOrPasswordReq, User.EditUserRes, string>();

export const editCurrentUserAsync = createAsyncAction(
  'USER | EDIT_CURRENT_USER_REQ',
  'USER | EDIT_CURRENT_USER_RES',
  'USER | EDIT_CURRENT_USER_ERR',
)<User.EditUserReq, User.EditUserRes, string>();

export const toggleUserSelect = createAction('USER | TOGGLE_USER_SELECT')<User.Base['id']>();
export const setSelectedUsers = createAction('USER | LIST.SET_SELECTED_USERS')<User.Base['id'][]>();
export const userListInit = createAction('USER | USER_LIST_INIT')();
export const userListDestroy = createAction('USER | USER_LIST_DESTROY')();
export const endOfListReached = createAction('USER | LIST.END_OF_LIST_REACHED')();

export const setUserListOrder = createAction(
  'USER | LIST.SET_USER_ORDER',
)<User.ListSortDescriptor>();

export const setListFilters = createAction('USER | LIST.SET_FILTERS')<Partial<User.FindFilters>>();

export const clearListFilters = createAction('USER | LIST.CLEAR_FILTERS')();

export const resetList = createAction('USER | RESET_LIST')();

export const setDialogState = createAction('USER | SET_DIALOG_STATE')<Partial<User.DialogStates>>();

export const deleteMultipleUsers = createAction('USER | DELETE_MULTIPLE_USER')();

export const userPage = {
  findUserAsync,
  createUserAsync,
  deleteUserAsync,
  editUserAsync,
  editUserAndOrPasswordAsync,
};

export const changePassword = createAsyncAction(
  'USER | PUT_PASS_REQ',
  'USER | PUT_PASS_RES',
  'USER | PUT_PASS_ERR',
)<User.ChangePassReq, User.ChangePassRes, string>();

// eslint-disable-next-line prettier/prettier
export const getUser = createAsyncAction(
  'USER | GET_REQ',
  'USER | GET_RES',
  'USER | GET_ERR',
)<User.GetReq, User.GetRes, string>();

const userSettings = {
  editCurrentUserAsync,
  changePassword,
  getUser,
};

export const asyncActions = { ...userPage, ...userSettings };
