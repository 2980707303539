import { createAction, createAsyncAction } from 'typesafe-actions';
import { Client } from './Client.types';

export const initListFilters = createAction('CLIENT | LIST.INIT_FILTERS')<
  Partial<Client.FindFilters>
>();

export const initListOrdering = createAction(
  'CLIENT | LIST.INIT_FILE_ORDER',
)<Client.ListSortDescriptor>();

export const queryParseFinish = createAction('CLIENT | LIST.QUERY_PARSE_FINISH')<void>();

export const findClientAsync = createAsyncAction(
  'CLIENT | FIND_CLIENT_REQ',
  'CLIENT | FIND_CLIENT_RES',
  'CLIENT | FIND_CLIENT_ERR',
)<void, Client.FindClientResponse, string>();

export const createClientAsync = createAsyncAction(
  'CLIENT | CREATE_CLIENT_REQ',
  'CLIENT | CREATE_CLIENT_RES',
  'CLIENT | CREATE_CLIENT_ERR',
)<Client.CreateClientRequest, Client.ExternalClient, string>();

export const deleteClientAsync = createAsyncAction(
  'CLIENT | DELETE_CLIENT_REQ',
  'CLIENT | DELETE_CLIENT_RES',
  'CLIENT | DELETE_CLIENT_ERR',
)<Client.ExternalClient['id'][], Client.ExternalClient['id'][], string>();

export const editClientAsync = createAsyncAction(
  'CLIENT | EDIT_CLIENT_REQ',
  'CLIENT | EDIT_CLIENT_RES',
  'CLIENT | EDIT_CLIENT_ERR',
)<Client.CreateClientRequest, Client.ExternalClient, string>();

export const validateClientListAsync = createAsyncAction(
  'CLIENT | VALIDATE_CLIENT_LIST_REQ',
  'CLIENT | VALIDATE_CLIENT_LIST_RES',
  'CLIENT | VALIDATE_CLIENT_LIST_ERR',
)<Client.ExternalClient['id'][], Client.ValidateResponse, string>();

export const toggleClientSelect = createAction('CLIENT | TOGGLE_CLIENT_SELLECT')<
  Client.ExternalClient['id']
>();
export const setSelectedClients = createAction('CLIENT | LIST.SET_SELECTED_CLIENTS')<
  Client.ExternalClient['id'][]
>();

export const clientListInit = createAction('CLIENT | CLIENT_LIST_INIT')();

export const clientListDestroy = createAction('CLIENT | CLIENT_LIST_DESTROY')();

export const endOfListReached = createAction('CLIENT | LIST.END_OF_LIST_REACHED')();

export const setClientListOrder = createAction(
  'CLIENT | LIST.SET_CLIENT_ORDER',
)<Client.ListSortDescriptor>();

export const setListFilters = createAction('CLIENT | LIST.SET_FILTERS')<
  Partial<Client.FindFilters>
>();

export const clearListFilters = createAction('CLIENT | LIST.CLEAR_FILTERS')();

export const resetList = createAction('CLIENT | RESET_LIST')();

export const setDialogState = createAction('CLIENT | SET_DIALOG_STATE')<
  Partial<Client.DialogStates>
>();

export const deleteMultipleClients = createAction('CLIENT | DELETE_MULTIPLE_CLIENT')();

export const asyncActions = {
  findClientAsync,
  createClientAsync,
  deleteClientAsync,
  editClientAsync,
  validateClientListAsync,
};
