import { Client } from 'store/Client/Client.types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const findClientAsync = createAsyncAction(
  'ASSIGN_DEVICE | FIND_CLIENT_REQ',
  'ASSIGN_DEVICE | FIND_CLIENT_RES',
  'ASSIGN_DEVICE | FIND_CLIENT_ERR',
)<void, Client.FindClientResponse, string>();

export const setFindClientName = createAction('ASSIGN_DEVICE | SET_FIND_CLIENT_NAME')<
  Pick<Client.FindFilters, 'name'>
>();
export const clearFilters = createAction('ASSIGN_DEVICE | LIST.CLEAR_FILTERS')();
export const endOfListReached = createAction('ASSIGN_DEVICE | END_OF_LIST_REACHED')();

export const resetFind = createAction('ASSIGN_DEVICE | RESET_FIND')();

export const asyncActions = {
  findClientAsync,
};
