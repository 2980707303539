import { LOCATION_CHANGE } from 'connected-react-router';
import {
  filter,
  tap,
  mergeMap,
  take,
  catchError,
  distinctUntilKeyChanged,
  map,
} from 'rxjs/operators';
import { isActionOf, isOfType, RootEpic } from 'typesafe-actions';
import { EMPTY, from } from 'rxjs';
import { appInit } from 'store/App/App.actions';
import { commandsUploadAsync } from 'store/UpdateDevice/UpdateDevice.actions';
import { getCurrentAsync } from 'store/Authorization/Authorization.actions';
import { Analytics } from './Analytics.types';

export const initGA: RootEpic = (action$, _state$, { utils }) =>
  action$.pipe(
    filter(isActionOf(appInit)),
    filter(utils.analytics.isProd),
    tap(utils.analytics.initGA),
    mergeMap(() => EMPTY),
  );

export const setUserDimensions: RootEpic = (action$, _state$, { utils }) =>
  action$.pipe(
    filter(isActionOf(getCurrentAsync.success)),
    filter(utils.analytics.isProd),
    map(({ payload }) => payload),
    tap(utils.analytics.setTypesOfCurrentDimensions),
    mergeMap(() => EMPTY),
  );
export const toLoadCookiebot: RootEpic = (action$, _state$, { utils }) =>
  action$.pipe(
    filter(isActionOf(appInit)),
    filter(() => utils.analytics.isProd() || utils.analytics.isTest()),
    take(1),
    tap(utils.analytics.loadCookiebot),
    mergeMap(() => EMPTY),
  );
export const toLoadHotjar: RootEpic = (action$, _state$, { utils }) =>
  action$.pipe(
    filter(isActionOf(appInit)),
    filter(utils.analytics.isProd),
    take(1),
    mergeMap(() =>
      from(
        utils.analytics.loadHotjarScript(
          window,
          document,
          'https://static.hotjar.com/c/hotjar-',
          '.js?sv=',
        ),
      ).pipe(
        mergeMap(() => EMPTY),
        catchError(() => EMPTY),
      ),
    ),
  );

export const toPageChangeGA: RootEpic = (action$, _state$, { utils }) =>
  action$.pipe(
    filter(isOfType(LOCATION_CHANGE)),
    filter(utils.analytics.isProd),
    map(utils.analytics.toRouteLocation),
    distinctUntilKeyChanged('pathname'),
    tap(utils.analytics.callPageView),
    mergeMap(() => EMPTY),
  );

export const toDeviceCommandUpload: RootEpic = (action$, _state$, { utils }) =>
  action$.pipe(
    filter(isActionOf(commandsUploadAsync.success)),
    filter(utils.analytics.isProd),
    tap(
      utils.analytics.sendEventGA({
        category: Analytics.GA_EVENT_CATEGORIES.DEVICES,
        action: 'Command upload',
        label: 'Config or FW update',
      }),
    ),
    mergeMap(() => EMPTY),
  );
