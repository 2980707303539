// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".qAP-HfCYLxiiGdQg-HaCL {\n  padding: var(--length3);\n  color: var(--font-color1);\n  font-size: var(--font-size3-1);\n  line-height: var(--font-size7);\n  border-radius: var(--border-radius-strong);\n  display: flex;\n  align-items: center;\n}\n\n._8iO_48AXkVjkzWBtoTbo- {\n  padding-right: var(--length2);\n  line-height: 1;\n}\n\n.smHX5eI3Y5vpEsJGPGuQ5 {\n  background: #F4F7FA;\n}\n\n.smHX5eI3Y5vpEsJGPGuQ5 ._8iO_48AXkVjkzWBtoTbo- {\n    color: var(--color-blue2);\n  }\n\n._1vYNvbuad3SgaUSGQiLx2Z {\n  background: rgba(255,181,23,0.07);\n}\n\n._1vYNvbuad3SgaUSGQiLx2Z ._8iO_48AXkVjkzWBtoTbo- {\n    color: var(--alert-warning-color);\n  }\n\n._26v6C0faD0pzpnMr4CS8xI {\n  background: var(--alert-error-bg-color);\n}\n\n._26v6C0faD0pzpnMr4CS8xI ._8iO_48AXkVjkzWBtoTbo- {\n    color: var(--alert-error-color);\n  }\n\n._1iGy4LtaCBvsDbmPm3C2j {\n  background: var(--alert-success-bg-color);\n}\n\n._1iGy4LtaCBvsDbmPm3C2j ._8iO_48AXkVjkzWBtoTbo- {\n    color: var(--alert-success-color);\n  }", ""]);
// Exports
exports.locals = {
	"alert": "qAP-HfCYLxiiGdQg-HaCL",
	"icon": "_8iO_48AXkVjkzWBtoTbo-",
	"info": "smHX5eI3Y5vpEsJGPGuQ5",
	"warning": "_1vYNvbuad3SgaUSGQiLx2Z",
	"error": "_26v6C0faD0pzpnMr4CS8xI",
	"success": "_1iGy4LtaCBvsDbmPm3C2j"
};
module.exports = exports;
