import { useEffect, useRef, useState } from 'react';

export const useClickOutside = (onClick: () => void) => {
  const [ref, setRef] = useState(null);

  const clickRef = useRef(onClick);
  clickRef.current = onClick;

  useEffect(() => {
    if(!ref) return undefined;

    const handler = ({ target }: MouseEvent) => {
      if (!ref.contains(target)) {
        clickRef.current();
      }
    };
    document.body.addEventListener('click', handler);

    return () => {
      document.body.removeEventListener('click', handler);
    };
  }, [ref]);

  return [setRef];
};
