import { combineReducers } from 'redux';
import { Client } from 'store/Client/Client.types';
import { errorReducerCreator, loadingReducerCreator } from 'store/utils';
import { createReducer } from 'typesafe-actions';
import { User } from 'store/User/User.types';
import {
  asyncActions,
  clearUserData,
  getCurrentAsync,
  logoutAsync,
  updateCurrentUser,
} from './Authorization.actions';

export const prepared = createReducer(false).handleAction(
  [getCurrentAsync.success, getCurrentAsync.failure],
  () => true,
);

export const user = createReducer<User.Base>(null)
  .handleAction([getCurrentAsync.success, updateCurrentUser], (_state, { payload }) => payload.user)
  .handleAction([logoutAsync.success, clearUserData], () => null);

export const authenticated = createReducer(false)
  .handleAction(getCurrentAsync.success, () => true)
  .handleAction([logoutAsync.success, clearUserData], () => false);

export const client = createReducer<Client.Base>(null)
  .handleAction(getCurrentAsync.success, (_state, { payload }) => payload.client)
  .handleAction([logoutAsync.success, clearUserData], () => null);

/**
 * Loading reducer ↓
 */
export const loading = loadingReducerCreator(asyncActions);

/**
 * Error reducer ↓
 */

export const error = errorReducerCreator(asyncActions);

export default combineReducers({ prepared, authenticated, user, client, loading, error });
