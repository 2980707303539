import * as React from 'react';
import Icon, { IconName } from '@tt2/components/build/Atoms/Icon';
import cn from 'classnames';
import styles from './Alert.css';

type AlertType = 'info' | 'success' | 'warning' | 'error';

interface AlertProps {
  type: AlertType;
  className?: string;
}

const TYPE_ICON_MAP: { [key in AlertType]: IconName } = {
  success: 'valid-success',
  info: 'info_invert',
  warning: 'warning_o',
  error: 'error',
};

export const Alert: React.FC<AlertProps> = ({ type, children, className }) => {
  return (
    <div className={cn(styles.alert, styles[type], className)} data-test-id="alert--div-0">
      <Icon className={styles.icon} name={TYPE_ICON_MAP[type]} size={18} /> {children}
    </div>
  );
};

export default Alert;
