// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3T0iHP-gPLr-qA6FpDKfYb._3ezEolInablkePXBx4wOBK {\n    border-bottom: 1px solid var(--color-gray3);\n  }\n    ._3T0iHP-gPLr-qA6FpDKfYb._3ezEolInablkePXBx4wOBK .TZcbh7cG0FPZ4RyqRGVJx {\n      color: var(--font-color1);\n    }\n    ._3T0iHP-gPLr-qA6FpDKfYb._3ezEolInablkePXBx4wOBK ._23tzA9soA8tAqgzNrznmRQ {\n      color: var(--color-gray2-1);\n    }\n  ._3T0iHP-gPLr-qA6FpDKfYb._3WBh70UhL8MEX_Xqj6K20e {\n    border-bottom: 1px solid var(--color-gray1-2);\n  }\n  ._3T0iHP-gPLr-qA6FpDKfYb._3WBh70UhL8MEX_Xqj6K20e .TZcbh7cG0FPZ4RyqRGVJx {\n      color: var(--color-gray6);\n    }\n  ._3T0iHP-gPLr-qA6FpDKfYb._3WBh70UhL8MEX_Xqj6K20e ._23tzA9soA8tAqgzNrznmRQ {\n      color: var(--color-gray6);\n    }\n  ._3T0iHP-gPLr-qA6FpDKfYb {\n  padding: var(--length3);\n}\n  ._3T0iHP-gPLr-qA6FpDKfYb .TZcbh7cG0FPZ4RyqRGVJx {\n    font-size: var(--font-size3-1);\n    font-weight: var(--fw-light-bold);\n    line-height: var(--font-size6);\n  }\n  ._3T0iHP-gPLr-qA6FpDKfYb ._23tzA9soA8tAqgzNrznmRQ {\n    font-size: var(--font-size1);\n  }", ""]);
// Exports
exports.locals = {
	"userDetails": "_3T0iHP-gPLr-qA6FpDKfYb",
	"dark": "_3ezEolInablkePXBx4wOBK",
	"name": "TZcbh7cG0FPZ4RyqRGVJx",
	"email": "_23tzA9soA8tAqgzNrznmRQ",
	"light": "_3WBh70UhL8MEX_Xqj6K20e"
};
module.exports = exports;
