import { Authorization } from 'store/Authorization/Authorization.types';
import { ListResponse, PageDescriptor, SortDescriptor } from 'src/types/common';
import { asyncActions } from 'store/User/User.actions';

export namespace User {
  export interface Base {
    id: number;
    email: string;
    name: string;
    role: Authorization.ROLE;
  }
  export interface ChangePassReq {
    userId: number;
    body: {
      clientId: number;
      password: string;
    };
  }
  export interface UserRes extends Base {
    clientId: number;
    clientName: string;
    created: string;
    updated: string;
  }

  export type ChangePassRes = UserRes;

  export interface CommonBody {
    clientId: number;
    email: string;
    name: string;
    role: Authorization.ROLE;
  }

  export interface EditUserReq {
    userId: number;
    body: CommonBody;
  }

  export interface EditUserAndOrPasswordReq {
    userId: number;
    editBody?: EditUserReq['body'];
    pwBody?: ChangePassReq['body'];
  }

  export type EditUserRes = UserRes;

  export type GetReq = string;
  export type GetRes = UserRes;

  // User Page
  export enum SortIdentifier {
    NAME = 'NAME',
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    CLIENT_NAME = 'CLIENT_NAME',
    ROLE = 'ROLE',
    EMAIL = 'EMAIL',
  }

  export enum FilterIdentifier {
    CLIENT_NAME = 'clientName',
    ROLE = 'roles',
    NAME = 'name',
  }

  export type FindUserRes = ListResponse<UserRes>;

  export interface FindUserReq extends FindFilters {
    pageDescriptor: PageDescriptor;
    sortDescriptor?: ListSortDescriptor;
  }

  export type FindFilters = {
    name: Base['name'];
    clientName: string;
    email: string;
    roles: Authorization.ROLE[];
  };

  export type ListSortDescriptor = SortDescriptor<SortIdentifier>;
  export type AsyncActionKeys = keyof typeof asyncActions;

  export enum Dialogs {
    CREATE_USER = 'CREATE_USER',
    EDIT_USER = 'EDIT_USER',
    DELETE_CONFIRMATION = 'DELETE_CONFIRMATION',
  }

  export type DialogStates = {
    [Dialogs.CREATE_USER]: {
      isOpen: boolean;
    };
    [Dialogs.EDIT_USER]: {
      isOpen: boolean;
      userId: Base['id'] | null;
    };
    [Dialogs.DELETE_CONFIRMATION]: {
      isOpen: boolean;
      userIdList: Base['id'][];
    };
  };

  export interface CreateUserReq extends CommonBody {
    password: string;
  }
  export type CreateUserRes = UserRes;

  export type DeleteUsersReq = Base['id'][];

  export type DeleteUsersRes = Base['id'][];
}
